import React, { useState, useEffect } from "react";
import { Box, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import axios from 'axios';
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

const ConfirmationModel = ({ isOpen, onClose, onConfirm, confirmData, isLoading }) => {
    const [mobileOtp, setMobileOtp] = useState("");
    const [emailOtp, setEmailOtp] = useState("");
    const [isLoadingOtp, setIsLoadingOtp] = useState(false);
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isCodeRequested, setIsCodeRequested] = useState(false);
    const [countdown, setCountdown] = useState(120); // Timer for 2 minutes (120 seconds)
    const token = useSelector((state) => state.auth.authToken);

    useEffect(() => {
        // Countdown timer effect
        let timer;
        if (isButtonDisabled && countdown > 0) {
            timer = setInterval(() => {
                setCountdown((prevCountdown) => prevCountdown - 1);
            }, 1000);
        } else if (countdown === 0) {
            setIsButtonDisabled(false);
            setCountdown(120); // Reset countdown to 2 minutes
        }

        return () => clearInterval(timer); // Cleanup timer on component unmount or effect re-run
    }, [isButtonDisabled, countdown]);

    if (!isOpen) return null;

    const amount = Number(confirmData?.amount);
    const formattedAmount = !isNaN(amount) ? parseFloat(amount.toFixed(6)) : "N/A";

    const requestOtp = async () => {
        setIsLoadingOtp(true);
        setIsButtonDisabled(true);
        try {
            const headers = {
                "x-access-token": token,
            };
            const response = await axios.get(`${process.env.REACT_APP_BASE_URI}/otp/generate`, { headers });
            if (response.status === 200 || response.status === 201) {
                toast.success("OTP sent successfully to both mobile and email.");
                setIsCodeRequested(true);
            }
        } catch (error) {
            toast.error("Failed to send OTP.");
        } finally {
            setIsLoadingOtp(false);
            // Countdown will handle button re-enable
        }
    };

    const handleConfirm = () => {
        if (!mobileOtp || !emailOtp) {
            toast.error("Please enter both OTPs.");
            return;
        }
        const otpRegex = /^\d{6}$/;
        if (!otpRegex.test(mobileOtp) || !otpRegex.test(emailOtp)) {
            toast.error("Both OTPs must be 6 digits.");
            return;
        }

        if (!isCodeRequested) {
            toast.error("Please request the OTP code first.");
            return;
        }

        onConfirm(mobileOtp, emailOtp);
        // Clear the OTP input fields after submitting
        setMobileOtp("");
        setEmailOtp("");

    };

    return (
        <Box
            sx={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                maxWidth: "500px",
                bgcolor: "var(--bs-light)",
                borderRadius: "20px",
                boxShadow: 24,
                p: 4,
                zIndex: 1300,
            }}
        >
            <Box display="flex" flexDirection="column" alignItems="center">
                <IconButton onClick={onClose} sx={{ position: "absolute", top: 16, right: 16 }}>
                    <CloseIcon sx={{ color: "var(--primaryColor)", fontSize: 24 }} />
                </IconButton>
                <Box marginTop="3rem">
                    <Typography variant="h5" gutterBottom color="var(--white)">
                        Transaction Confirmation
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="body1" color="var(--white)" gutterBottom>
                        <strong>Receiver Address:</strong> {confirmData?.receiverAddress || "0"}
                    </Typography>
                    <Typography variant="body1" color="var(--white)">
                        <strong>Amount:</strong> {formattedAmount} {confirmData?.symbol}
                    </Typography>
                </Box>
                <Box width="100%">
                    <Box position="relative" marginBottom="1rem">
                        <input
                            className="input"
                            type="text"
                            value={mobileOtp}
                            onChange={(e) => setMobileOtp(e.target.value)}
                            placeholder="Enter Mobile OTP"
                            style={{ color: "var(--white)", backgroundColor: 'var(--darkSoft)', width: '100%', borderRadius: "8px", paddingLeft: '1rem' }}
                        />
                        <Box position="absolute" top="0" bottom="0" right="10px" display="flex" alignItems="center" gap="4px">
                            <LocalPhoneIcon sx={{ color: 'var(--primaryColor)' }} />
                        </Box>
                    </Box>
                    <Box position="relative" marginBottom="1rem">
                        <input
                            className="input"
                            type="text"
                            value={emailOtp}
                            onChange={(e) => setEmailOtp(e.target.value)}
                            placeholder="Enter Email OTP"
                            style={{ color: "var(--white)", backgroundColor: 'var(--darkSoft)', width: '100%', borderRadius: "8px", paddingLeft: '1rem' }}
                        />
                        <Box position="absolute" top="0" bottom="0" right="10px" display="flex" alignItems="center" gap="4px">
                            <EmailIcon sx={{ color: 'var(--primaryColor)' }} />
                        </Box>
                    </Box>
                    <Box display="flex" justifyContent="center" mt={2}>
                        <button
                            onClick={requestOtp}
                            className="buyButtons" 
                            disabled={isLoadingOtp || isButtonDisabled}
                        >
                            {isLoadingOtp ? "Sending..." : isButtonDisabled ? `Wait (${countdown}s)` : "Get OTP"}
                        </button>
                    </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" gap="10px" mt={2} width="100%">
                    <button className="buyButtons" onClick={onClose} style={{ width: '100%', background: 'var(--pink) !important' }} >
                        Cancel
                    </button>
                    <button className="buyButtons" onClick={handleConfirm} disabled={isLoading} style={{ width: '100%' }} >
                        {isLoading ? "Loading..." : "Confirm"}
                    </button>
                </Box>
            </Box>
        </Box>
    );
};

export default ConfirmationModel;
