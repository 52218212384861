import React, { useEffect, useState } from "react";
import "./navbar.css";
import logodark from "../assets/palm-white.png";
import webLogo from "../assets/palmLogo2.png";
import { Box, IconButton, Typography } from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import ThemeSwitchs from "./ThemeSwitchs";
import MenuIcon from "@mui/icons-material/Menu";
import { Link, useNavigate } from "react-router-dom";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
  logOutSuccess,
  setNotifCount,
  setOneDocoinPrice,
} from "../../redux/slices/authSlice";
import LogoutIcon from "@mui/icons-material/Logout";
import RestoreIcon from "@mui/icons-material/Restore";
import { callApi } from "../../api";
import SpatialTrackingIcon from "@mui/icons-material/SpatialTracking";
import moment from "moment";
import defaultImage from "../../components/assets/PalmLogo.png";

const BASE_URI = process.env.REACT_APP_BASE_URI
const substringToRemove = "api";
function removeLastOccurrence(baseString, substring) {
  const index = baseString.lastIndexOf(substring);
  if (index !== -1 && index === baseString.length - substring.length) {
    return baseString.substring(0, index);
  }
  return baseString;
}
export const imagePath = removeLastOccurrence(BASE_URI, substringToRemove);

const Navbar = () => {
  const navigate = useNavigate();
  const token = useSelector((state) => state.auth.authToken);
  const userdetail = useSelector((state) => state.auth.importedAdress);
  const isdarkMode = useSelector((state) => state.auth.isDarkMode);
  const notifyCount = useSelector((state) => state.auth.notifiCount);
  const userEditableDetails = useSelector((state) => state.auth.UserDetailsEditable);
  const isAuthenticate = useSelector((state) => state.auth.isUserAuthenticated);

  const dispatch = useDispatch();
  const [price, setprice] = useState("");
  const [notifcationData, setNotificationData] = useState([]);
  const updateWeb = useSelector((state) => state.auth.updateWeb);

  useEffect(() => {
    const fetchdata = async () => {
      try {
        const res = await axios.get(`${BASE_URI}/tokenPrice`);
        const response = res.data.result.price;
        setprice(response);
        dispatch(setOneDocoinPrice(response));
      } catch (error) {
      }
    };
    fetchdata();
  }, []);

  async function clearCache() {
    if (window.caches) {
      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map(async (name) => {
          const cache = await caches.open(name);
          await cache.clear();
        })
      );
    }
  }


  const handleLogout = async () => {
    try {
      // Clear Cache
      await clearCache();

      // Clear Local Storage and Session Data
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");

      // Clear Cookies
      document.cookie.split(";").forEach((c) => {
        document.cookie = c
          .replace(/^ +/, "")
          .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });

      // Clear Redux Data
      await dispatch(logOutSuccess());

      // Navigate to login page
      navigate("/login");
    } catch (error) {
      console.log(error, "error while logging out");
    }
  };

  const getNotification = async () => {
    try {
      const header = {
        "content-Type": "application/json",
        "x-access-token": token,
      };
      const body = {};
      const method = "get";
      const subUri = "/user/getUserNotifitions";

      const response = await callApi(header, body, method, subUri);
      if (response.status === 200 || response.status === 201) {
        let datalength = response?.data?.data || [];
        let res = await datalength?.filter((item) => item.is_seen == 1);
        setNotificationData(response?.data?.data);
        dispatch(setNotifCount(0));
        await seenNotif();
      }
    } catch (error) {
    }
  };

  const seenNotif = async () => {
    const header = {
      "content-Type": "application/json",
      "x-access-token": token,
    };
    const body = {};
    const method = "post";
    const subUri = "/user/seenNotification";
    try {
      let response = await callApi(header, body, method, subUri);

      if (response.status == 200 || response.status == 201) {
        // console.log(response.data,'response data ==========user//user/seenNotification')
      }
    } catch (err) {
    }
  };

  useEffect(() => {
    if (isAuthenticate) {
      getNotification();
    }
  }, [isAuthenticate]);



  return (
    <Box className="Navbar">
      <Box className="logoContainer">

        <img
          src={isdarkMode ? webLogo : logodark} // Update this if necessary
          alt=""
          className="logoWidth"
        />
        <Box className="MenuIconToggle">
          <IconButton>
            <MenuIcon sx={{ color: "var(--white)" }} />
          </IconButton>
        </Box>
      </Box>
      <Box className="iconsContainers">
        <Box className="docoinheadprice">
          <h5>PALM = ${parseFloat(price)}</h5>
        </Box>
        {/* <Box className="fullscreens">
          <IconButton>
            <FullscreenIcon sx={{ color: "var(--white)" }} />
          </IconButton>
        </Box> */}
        <Box className="notifications">
          <IconButton>
            <NotificationsNoneIcon
              className="bx-tada"
              sx={{ color: "var(--white)" }}
            />
          </IconButton>
          <span className="bages">{notifyCount}</span>
          <Box className="notificationRows">
            <Box
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              marginBottom={"1rem"}
            >
              <Typography variant="h6">Notifications</Typography>
              {/* <Typography variant="caption" color={"var(--primaryColor)"}>
                View All
              </Typography> */}
            </Box>
            <Box>
              {notifcationData[0] == undefined ||
                notifcationData == "No notification" ? (
                <>
                  <span>No Notification...</span>
                </>
              ) : (
                notifcationData?.map((item, index) => {
                  let timeStamp = item?.time;
                  // console.log(timeStamp,'timestamp')
                  let updatedTimeStamp = moment(timeStamp)
                    .add(5, "hours")
                    .add(30, "minutes");
                  const timeAgo = moment(updatedTimeStamp).fromNow();
                  return (
                    <Box
                      display="flex"
                      gap="8px"
                      marginBottom={"1rem"}
                      key={index}
                    >
                      <Box>
                        <img
                          src={logodark}
                          style={{
                            height: "40px",
                            width: "40px",
                            marginTop: 15,
                            objectFit: "contain",
                            border: "1px solid var(--bs-header-bg)",
                            borderRadius: "100px",
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          fontSize={15}
                          color={"var(--whiet)"}
                          paddingY={"7px"}
                        >
                          {String(item?.action).substring(0, 45)}...
                        </Typography>
                        <Box>
                          {/* <Typography fontSize={13} color={"#c3cbe4"}>
                            {item.type}
                          </Typography> */}
                          <Typography
                            display="flex"
                            alignItems="center"
                            gap="5px"
                            variant="caption"
                            color={"#c3cbe4"}
                          >
                            <AccessTimeIcon sx={{ fontSize: "13px" }} />{" "}
                            {timeAgo}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  );
                })
              )}

            </Box>

          </Box>
        </Box>
        <Box className="userContainers">
          <img
            src={userEditableDetails?.image ? `${imagePath}${userEditableDetails?.image}` : defaultImage
            }
            style={{ height: '35px', width: '40px', }}
            className="proUserImage"
            alt="user image"
          />
          <span>
            {userdetail?.user_details?.name !== undefined
              ? String(userdetail?.user_details?.name).slice(0, 15)
              : "user"}
          </span>
          <Box className="userProfilelist">
            <Link to="/profile" className="profiledropList">
              <PersonOutlineOutlinedIcon sx={{ fontSize: 16 }} />
              Profile
            </Link>
            <Link to="/refferal-history" className="profiledropList">
              <SpatialTrackingIcon sx={{ fontSize: 16 }} />
              Refferal
            </Link>
            <Link to="/login-history" className="profiledropList">
              <RestoreIcon sx={{ fontSize: 16 }} />
              Login History
            </Link>
            <button onClick={handleLogout} className="Logoutbtn">
              <LogoutIcon sx={{ fontSize: 16 }} />
              Logout
            </button>
          </Box>
        </Box>
        <Box sx={{ display: { md: "block", sm: "none", xs: "none" } }}>
          <ThemeSwitchs />
        </Box>
      </Box>
    </Box>
  );
};

export default Navbar;
