import React, { useEffect, useState } from "react";
import "./stake.css";
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import successful from "../buys/success-payment-approved-png.webp";
import CloseIcon from "@mui/icons-material/Close";
import TransactionsData from "./TransactionsData";
import { callApi, fetchAndDispatchHomeData, fetchStakeData } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import CircularProgress from "@mui/material/CircularProgress";
import { setStakeData, setTotalrewards, setUpdateApp } from "../../redux/slices/authSlice";
import moment from "moment";
import GetNotificationCount from "../../components/NotificationCount/NotificationCount";
import Verification from "../../components/logedcomponents/Verification";
import CustomLoader from "../../CustomLoader/CustomLoader";
// import CircularProgress from "@mui/material";

function PopupModal({ isOpen, onClose, hash, hash1, modalType, onConfirm, confirmData, isLoading }) {
    if (!isOpen) return null;
    const convertTimestampToYMDMS = (t) => {
        let year, month, day, hour, minute, second;

        second = t % 60;
        t = Math.floor(t / 60);
        minute = t % 60;
        t = Math.floor(t / 60);
        hour = t % 24;
        t = Math.floor(t / 24);
        day = t % 30;
        t = Math.floor(t / 30);
        month = t % 12;
        t = Math.floor(t / 12);
        year = t;

        if (year > 0) {
            return `${year} Year${year > 1 ? "s" : ""}`;
        }
        if (month > 0) {
            return `${month} Month${month > 1 ? "s" : ""}`;
        }
        if (day > 0) {
            return `${day} Day${day > 1 ? "s" : ""}`;
        }
        if (hour > 0) {
            return `${hour} Hour${hour > 1 ? "s" : ""}`;
        }
        if (minute > 0) {
            return `${minute} Minute${minute > 1 ? "s" : ""}`;
        }
        if (second > 0) {
            return `${second} Second${second > 1 ? "s" : ""}`;
        }
        return "0 Second"; // If the duration is 0
    };

    const amount = Number(confirmData?.amount);
    const formattedAmount = amount !== undefined && amount !== null ? parseFloat(amount.toFixed(6)) : "N/A";

    return (
        <div className="modalContainers">
            <div className="modalRows">
                <div className="modalCloseRows">
                    <IconButton onClick={onClose}>
                        <CloseIcon sx={{ color: "var(--white)", fontSize: 18 }} />
                    </IconButton>
                </div>
                <div className="modal-content">
                    {modalType === "success" && (
                        <div>
                            <div>
                                <img src={successful} width="250" />
                            </div>
                            <Box>
                                <Typography fontSize="30px" gutterBottom>
                                    Transaction Completed!
                                </Typography>
                            </Box>
                            <h3>Congratulations, coins staked successfully.</h3>
                            <Link target="_blank" to={hash ? hash : hash1}>
                                <button onClick={onClose} className="sendButtons" style={{ color: "var(--white)" }}>
                                    View in Explorer
                                </button>
                            </Link>
                        </div>
                    )}

                    {/* Confirm Stake modal content */}
                    {modalType === "confirm" && (
                        <div>
                            <div>
                                <img src={successful} width="250" />
                            </div>
                            <Box marginBottom="2rem">
                                <Typography fontSize="30px" gutterBottom>
                                    Confirm Stake
                                </Typography>
                            </Box>
                            <Box>
                                <Box display="flex" alignItems="center" gap="15px" marginBottom={"10px"}>
                                    <Typography>Amount:</Typography>
                                    <Typography>{formattedAmount} PALM</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap="15px" marginBottom={"10px"}>
                                    <Typography>APY:</Typography>
                                    <Typography>{confirmData?.plan_apy || "0"}%</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap="15px" marginBottom={"10px"}>
                                    <Typography>Duration:</Typography>
                                    <Typography>{confirmData?.plan_name || ""}</Typography>
                                </Box>
                                <Box display="flex" alignItems="center" gap="15px" marginBottom={"10px"}>
                                    <Typography>Reward Distribution:</Typography>
                                    <Typography>{convertTimestampToYMDMS(confirmData?.minTimeWithdrawRewards) || ""}</Typography>
                                </Box>
                            </Box>
                            <Box display="flex" alignItems="center" justifyContent="space-between" gap="10px">
                                <button className="sendButtons" style={{ color: "#fff" }} onClick={onClose}>
                                    Cancel
                                </button>
                                <button className="sendButtons" style={{ color: "#fff" }} onClick={onConfirm}>
                                    {isLoading === true ? "Loading" : "Confirm"}
                                </button>
                            </Box>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
const Stake = () => {
    const updateWeb = useSelector((state) => state.auth.updateWeb);
    const stackingPlan = useSelector((state) => state.auth.stackingPlan);
    const userdetail = useSelector((state) => state.auth.importedAdress);
    const dispatch = useDispatch();
    const token = useSelector((token) => token.auth.authToken);
    const [loading1, setloading1] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedModaltype, setSelectedModalType] = useState("");
    const [confirmDataSend, setConfirmDataSend] = useState({});
    const [errorMessage, setErrorMessage] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [selectedPlanTiming, setSelectedPanTime] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);

    useEffect(() => {
        if (stackingPlan.length > 0) {
            const defaultPan = selectedPlanTiming.length !== 0 ? selectedPlanTiming : stackingPlan[0]?.plan_reward_time;
            setSelectedPanTime(defaultPan);
            setSelectedOption({ key: 0, value: defaultPan[0] });
        }
    }, [stackingPlan]);

    const handleChangePlanTiming = (item, index) => {
        setActiveBox({
            ...activeBox,
            index: index,
            item: item,
        });
        const defaultPan = item?.plan_reward_time;
        setSelectedPanTime(defaultPan);
    };

    const handleSelectChange = (event) => {
        const selectedValue = JSON.parse(event.target.value);
        setSelectedOption(selectedValue);
    };

    const [hash, sethash] = useState("");
    const closeModal = () => {
        dispatch(setUpdateApp(!updateWeb));
        setIsModalOpen(false);
    };

    const [activeBox, setActiveBox] = useState({ index: 0, item: null });
    const [Amount, setAmount] = useState({
        step: "",
        amount: "",
    });

    const convertTimestampToYMDMS = (t) => {
        let year, month, day, hour, minute, second;

        second = t % 60;
        t = Math.floor(t / 60);
        minute = t % 60;
        t = Math.floor(t / 60);
        hour = t % 24;
        t = Math.floor(t / 24);
        day = t % 30;
        t = Math.floor(t / 30);
        month = t % 12;
        t = Math.floor(t / 12);
        year = t;

        if (year > 0) {
            return `${year} Year${year > 1 ? "s" : ""}`;
        }
        if (month > 0) {
            return `${month} Month${month > 1 ? "s" : ""}`;
        }
        if (day > 0) {
            return `${day} Day${day > 1 ? "s" : ""}`;
        }
        if (hour > 0) {
            return `${hour} Hour${hour > 1 ? "s" : ""}`;
        }
        if (minute > 0) {
            return `${minute} Minute${minute > 1 ? "s" : ""}`;
        }
        if (second > 0) {
            return `${second} Second${second > 1 ? "s" : ""}`;
        }
        return "0 Second"; // If the duration is 0
    };

    const handleStackToken = async () => {
        // Check if the amount is provided
        if (!Amount.amount.trim()) {
            return toast.error("Please enter the amount.");
        }

        // Validate that the amount is greater than zero
        const amountValue = Number(Amount.amount);
        if (isNaN(amountValue) || amountValue <= 0) {
            return toast.error("Amount should be greater than zero.");
        }

        // Check if the user has sufficient balance
        const userBalance = Number(userdetail?.wallet_details?.amount);
        if (userBalance < amountValue) {
            return toast.error("Insufficient balance. Please add more funds.");
        }

        // Ensure a valid stacking plan is selected
        const selectedPlanIndex = activeBox?.index;
        if (typeof selectedPlanIndex !== "number") {
            return toast.error("Invalid stacking plan selected. Please choose a valid plan.");
        }

        const selectedPlan = stackingPlan?.[selectedPlanIndex];
        if (!selectedPlan) {
            return toast.error("Selected stacking plan is not available. Please try again.");
        }

        // Prepare confirmation data
        setConfirmDataSend({
            ...selectedPlan,
            amount: Amount.amount, // Add the amount property here
            minTimeWithdrawRewards: selectedOption?.value,
        });

        // Open the confirmation modal
        setSelectedModalType("confirm");
        setIsModalOpen(true);
    };

    const onConfirmStake = async () => {
        setIsModalOpen(false);
        try {
            setloading1(true);
            const header = {
                "content-Type": "application/json",
                "x-access-token": token,
            };

            const body = {
                amount: Amount.amount,
                step: activeBox.index,
                reward_time_key: selectedOption.key,
            };

            const method = "post";
            const subUri = "/staking/stake";

            const response = await callApi(header, body, method, subUri);

            if (response.status == 200 || response.status == 201) {
                const hash = response?.data?.hash;
                setloading1(false);
                setIsModalOpen(true);
                setSelectedModalType("success");
                sethash(hash);
                setAmount({ amount: "", step: activeBox });
                await fetchStakeData(dispatch, token);
                await fetchAndDispatchHomeData(dispatch, token);
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.error || error?.response?.data?.message || error?.message || "unknown error";
            toast.error(errorMessage);
            console.log(errorMessage);
            setIsModalOpen(false);
        } finally {
            setloading1(false);
        }
    };

    const handleAmountChange = (e) => {
        const amountValue = e.target.value;
        setAmount({ ...Amount, amount: amountValue });

        // Validate the entered amount
        const userBalance = Number(userdetail?.wallet_details?.amount);
        const enteredAmount = Number(amountValue);

        if (enteredAmount > userBalance) {
            setErrorMessage("The amount is not sufficient..");
            setIsButtonDisabled(true);
        } else {
            setErrorMessage("");
            setIsButtonDisabled(false);
        }
    };

    return (
        <>
            <Box className="buyMainContainer">
                <Box className="DASHBOARDheading">
                    <Box className="titledashboard">
                        <h3>Stake</h3>
                    </Box>
                    <Box className="">
                        <ul className="dashboard_ulItem">
                            <li style={{ color: "var(--white)", fontSize: "12px" }}>Palm Smart Chain</li>
                            <li>/</li>
                            <li>Stake</li>
                        </ul>
                    </Box>
                </Box>
                <CustomLoader loading={loading1} />
                <Container maxWidth="xxl">
                    <Grid container spacing={1}>
                        <Grid item lg="3" md="3" sm="12" xs="12">
                            <Box
                                bgcolor={"var(--darkSoft)"}
                                sx={{
                                    padding: { md: "20px", sm: "15px", xs: "15px" },
                                    borderRadius: "12px",
                                }}
                            >
                                <Typography marginBottom={"10px"} variant="body2" color={"var(--textmuted)"}>
                                    <strong>Paln Duration</strong>
                                </Typography>
                                <Box marginBottom={"1.2rem"}>
                                    {stackingPlan?.length > 0 &&
                                        stackingPlan?.map((item, index) => {
                                            return (
                                                <Box width={"100%"} key={item.id}>
                                                    <button
                                                        className={activeBox.index === index ? "DoCoinbtnActive" : "DoCoinbtn"}
                                                        name="step"
                                                        onClick={() => handleChangePlanTiming(item, index)}
                                                        // onClick={() =>
                                                        //     setActiveBox({
                                                        //         ...activeBox,
                                                        //         index: index,
                                                        //         item: item,
                                                        //     })
                                                        // }
                                                        style={{
                                                            display: "flex",
                                                            textAlign: "justify",
                                                            width: "100%",
                                                            alignItems: "center",
                                                            justifyContent: "space-between",
                                                            marginBottom: "10px",
                                                        }}
                                                    >
                                                        <Typography fontSize="20px">{item.plan_name}</Typography>
                                                        <Typography fontSize="15px">{`${item.plan_apy}%`}</Typography>
                                                    </button>
                                                </Box>
                                            );
                                        })}
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item lg="9" md="9" sm="12" xs="12">
                            <Box bgcolor={"var(--darkSoft)"} sx={{ padding: { md: "20px", sm: "15px", xs: "15px" }, borderRadius: "10px" }}>
                                <Typography marginBottom={"10px"} variant="body1" color={"var(--white)"}>
                                    Add value : {Number(userdetail?.wallet_details?.amount).toFixed(2)} PALM
                                </Typography>

                                <Box>
                                    <Box display="flex" alignItems="center">
                                        <button className="inrbtn">PalmSmart</button>
                                        <input className="input" type="number" min={0} placeholder="0.00" onChange={handleAmountChange} value={Amount.amount} />
                                    </Box>
                                </Box>
                                <Box marginY="1rem">
                                    <Typography marginBottom={"10px"} variant="body1" color={"var(--white)"}>
                                        Select Rewards Days :
                                    </Typography>
                                    <select className="input" onChange={handleSelectChange}>
                                        <option value="" disabled>
                                            Select a reward
                                        </option>
                                        {selectedPlanTiming?.length > 0 ? (
                                            selectedPlanTiming.map((item, index) => (
                                                <option key={index} value={JSON.stringify({ key: index, value: item })}>
                                                    {convertTimestampToYMDMS(item)}
                                                </option>
                                            ))
                                        ) : (
                                            <option disabled>No rewards time plan available</option>
                                        )}
                                    </select>
                                </Box>
                                {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                                <Box marginTop={"1.5rem"} textAlign={"center"}>
                                    <button className="buyButtons" disabled={isButtonDisabled} onClick={() => handleStackToken(activeBox)} style={{ background: "var(--primaryColor)", width: "100%" }}>
                                        Stake
                                    </button>
                                    {/* )} */}
                                </Box>
                                <PopupModal isOpen={isModalOpen} onClose={closeModal} hash={hash} modalType={selectedModaltype} onConfirm={onConfirmStake} confirmData={confirmDataSend} isLoading={loading1} />
                            </Box>
                        </Grid>
                    </Grid>
                    <TransactionsData />
                </Container>
                <ToastContainer />
            </Box>
        </>
    );
};

export default Stake;
