import React, { useState } from "react";
import { Box, Container, Grid, IconButton, Typography } from "@mui/material";
import "./kyc.css";
import kycimg from "../../components/assets/kyc-verifications.png";
import CloseIcon from "@mui/icons-material/Close";

const Modal = ({ onClose }) => {
    const [tabShow, setShowTabs] = useState("tab1");

    const handleTabClick = (tab) => {
        setShowTabs(tab);
    };

    const [selectedFile, setSelectedFile] = useState(null);

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        setSelectedFile(file);
    };

    const handleInputChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    return (
        <div className="modalKYCContainer">
            <div className="modalKYCScontent">
                <Box display="flex" alignItems="center" justifyContent="space-between">
                    <Typography>Verify your Account</Typography>
                    <IconButton className="close" onClick={onClose}>
                        <CloseIcon sx={{ color: "var(--white)" }} />
                    </IconButton>
                </Box>
                <Box>
                    <Box marginY={"2rem"} sx={{ display: { md: "flex", sm: "flex", xs: "flex" }, alignItems: "center", flexWrap: "wrap", bgcolor: "#2F3652" }}>
                        <button className={tabShow === "tab1" ? "tabsActives" : "tabsbtnkyc"} onClick={() => handleTabClick("tab1")}>
                            <span className="roundedNumbers">1</span>
                            Country
                        </button>
                        <button className={tabShow === "tab2" ? "tabsActives" : "tabsbtnkyc"} onClick={() => handleTabClick("tab2")}>
                            <span className="roundedNumbers">2</span>
                            KYC
                        </button>
                        <button className={tabShow === "tab3" ? "tabsActives" : "tabsbtnkyc"} onClick={() => handleTabClick("tab3")}>
                            <span className="roundedNumbers">3</span>
                            Mobile
                        </button>
                        <button className={tabShow === "tab4" ? "tabsActives" : "tabsbtnkyc"} onClick={() => handleTabClick("tab4")}>
                            <span className="roundedNumbers">4</span>
                            Aadhar
                        </button>
                        <button className={tabShow === "tab5" ? "tabsActives" : "tabsbtnkyc"} onClick={() => handleTabClick("tab5")}>
                            <span className="roundedNumbers">5</span>
                            Pan Card
                        </button>
                    </Box>
                    <Box marginY={"2rem"}>
                        {tabShow === "tab1" && (
                            <Box>
                                <Box marginY={"1rem"}>
                                    <Typography gutterBottom variant="body2" color={"var(--textmuted)"} marginBottom={"10px"}>
                                        Select your Country
                                    </Typography>
                                    <input className="input" placeholder="Enter your country" style={{ borderRadius: "5px", height: "48px", borderColor: "#58648b" }} />
                                </Box>
                            </Box>
                        )}
                        {tabShow === "tab2" && (
                            <Box>
                                <Box marginY={"1rem"}>
                                    <Typography gutterBottom variant="body2" color={"var(--textmuted)"} marginBottom={"10px"}>
                                        Govt. ID Upload
                                    </Typography>
                                    <input className="input" placeholder="Enter your country" style={{ borderRadius: "5px", height: "40px", borderColor: "#58648b" }} />
                                </Box>
                                <Box marginY={"1rem"}>
                                    <Typography gutterBottom variant="body2" color={"var(--textmuted)"} marginBottom={"10px"}>
                                        Front Image
                                    </Typography>
                                    <input className="inputfile" type="file" placeholder="Upload File" style={{ borderRadius: "5px", borderColor: "#58648b" }} />
                                </Box>
                                <Box marginY={"1rem"}>
                                    <Typography gutterBottom variant="body2" color={"var(--textmuted)"} marginBottom={"10px"}>
                                        Rear Image
                                    </Typography>
                                    <input className="inputfile" type="file" placeholder="Upload File" style={{ borderRadius: "5px", borderColor: "#58648b" }} />
                                </Box>
                                <Box marginY={"1rem"}>
                                    <Typography gutterBottom variant="body2" color={"var(--textmuted)"} marginBottom={"10px"}>
                                        Profile Image
                                    </Typography>
                                    <input className="inputfile" type="file" placeholder="Upload File" style={{ borderRadius: "5px", borderColor: "#58648b" }} />
                                </Box>
                            </Box>
                        )}
                        {tabShow === "tab3" && (
                            <Box>
                                <Box marginY={"1rem"}>
                                    <Typography gutterBottom variant="body2" color={"var(--textmuted)"} marginBottom={"10px"}>
                                        Mobile Number
                                    </Typography>
                                    <input className="input" placeholder="Mobile Number" style={{ borderRadius: "5px", height: "40px", borderColor: "#58648b" }} />
                                </Box>
                                <Box marginY={"1rem"}>
                                    <Typography gutterBottom variant="body2" color={"var(--textmuted)"} marginBottom={"10px"}>
                                        OTP Verify
                                    </Typography>
                                    <input className="input" placeholder="OPT Verify" style={{ borderRadius: "5px", height: "40px", borderColor: "#58648b" }} />
                                </Box>
                            </Box>
                        )}
                        {tabShow === "tab4" && (
                            <Box>
                                <Box marginY={"1rem"}>
                                    <Typography gutterBottom variant="body2" color={"var(--textmuted)"} marginBottom={"10px"}>
                                        Aadhar Card Number
                                    </Typography>
                                    <input className="input" placeholder="Aadhar Card Number" style={{ borderRadius: "5px", height: "40px", borderColor: "#58648b" }} />
                                </Box>
                                <Box marginY={"1rem"}>
                                    <Typography gutterBottom variant="body2" color={"var(--textmuted)"} marginBottom={"10px"}>
                                        OTP Verify
                                    </Typography>
                                    <input className="input" placeholder="OPT Verify" style={{ borderRadius: "5px", height: "40px", borderColor: "#58648b" }} />
                                </Box>
                            </Box>
                        )}
                        {tabShow === "tab5" && (
                            <Box>
                                <Box marginY={"1rem"}>
                                    <Typography gutterBottom variant="body2" color={"var(--textmuted)"} marginBottom={"10px"}>
                                        Pan Card No.
                                    </Typography>
                                    <input className="input" placeholder="Pan Card No." style={{ borderRadius: "5px", height: "40px", borderColor: "#58648b" }} />
                                </Box>
                                <Box marginY={"1rem"}>
                                    <Typography gutterBottom variant="body2" color={"var(--textmuted)"} marginBottom={"10px"}>
                                        Upload Document file for a Verification
                                    </Typography>
                                    <div className="inputfile" style={{ borderRadius: "5px", borderColor: "#58648b", padding: "20px", textAlign: "center", border: "1px dashed #58648b", height: '100%' }} onDrop={handleDrop} onDragOver={handleDragOver}>
                                        {selectedFile ? (
                                            <div>
                                                <h4>Selected File:</h4>
                                                <p>{selectedFile.name}</p>
                                            </div>
                                        ) : (
                                            <div>

                                                <input type="file" accept=".pdf,.doc,.docx,.png,.jpg" onChange={handleInputChange} style={{ display: "none" }} id="fileInput" />
                                                <label htmlFor="fileInput" style={{ cursor: "pointer" }}>
                                                    Drag & Drop your file here
                                                </label>
                                            </div>
                                        )}
                                    </div>
                                </Box>
                            </Box>
                        )}
                    </Box>
                    <Box textAlign={"end"}>
                        <button className="Verificationbtn" width="100%" style={{ height: "40px" }}>
                            Preview
                        </button>
                        <button className="Verificationbtn" width="100%" style={{ height: "40px" }}>
                            Next
                        </button>
                    </Box>
                </Box>
            </div>
        </div>
    );
};

const KYCVerification = () => {
    const [showModal, setShowModal] = useState(false);

    const toggleModal = () => {
        setShowModal(prevShowModal => !prevShowModal);
    };

    return (
        <Box>
            <Box className="DASHBOARDheading">
                <Box className="titledashboard">
                    <h3>KYC Verification</h3>
                </Box>
                <Box className="">
                    <ul className="dashboard_ulItem">
                        <li style={{ color: "var(--white)", fontSize: '12px' }}>Palm Smart Chain</li>
                        <li>/</li>
                        <li>KYC Verification</li>
                    </ul>
                </Box>
            </Box>
            <Container maxWidth="xxl">
                <Grid container>
                    <Grid item lg={5} md={5} sm={12} xs={12} margin={"auto"}>
                        <Box bgcolor={"var(--darkSoft)"} sx={{ padding: { md: "30px" }, borderRadius: "10px" }} marginBottom={"1.5rem"}>
                            <Box>
                                <Typography variant="h5" fontWeight={"bold"} textAlign={"center"} color={"var(--white)"} gutterBottom>
                                    KYC Verification
                                </Typography>
                                <Typography variant="body1" textAlign={"center"} color={"var(--bs-menu-item-color)"} gutterBottom>
                                    Itaque earun rerum hic tenetur a sapiente delectus ut aut reiciendis perferendis asperiores repellat
                                </Typography>
                            </Box>
                            <Box marginY={"1.5rem"} textAlign={"center"}>
                                <button className="Verificationbtn" onClick={toggleModal}>
                                    Click here for Verification
                                </button>
                                <button className="Verificationbtn" style={{ backgroundColor: "var(--secondaryColor)" }}>
                                    Skip for now
                                </button>
                            </Box>
                            <Box textAlign={"center"} width={"100%"}>
                                <img src={kycimg} alt="" />
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box>{showModal && <Modal onClose={toggleModal} />}</Box>
        </Box>
    );
};

export default KYCVerification;
