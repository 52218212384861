import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import jsPDF from "jspdf";
import "jspdf-autotable";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";


// Utility function to convert currency value
const convertCurrency = (value, tokenDecimal) => {
  return (parseFloat(value) / Math.pow(10, tokenDecimal));
};

const amountTransactionType = (from, to, selectedAccount) => {
  if (from.toLowerCase() === selectedAccount.toLowerCase()) {
    return "Send";
  } else if (to.toLowerCase() === selectedAccount.toLowerCase()) {
    return "Receive";
  }
  return "Unknown";
};

// Function to export data to CSV format
export const exportToCSV = (data) => {
  const csvData = data.map((item) => Object.values(item));
  const csvContent = csvData.map((row) => row.join(",")).join("\n");
  const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
  saveAs(blob, "data.csv");
};

const convertTimeStampToDate = (timeStamp) => {
  const timeStampInMs = parseInt(timeStamp) * 1000;
  const date = new Date(timeStampInMs);
  return date.toLocaleDateString();
};


export const exportToExcel = (data, selectedAccount) => {
  // Convert data to array of arrays for XLSX
  const excelData = data.map((item) => {

    return [
      convertTimeStampToDate(item.timeStamp),
      item.hash,
      amountTransactionType(item.from, item.to, selectedAccount),
      item.from,
      item.to,
      `${convertCurrency(item.value, item.tokenDecimal)} ${item.tokenSymbol}`
    ];
  });

  // Create a worksheet
  const worksheet = XLSX.utils.aoa_to_sheet([["Date", "Transaction Hash", "Action", "From", "To", "Amount"], ...excelData]);

  // Create a workbook
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

  // Convert workbook to Excel buffer
  const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

  // Convert buffer to Blob
  const blob = new Blob([excelBuffer], { type: "application/octet-stream" });

  // Save Blob as Excel file
  saveAs(blob, "transaction_data.xlsx");
};



export const exportToPDF = (data, selectedAccount) => {

  const doc = new jsPDF();

  // Define table headers
  const headers = ["Date", "Transaction Hash", "action", "from", "to", "Amount"];

  const tableData = data.map((item) => [
    convertTimeStampToDate(item.timeStamp),
    item.hash,
    amountTransactionType(item.from, item.to, selectedAccount),
    item.from,
    item.to,
    `${convertCurrency(item.value, item.tokenDecimal)} ${item.tokenSymbol}`
  ]);

  const options = {
    startY: 20, // Initial y position for the table (optional)
    margin: { top: 20 }, // Margin top
    headStyles: { fillColor: [85, 110, 230] }, // Header background color
    styles: { overflow: "linebreak" }, // Handle long text by wrapping
    columnStyles: {
      0: { cellWidth: 30 },
      1: { cellWidth: 30 },
      2: { cellWidth: 20 },
      3: { cellWidth: 25 },
      4: { cellWidth: 25 },
      5: { cellWidth: 25 },
      6: { cellWidth: 25 },
    },
    addPageContent: function (data) {
      // Add page number
      doc.text("Page " + data.pageNumber, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10);
    },
    cellPadding: 2,
  };

  // Generate the table
  doc.autoTable(headers, tableData, options);

  // Save the PDF
  doc.save("transaction_data.pdf");
};

export const copyToClipboard = async (data, selectedAccount) => {
  try {
    const tableData = await Promise.all(
      data.map(async (item) => {

        const rowData = [
          convertTimeStampToDate(item.timeStamp),
          item.hash,
          amountTransactionType(item.from, item.to, selectedAccount),
          item.from,
          item.to,
          `${convertCurrency(item.value, item.tokenDecimal)} ${item.tokenSymbol}`
        ].join("\t");

        return rowData;
      })
    );

    const tableText = tableData.join("\n");

    navigator.clipboard.writeText(tableText);
    toast.success("Table data copied to clipboard!");
  } catch (error) {
    console.error("Failed to copy table data: ", error);
  }
};
