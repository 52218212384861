import React from 'react';
import './CustomLoader.css'; // Ensure the correct path to your CSS file
import { Typography } from '@mui/material';

const CustomLoader = ({ loading }) => {
  // Conditionally render the loader based on the 'loading' prop
  if (!loading) return null;

  return (
    <div className='loaders' style={{ flexDirection: "column", gap: '10px', }}>
      <div className='loader-text'>
        <Typography variant='h5' color="var(--white)">
          Transaction under process. Don't leave or refresh the page.
        </Typography>
      </div>
      <div className='loader' />
    </div>
  );
};

export default CustomLoader;
