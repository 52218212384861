import React, { useEffect, useState } from 'react';
import './SelectedOption.css';
import { Box } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useDispatch } from 'react-redux';
import { setSelectedSaleToken, setSelectedBuyWithToken } from '../redux/slices/authSlice';

const SelectedOption = ({ tokensWithout973, tokenType }) => {
  const dispatch = useDispatch();
  const [selectedOption, setSelectedOption] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (tokensWithout973.length > 0) {
      const defaultCurrency = tokensWithout973[0];
      if (!selectedOption) {
        setSelectedOption(defaultCurrency);
        dispatch(setSelectedSaleToken(defaultCurrency));
        dispatch(setSelectedBuyWithToken(defaultCurrency));
      }
    }
  }, [tokensWithout973, selectedOption, dispatch]);

  const handleOptionClick = (option) => {
    if (option !== selectedOption) {
      setSelectedOption(option);
      if (tokenType === "sell") {
        dispatch(setSelectedSaleToken(option));
      } else {
        dispatch(setSelectedBuyWithToken(option));
      }
      setIsOpen(false);
    }
  };

  return (
    <div className="dropdown-container">
      <div className="dropdown-header" onClick={() => setIsOpen(!isOpen)}>
        {selectedOption ? (
          <Box display="flex" alignItems="center" gap="10px">
            <img
              src={selectedOption.image}
              alt={selectedOption.symbol}
              className="dropdown-image"
            />
            <span>{selectedOption.symbol} ({selectedOption.name}) </span>
          </Box>
        ) : (
          <span>Select Currency</span>
        )}
        <span className="arrow">
          {isOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </span>
      </div>
      {isOpen && (
        <ul className="dropdown-list">
          {tokensWithout973.map((option, index) => (
            <li
              key={index}
              value={`${option.chain_id},${option.symbol}`}
              className="dropdown-list-item"
              onClick={() => handleOptionClick(option)}
            >
              <img
                src={option.image}
                alt={option.symbol}
                className="dropdown-image"
              />
              <span>{option.symbol} ({option.name})</span>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default SelectedOption;
