import { configureStore } from '@reduxjs/toolkit';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// import AuthSlices from '../AuthSlices/AuthSlices';
import authSlice from '../slices/authSlice';


const persistConfig = {
    key: 'root',
    storage,
  };
  
    const persistedReducer = persistReducer(persistConfig, authSlice);
  const store = configureStore({
      reducer:{
        auth: persistedReducer  
      },
  });
  export const persistor = persistStore(store);
export default store;