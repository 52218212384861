import React from "react";
import { IconButton, Typography, Box } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Link } from "react-router-dom";
import successful from "../pages/buys/success-payment-approved-png.webp";

export const SuccessModal = ({ isOpen, onClose, modalType, responseMessage, titleText = "Transaction Completed!", buttonText = "View in Explorer" }) => {
    if (!isOpen) return null;

    return (
        <div className="modalContainers">
            <div className="modalRows">
                <div className="modalCloseRows">
                    <IconButton onClick={onClose}>
                        <CloseIcon sx={{ color: "var(--white)", fontSize: 18 }} />
                    </IconButton>
                </div>
                <div className="modal-content">
                    {modalType === "success" && (
                        <div>
                            {/* {imageSrc && ( */}
                            <div>
                                <img src={successful} width="250" alt="Success" />
                            </div>
                            {/* )} */}
                            <Box>
                                <Typography fontSize="30px" gutterBottom>
                                    {titleText}
                                </Typography>
                            </Box>
                            {responseMessage?.title && <h3>{responseMessage.title}</h3>}
                            {responseMessage?.hash && (
                                <Link target="_blank" to={responseMessage.hash}>
                                    <button onClick={onClose} className="sendButtons" style={{ color: "#fff" }}>
                                        {buttonText}
                                    </button>
                                </Link>
                            )}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
