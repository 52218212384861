import React, { useEffect, useState } from "react";
import "./Home.css";
import users from "../../components/assets/icons/users.png";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import ChartWidget from "./ChartWidget";
import TransactionsDashboard from "./TransactionsDashboard";
import axios from "axios";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-toastify/dist/ReactToastify.css";
import { setChangeBuyTab, setStackingPlan } from "../../redux/slices/authSlice";
import StockTicker from "./StockMarket/StockMarketLoader";
import defaultImage from "../../components/assets/PalmLogo.png";
import { imagePath } from "../../components/navbar/Navbar";

const Home = () => {
  const PALM_CHAIN_ID = Number(process.env.REACT_APP_PALM_CHAIN_ID);
  const data = useSelector((state) => state.auth.authToken);
  const transactionDataTable = useSelector((state) => state.auth.transactionsReport) || [];
  const tokenFromRedux = useSelector((state) => state.auth.authToken);
  const userdetail = useSelector((state) => state.auth.importedAdress);
  const detailsUser = useSelector((state) => state.auth.userDetails);
  const allTokens = userdetail?.BuyWith || [];
  const tokensWithout973 = allTokens.filter((token) => token.chain_id !== PALM_CHAIN_ID);

  const userEditableDetails = useSelector((state) => state.auth.UserDetailsEditable);

  const [copiedMessage, setCopiedMessage] = useState("");

  const handleCopyAddress = () => {
    const textarea = document.createElement("textarea");
    textarea.value = detailsUser?.selectedAccount;
    document.body.appendChild(textarea);

    textarea.select();
    document.execCommand("copy");
    document.body.removeChild(textarea);
    setCopiedMessage("Address copied!");
    setTimeout(() => {
      setCopiedMessage("");
    }, 2000);
  };

  const buydata = [
    {
      id: 2,
      icon: require("../../components/assets/icons/send.png"),
      title: "Send",
      path: "/buy",
    },
    {
      id: 3,
      icon: require("../../components/assets/icons/receive.png"),
      title: "Receive",
      path: "/buy",
    },
    {
      id: 4,
      icon: require("../../components/assets/icons/swap.png"),
      title: "Swap",
      path: "/buy",
    },
    {
      id: 5,
      icon: require("../../components/assets/icons/stake.png"),
      title: "Stake",
      path: "/stake",
    },
  ];
  const addres = detailsUser ? detailsUser?.selectedAccount : "userdetail";
  const formattedAddress = `${String(addres).slice(0, 6)}...${String(addres).slice(-6)}`;

  const dispatch = useDispatch();

  const handleMenuItemClick = async (title) => {
    if (title === "Stake" || title === "Send" || title === "Receive" || title === "Swap") {
      dispatch(setChangeBuyTab(title));
    }
    // if (title === "Dashboard") {
    //   dispatch(setChangeBuyTab("Swap"));
    //   const newURL = `/`;
    //   await window.history.pushState({}, "", newURL);
    // }
  };

  // stacking plan
  const getStackingPlan = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URI}/staking/active-plans`, {
        headers: {
          "x-access-token": tokenFromRedux,
        },
      });
      const response = res?.data;
      if (res.status === 200 || res.data === 201) {
        dispatch(setStackingPlan(response));
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  useEffect(() => {
    getStackingPlan();
  }, []);

  return (
    <div>
      <div style={{ marginBottom: "1rem" }}>
        <StockTicker />
      </div>
      <div className="DASHBOARDheading">
        <div className="titledashboard">
          <h3>DASHBOARD</h3>
        </div>

        <div className="">
          <ul className="dashboard_ulItem">
            <li style={{ color: "var(--white)", fontSize: "12px" }}>Palm Smart Chain</li>
            <li>/</li>
            <li>Dashboard</li>
          </ul>
        </div>
      </div>
      <div className="MainHome">
        <div className="Box Box1">
          <div className="abhayBoxs">
            <div className="usersimg">
              {/* <img src={users} alt="" width={"32px"} /> */}
              <img src={userEditableDetails?.image ? `${imagePath}${userEditableDetails?.image}` : defaultImage} alt="" width={"32px"} />
            </div>
            <div className="userrdetails">
              <h4>{detailsUser?.name}</h4>
              <span className="userEmail">{detailsUser.email}</span>
              <div className="adreesscontract">
                <span className="addCopy" onClick={handleCopyAddress} style={{ cursor: "pointer" }}>
                  Address : {formattedAddress}
                  <FileCopyOutlinedIcon sx={{ fontSize: 16 }} />
                </span>
                {copiedMessage && <span>{copiedMessage}</span>}
              </div>
            </div>
          </div>
          <div className="Available__Balance_Boxs">
            <div className="Available__Balance">
              <div className="AvailableRow">
                <h6>Available Balance</h6>
                <h5>${Number(userdetail?.wallet_details?.amount_usd).toFixed(2)}</h5>
              </div>
              <div className="AvailableRow text-right">
                <h6>PALM Balance</h6>
                <h5>PALM {`${Number(userdetail?.wallet_details?.amount)} `}</h5>
              </div>
            </div>
          </div>
          <div className="BalanceBoxContainer">
            {buydata.map((items, index) => (
              <div className="BalanceBox BalanceBox1" key={index}>
                <Link to={items.path} style={{ textAlign: "center" }} onClick={() => handleMenuItemClick(items.title)}>
                  <img src={items.icon} alt="" style={{ margin: "auto", marginTop: "20px" }} />
                  <div className="buyBtns">
                    <button className="btnBuy">{items.title}</button>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
        {tokensWithout973.length > 0 &&
          tokensWithout973.map((item, index) => (
            <div className="Box Box4" key={index}>
              <div className="EthereumContainer">
                <img src={item.image} alt="" width="40px" />
                <div className="EthereumTitle">
                  <span>{item?.name}</span>
                  <h4>
                    {Number(item?.balance)} {item?.symbol}
                  </h4>
                </div>
              </div>
            </div>
          ))}
        <div className="Box Box5">
          <div className="Overviewtitle">
            <h4>Overview</h4>
          </div>
          <ChartWidget />
        </div>
        <div className="Box Box6">
          <div className="TransactionsDashboardTitle">
            <h4>Transactions</h4>
          </div>
          {transactionDataTable.length > 0 && <TransactionsDashboard trxTable={transactionDataTable} />}
        </div>
      </div>
    </div>
  );
};

export default Home;
