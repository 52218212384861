import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import signLogo from "../assets/logo/Palm-Logo.png";
import { Link, useLocation, useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import EmailIcon from "@mui/icons-material/Email";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callApi } from "../../api";
import PhoneIcon from "@mui/icons-material/Phone";

const ConfirmPasswordVerification = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { email } = location.state || {};
    const [password, setPassword] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [isValidPassword, setIsValidPassword] = useState(true);

    // State for OTPs
    const [emailOtp, setEmailOtp] = useState(["", "", "", "", "", ""]);
    const [phoneOtp, setPhoneOtp] = useState(["", "", "", "", "", ""]);
    const handleemailChange = (e, i) => {
        let { value } = e.target;
        value = value.replace(/\D/g, "");
        const newOtp = [...emailOtp];
        newOtp[i] = value;
        setEmailOtp(newOtp);
    };
    const handlePhoneChange = (e, i) => {
        let { value } = e.target;
        value = value.replace(/\D/g, "");
        const newOtp = [...phoneOtp];
        newOtp[i] = value;
        setPhoneOtp(newOtp);
    };

    const handleemailKeyDown = (e, i) => {
        const prevInput = e.target.previousSibling;
        const nextInput = e.target.nextSibling;

        if (e.key === "Backspace" && i === 0) {
        } else if (e.key === "Backspace" && e.target.value === "") {
            e.preventDefault();
            if (prevInput) {
                prevInput.focus();
            }
        } else if (e.key === "Backspace" && i > 0 && e.target.value !== "") {
        } else if (i < emailOtp.length - 1 && e.target.value !== "") {
            if (nextInput) {
                nextInput.focus();
            }
        }
    };
    const handlephoneKeyDown = (e, i) => {
        const prevInput = e.target.previousSibling;
        const nextInput = e.target.nextSibling;

        if (e.key === "Backspace" && i === 0) {
        } else if (e.key === "Backspace" && e.target.value === "") {
            e.preventDefault();
            if (prevInput) {
                prevInput.focus();
            }
        } else if (e.key === "Backspace" && i > 0 && e.target.value !== "") {
        } else if (i < phoneOtp.length - 1 && e.target.value !== "") {
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    const handleChangePassword = (event) => {
        const { value } = event.target;
        setPassword(value);
        setIsValidPassword(validatePassword(value));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const password = e.target.password.value;
        const confirmPassword = e.target.confirmPassword.value;
        const formattedEmailOtp = emailOtp.join("");
        const formattedPhoneOtp = phoneOtp.join("");
        if (!formattedEmailOtp || !formattedPhoneOtp) {
            toast.error("Both OTPs are required");
            return;
        }
        if (!validatePassword(password)) return toast.error("Password is weak");
        if (password !== confirmPassword) return toast.error("Password is not matched");
        try {
            const header = {
                "content-Type": "application/json",
                // 'x-access-token': token
            };
            const body = { otp: formattedPhoneOtp, password, email_otp: formattedEmailOtp, email };
            const method = "post";
            const subUri = "/user/updateForgetPassword";
            const response = await callApi(header, body, method, subUri);
            if (response.status == 200 || response.status == 201) {
                const message = response.data.message;
                await toast.success(message);
                navigate("/login");
            }
        } catch (error) {
            const errorMessage = error.response.data.error || error.response.data.message || error.message || "unknown error";
            toast.error(errorMessage);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box className="SignUpContainer" height={"100%"}>
                <Container maxWidth="xl">
                    <Grid container>
                        <Grid lg="4" md="4" sm="12" xs="12" margin={"auto"}>
                            <Box>
                                <Box textAlign={"center"}>
                                    <Box>
                                        <img src={signLogo} alt="" width={"120px"} />
                                    </Box>
                                    {/* <Box>
                                    <Typography variant="h5" fontWeight={'600'} color={"var(--body)"}>
                                      Confirm Password
                                    </Typography> 
                                  </Box> */}
                                </Box>
                                <Box>
                                    <Box position="relative" bgcolor={"var(--white)"} zIndex="9" borderRadius={"1rem"} padding={"1.5rem"} boxShadow={"0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"}>
                                        <Box display="flex" alignItems="center" gap="10px" marginBottom="1rem">
                                            <Box className="EmailIcons">
                                                <EmailIcon sx={{ color: "var(--primaryColor)", fontSize: 30 }} />
                                            </Box>
                                            <Typography variant="h5" color="var(--body)" fontWeight={"500"}>
                                                Verify your email
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                                            {emailOtp.map((value, index) => (
                                                <input
                                                    key={`email-otp-${index}`}
                                                    type="text"
                                                    className="textInput"
                                                    maxLength="1"
                                                    value={value}
                                                    onChange={(e) => handleemailChange(e, index, "email")}
                                                    onKeyDown={(e) => handleemailKeyDown(e, index, "email")}
                                                    required
                                                />
                                            ))}
                                        </Box>
                                    </Box>

                                    {/* Phone OTP Verification */}
                                    <Box marginY='10px' position="relative" bgcolor={"var(--white)"} zIndex="9" borderRadius={"1rem"} padding={"1.5rem"} boxShadow={"0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"}>
                                        <Box display="flex" alignItems="center" gap="10px" marginBottom="1rem">
                                            <Box className="EmailIcons" textAlign="center">
                                                <PhoneIcon sx={{ color: "var(--primaryColor)", fontSize: 30 }} />
                                            </Box>
                                            <Typography variant="h5" color="var(--body)" fontWeight={"500"}>
                                                Verify your Number
                                            </Typography>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                                            {phoneOtp.map((value, index) => (
                                                <input
                                                    key={`phone-otp-${index}`}
                                                    type="text"
                                                    className="textInput"
                                                    maxLength="1"
                                                    value={value}
                                                    onChange={(e) => handlePhoneChange(e, index, "phone")}
                                                    onKeyDown={(e) => handlephoneKeyDown(e, index, "phone")}
                                                    required
                                                />
                                            ))}
                                        </Box>
                                    </Box>

                                    <Box position="relative" bgcolor={"var(--white)"} zIndex="9" borderRadius={"1rem"} padding={"1.5rem"} boxShadow={"0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"}>
                                        <Box sx={{ marginBottom: "15px" }}>
                                            <Typography variant="body" gutterBottom color={"var(--textmuted)"}>
                                                New Password
                                            </Typography>
                                            <input placeholder="Enter password" type="password" className="textInput" name="password" onChange={handleChangePassword} required />
                                        </Box>

                                        <Box sx={{ marginBottom: "15px" }}>
                                            <Typography variant="body" gutterBottom color={"var(--textmuted)"}>
                                                Confirm New Password
                                            </Typography>
                                            <input placeholder="Enter confirm password" type="password" className="textInput" name="confirmPassword" required />
                                        </Box>
                                    </Box>
                                </Box>
                                <Box>
                                    <Box sx={{ width: { md: "100%" }, margin: "auto" }}>
                                        <button className="Continue" style={{ background: "var(--primaryColor)" }}>
                                            Comfirm
                                        </button>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <ToastContainer />
        </form>
    );
};

export default ConfirmPasswordVerification;
