import { Box, Container, Grid, Typography } from '@mui/material'
import React from 'react'
import './Setting.css'

const Settings = () => {
    return (
        <div>
            <Container maxWidth="xl">
                <Box marginBottom={"2rem"}>
                    <Typography color={"var(--white)"} fontWeight={"500"} variant="h5">
                        Setting
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item lg="12" md="12" sm="12" xs="12">
                        <Box className="SettingContainer">
                            <Box paddingY='1rem' sx={{display:'flex', alignItems:'center', justifyContent:'space-between'}}>
                                <Box>
                                    <Typography>Notification</Typography>
                                </Box>
                                <Box></Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
}

export default Settings