import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  WhatsappShareButton,
  TelegramShareButton,
  RedditShareButton,
  EmailShareButton,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  TelegramIcon,
  RedditIcon,
  EmailIcon,
} from "react-share";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { IconButton } from "@mui/material";
import { toast } from "react-toastify";
const SocialShareModal = ({ isOpen, onClose, url, title }) => {
  if (!isOpen) return null;


  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      console.log("Copied to clipboard");
      toast.success("Copied to clipboard"); // Use success toast for positive feedback
    } catch (err) {
      console.error("Failed to copy to clipboard:", err);
      toast.error("Failed to copy"); // Use error toast for feedback
    }
  };

  return (
    <div>
      <div className="modal-overlay">
        <div className="modal-content">
          <div className="flexRow">
            <h2>Palm Smart Chain</h2>
            <button className="close-btn" onClick={onClose}>
              <CloseIcon sx={{ color: "var(--primaryColor)" }} />
            </button>
          </div>
          <div className="share-buttons">
            <FacebookShareButton url={url} quote={title}>
              <FacebookIcon size={40} round />
            </FacebookShareButton>
            <TwitterShareButton url={url} title={title}>
              <TwitterIcon size={40} round />
            </TwitterShareButton>
            <LinkedinShareButton url={url}>
              <LinkedinIcon size={40} round />
            </LinkedinShareButton>
            <WhatsappShareButton url={url} title={title}>
              <WhatsappIcon size={40} round />
            </WhatsappShareButton>
            <TelegramShareButton url={url} title={title}>
              <TelegramIcon size={40} round />
            </TelegramShareButton>
            <RedditShareButton url={url} title={title}>
              <RedditIcon size={40} round />
            </RedditShareButton>
            <EmailShareButton url={url} title={title}>
              <EmailIcon size={40} round />
            </EmailShareButton>
            <IconButton onClick={handleCopy} sx={{ backgroundColor: '#9568ff !important' }}>
              <ContentCopyIcon size={40} sx={{ color: '#fff' }} />
            </IconButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialShareModal;
