import React, { useEffect, useState } from "react";
import "./menu.css";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { colors } from "@mui/material";
import { setChangeBuyTab } from "../../redux/slices/authSlice";

const Menu = () => {
  const isdarkMode = useSelector((state) => state.auth.isDarkMode);
  const currentTab = useSelector((state) => state.auth.currentButTab);
  const dispatch = useDispatch();

  const handleMenuItemClick = async (title) => {
    dispatch(setChangeBuyTab(title));
  };

  useEffect(() => {
    if (currentTab === "Buy" || currentTab === "Send" || currentTab === "Gift" || currentTab === "Receive" || currentTab === "Swap") {
      const newURL = `./buy?type=${currentTab}`;
      window.history.pushState({}, "", newURL);
    }
  }, [currentTab]);

  const menus = [
    {
      id: 1,
      title: "Menu",
      listItems: [
        {
          id: 1,
          title: "Dashboard",
          url: "/",
          icon: require("../assets/menu/dashboard.png"),
        },

        {
          id: 2,
          title: "Send",
          url: `/buy?type=${currentTab}`,
          icon: require("../assets/menu/send.png"),
        },
        {
          id: 3,
          title: "Receive",
          url: `/buy?type=${currentTab}`,
          icon: require("../assets/menu/receive.png"),
        },
        {
          id: 4,
          title: "Swap",
          url: `/buy?type=${currentTab}`,
          icon: require("../assets/menu/swap.png"),
        },
        {
          id: 6,
          title: "Gift",
          url: `/buy?type=${currentTab}`,
          icon: require("../assets/menu/transactions.png"),
        },
        {
          id: 5,
          title: "Stake",
          url: "/stake",
          icon: require("../assets/menu/stake.png"),
        },
        // {
        //   id: 9,
        //   title: 'Import Token',
        //   url: '/import-token',
        //   icon: require('../assets/menu/import-token.png')
        // },

        {
          id: 7,
          title: "Help Support",
          url: "/help-support",
          icon: require("../assets/menu/help-support.png"),
        },
        {
          id: 8,
          title: "Reports",
          url: "/reports",
          icon: require("../assets/menu/reports.png"),
        },
      ],
    },
  ];
  return (
    <div className="Menu">
      {menus.map((items) => (
        <div className="item" key={items.id}>
          <span className="title">{items.title}</span>
          {items.listItems.map((listItem) => (
            <Link to={listItem.url} className={`listItem ${listItem.title === currentTab ? 'active' : ''}`} key={listItem.id} onClick={() => handleMenuItemClick(listItem.title)}>
              <img src={listItem.icon} alt="" style={{ height: 20, width: 20 }} />
              <span className="listItemTitle">{listItem.title}</span>
            </Link>
          ))}
        </div>
      ))}
    </div>
  );
};

export default Menu;
