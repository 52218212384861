import { Box, CircularProgress, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import TelegramIcon from "@mui/icons-material/Telegram";
import "./helpsupport.css";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { callApi } from "../../api";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PositionedSnackbar from "../../components/SnackBar/ToastMessage";
const HelpSupport = () => {
  const [help, setHelp] = useState({
    name: "",
    phone: "",
    email: "",
    subject: "",
    message: "",
    image: "",
  });
  const [loading, setLoading] = useState(false)

  // const handleSend = async () => {
  //   if (!help.name) return toast.error("please enter your full Name");
  //   if (!help.phone) return toast.error("please enter your Contact Number");
  //   if (isNaN(help.phone))
  //     return toast.error("please enter valid Contact Number");
  //   if (!help.email) return toast.error("please enter your email");
  //   if (!help.subject) return toast.error("please Add a subject");
  //   if (!help.image) return toast.error("choose an image");
  //   if (!help.message) return toast.error("please enter your query");
  //   try {
  //     setLoading(true)
  //     const header = {
  //       "content-Type": "application/json",
  //     };
  //     const body = {
  //       name: help.name,
  //       phone: help.phone,
  //       email: help.email,
  //       subject: help.subject,
  //       message: help.message,
  //       image: help.image,
  //     };
  //     const method = "post";
  //     const subUri = "/user/support";
  //     console.log(body,'body========')
  //     const res = await callApi(header, body, method, subUri);
  //     if (res.status == 200 || res.status == 201) {
  //       console.log(res, "response");
  //       toast.success(res?.data?.message);
  //     } else {
  //       console.log("internal problem");
  //     }
  //   } catch (error) {
  //     const errorMessage = error.response.data.error
  //       ? error?.response?.data?.error
  //       : error?.response?.data?.message;
  //     toast.error(errorMessage);
  //   }finally{
  //     setHelp({
  //       name: "",
  //       phone: "",
  //       email: "",
  //       subject: "",
  //       message: "",
  //       image: "",
  //     });
  //     setLoading(false)
  //   }
  // };

  //   const [snackBar,setSnackBar] = useState(true)
  // const handleClose=()=>{
  //   setSnackBar(false)

  // }

  const handleSend = async () => {
    if (!help.name) return toast.error("please enter your full Name");
    if (!help.phone) return toast.error("please enter your Contact Number");
    if (isNaN(help.phone)) return toast.error("please enter valid Contact Number");
    if (!help.email) return toast.error("please enter your email");
    if (!help.subject) return toast.error("please Add a subject");
    if (!help.image) return toast.error("choose an image");
    if (!help.message) return toast.error("please enter your query");

    try {
      setLoading(true);

      const formData = new FormData();
      formData.append("name", help.name);
      formData.append("phone", help.phone);
      formData.append("email", help.email);
      formData.append("subject", help.subject);
      formData.append("message", help.message);
      formData.append("image", help.image); // Append the image file

      const config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };

      const method = "post";
      const subUri = "/user/support";
      // console.log(formData,'============================')
      const res = await callApi(config.headers, formData, method, subUri);

      if (res.status == 200 || res.status == 201) {
        // console.log(res, "response");
        toast.success(res?.data?.message);
      } else {
        console.log("internal problem");
      }
    } catch (error) {
      const errorMessage = error.response.data.error
        ? error?.response?.data?.error
        : error?.response?.data?.message;
      toast.error(errorMessage);
    } finally {
      setHelp({
        name: "",
        phone: "",
        email: "",
        subject: "",
        message: "",
        image: "",
      });
      setLoading(false);
    }
  };


  return (
    <>
      <Box className="buyMainContainer">
        <Box className="DASHBOARDheading">
          <Box className="titledashboard">
            <h3>Help Support</h3>
          </Box>
          <Box className="">
            <ul className="dashboard_ulItem">
              <li style={{ color: "var(--white)", fontSize: '12px' }}>Palm Smart Chain</li>
              <li>/</li>
              <li>Help Support</li>
            </ul>
          </Box>
        </Box>
        <Box
          bgcolor={"var(--darkSoft)"}
          sx={{ paddingY: "15px", borderRadius: "10px" }}
        >
          <Container maxWidth="xxl">
            <Grid container>
              <Grid item md="12" sm="12" xs="12">
                <Box
                  sx={{
                    display: { md: "flex", sm: "block", xs: "block" },
                    gap: "10px",
                  }}
                >
                  <Box sx={{ width: { md: "50%" } }} marginBottom={"1.5rem"}>
                    <Typography
                      marginBottom={"10px"}
                      variant="body2"
                      color={"var(--white)"}
                    >
                      Full Name
                    </Typography>

                    <input
                      className="input"
                      placeholder="Enter full name"
                      name="name"
                      value={help.name}
                      onChange={(e) =>
                        setHelp({
                          ...help,
                          name: e.target.value,
                        })
                      }
                    />
                  </Box>
                  <Box sx={{ width: { md: "50%" } }} marginBottom={"1.5rem"}>
                    <Typography
                      marginBottom={"10px"}
                      variant="body2"
                      color={"var(--white)"}
                    >
                      Phone Number
                    </Typography>

                    <input
                      className="input"
                      type="text"
                      placeholder="Enter phone number"
                      name="phone"
                      value={help.phone}
                      onChange={(e) =>
                        setHelp({
                          ...help,
                          phone: e.target.value,
                        })
                      }
                    />
                  </Box>
                </Box>
                <Box
                  marginBottom={"1.5rem"}
                  sx={{
                    display: { md: "flex", sm: "block", xs: "block" },
                    gap: "10px",
                  }}
                >
                  <Box sx={{ width: { md: "50%" } }} marginBottom={"1.5rem"}>
                    <Typography
                      marginBottom={"10px"}
                      variant="body2"
                      color={"var(--white)"}
                    >
                      Email
                    </Typography>

                    <input
                      className="input"
                      placeholder="Enter email"
                      name="email"
                      value={help.email}
                      onChange={(e) =>
                        setHelp({
                          ...help,
                          email: e.target.value,
                        })
                      }
                    />
                  </Box>
                  <Box sx={{ width: { md: "50%" } }} marginBottom={"1.5rem"}>
                    <Typography
                      marginBottom={"10px"}
                      variant="body2"
                      color={"var(--white)"}
                    >
                      Subject
                    </Typography>

                    <input
                      className="input"
                      type="text"
                      placeholder="Add a subject"
                      name="subject"
                      value={help.subject}
                      onChange={(e) =>
                        setHelp({
                          ...help,
                          subject: e.target.value,
                        })
                      }
                    />
                  </Box>
                </Box>
                <Box marginBottom={"1.5rem"}>
                  <Typography
                    marginBottom={"10px"}
                    variant="body2"
                    color={"var(--white)"}
                  >
                    Attachment
                  </Typography>

                  <input
                    className="inputfile"
                    style={{backgroundColor:'var(--bs-light)', width:'100%', padding:'12px'}}
                    type="file"
                    placeholder="Upload File"
                    name="image"
                    value={help.image}
                    onChange={(e) =>
                      setHelp({
                        ...help,
                        image: e.target.value,
                      })
                    }
                  />
                </Box>
                <Box marginBottom={"1.5rem"}>
                  <Typography
                    marginBottom={"10px"}
                    variant="body2"
                    color={"var(--white)"}
                  >
                    Message :
                  </Typography>

                  <textarea
                    rows={4}
                    className="TextArea"
                    placeholder="Enter your message"
                    name="message"
                    value={help.message}
                    onChange={(e) =>
                      setHelp({
                        ...help,
                        message: e.target.value,
                      })
                    }
                  ></textarea>
                </Box>
                {/* <PositionedSnackbar 
                open={snackBar}
                handleClose={handleClose}
                message='help and support'
                severity="success"
                position={{ vertical: 'top', horizontal: 'right' }}
                autoHideDuration={2000}

                /> */}
                <Box>
                  <button className="buttonSendHelp" onClick={handleSend}>
                    <TelegramIcon />
                    {loading ? <CircularProgress size={20} color="inherit" /> : 'Send'}
                  </button>
                </Box>
              </Grid>
            </Grid>
          </Container>
        </Box>
      </Box>
      <ToastContainer />
    </>
  );
};

export default HelpSupport;
