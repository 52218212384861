import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { callApi } from "../../api";
import CustomLoader from "../../CustomLoader/CustomLoader";

const LoginHistory = () => {
  const token = useSelector((token) => token.auth.authToken);
  const [loading, setLoading] = useState(false);
  const [loginHistory, setLoginHistory] = useState([]);

  const handleGetItem = async () => {
    setLoading(true);
    try {
      const header = {
        "content-Type": "application/json",
        "x-access-token": token,
      };
      const method = "get";
      const subUri = `/user/login-history`;

      const response = await callApi(header, {}, method, subUri);
      if (response.status == 200 || response.status == 201) {
        setLoginHistory(response?.data);
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        "unknown message";
      console.log(errorMessage, "error message");
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    if (token) {
      handleGetItem();
    }
  }, []);
  let message;
  if (loading) {
    message = "Loading...";
  } else if (loginHistory?.length === 0) {
    message = "No login history found.";
  } else {
    message = "";
  }
  const convertTimeStampToDate = (timeStamp) => {
    const timeStampInMs = parseInt(timeStamp) * 1000;
    const date = new Date(timeStampInMs);
    return date.toLocaleDateString();
  };
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(8);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = loginHistory?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const totalPages = Math.ceil(loginHistory?.length / itemsPerPage);
  const pageNumbers = [];
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }



  return (
    <div>
      <Box marginBottom={"2rem"}>
        <Typography
          variant="h5"
          color={"var(--white)"}
          gutterBottom
          fontWeight={"500"}
        >
          Login History
        </Typography>
      </Box>
      <Box>
        {loginHistory?.length === 0 ? (
          <>
            <Typography>{message}</Typography>
          </>
        ) : (
          <>
            <Box
              sx={{
                display: { lg: "flex", md: "flex", sm: "flex" },
                alignItems: "center",
                justifyContent: "space-between",
                marginY: "1rem",
              }}
            >
              {/* <Box>
                <input
                  type="search"
                  // onChange={(e) => setSearchQuery(e.target.value)}
                  className="input"
                  placeholder="Search..."
                  style={{ borderRadius: "5px", background: "var(--bs-light)" }}
                />
              </Box> */}
              {/* <Box
                display="flex"
                alignItems="center"
                gap="5px"
                sx={{ marginTop: { md: "0", sm: "1rem", xs: "1rem" } }}
              >
                <button className="dataBtn">Copy</button>
                <button className="dataBtn">Excel</button>
                <button className="dataBtn">PDF</button>
              </Box> */}
            </Box>
            <Box className="overflow-x">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ minWidth: "70px" }}>IP ADDRESS</th>
                    <th style={{ minWidth: "150px" }}>ADDRESS</th>
                    {/* <th>LOCATION</th> */}
                    <th>PLATFORM</th>
                    <th>BROWSER</th>
                    {/* <th>DEVICE NAME</th> */}
                    <th>SERVICE PROVIDER</th>
                    <th>DATE & TIME</th>
                  </tr>
                </thead>
                <tbody>
                {loginHistory?.map((item,index) =>(
                    <tr>
                    <th scope="row">
                      <Typography>{item?.ipAddress}</Typography>
                    </th>
                    <td>
                      <Typography>{`${item?.city} ${item?.country_name}`}</Typography>
                    </td>
                    {/* <td>
                      <Typography>Operating System</Typography>
                    </td> */}
                    <td>
                      <Typography>{item?.platform}</Typography>
                    </td>
                    <td>
                      <Typography>{item?.browser}</Typography>
                    </td>
                    {/* <td>
                      <Typography>Location</Typography>
                    </td> */}
                    <td>
                      <Typography>{item?.org}</Typography>
                    </td>
                    <td>
                      <Typography>{convertTimeStampToDate(item?.login_at)}</Typography>
                    </td>
                  </tr>
                ))}
                  
                </tbody>
              </table>
            </Box>
            {loginHistory?.length > 0 && (
              <Box>
                <ul className="pagination">
                  {currentPage !== 1 && (
                    <li className="page-item">
                      <button
                        onClick={() => handlePageChange(currentPage - 1)}
                        className="page-link"
                      >
                        Previous
                      </button>
                    </li>
                  )}
                  {pageNumbers
                    .slice(currentPage - 1, currentPage + 1)
                    .map((number) => (
                      <li key={number} className="page-item">
                        <button
                          onClick={() => handlePageChange(number)}
                          className="page-link"
                        >
                          {number}
                        </button>
                      </li>
                    ))}
                  {currentPage !== pageNumbers.length && (
                    <li className="page-item">
                      <button
                        onClick={() => handlePageChange(currentPage + 1)}
                        className="page-link"
                      >
                        Next
                      </button>
                    </li>
                  )}
                </ul>
              </Box>
            )}
          </>
        )}
      </Box>
    </div>
  );
};

export default LoginHistory;
