import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, IconButton, Modal, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import "./profile.css";
import userIMage from "../../components/assets/PalmLogo.png";
import EditIcon from "@mui/icons-material/Edit";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { callApi } from "../../api";
import countryCode from "../../utils/Country.json";
import { userDetailsEditable } from "../../redux/slices/authSlice";
import CircularProgress from "@mui/material/CircularProgress";
import { imagePath } from "../../components/navbar/Navbar";

const Profile = () => {
    const userEditableDetails = useSelector((state) => state.auth.UserDetailsEditable);
    const token = useSelector((token) => token.auth.authToken);


    const dispatch = useDispatch();

    const noneEditableDetails = useSelector((state) => state.auth.userDetails);
    const [open, setOpen] = useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const [loading, setLoading] = useState(false);

    const [userImage, setUserImage] = useState(userEditableDetails?.image || "");
    const [formData, setFormData] = useState({
        name: userEditableDetails?.fullName || "",
        address: userEditableDetails?.address || "",
        phoneno: userEditableDetails?.mobileNumber || "",
        gender: userEditableDetails?.gender || "male",
    });

    const handleImageChange = (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            setUserImage(file); // Store the File object directly
        }
    };

    const [sortedCountries, setSortedCountries] = useState([]);
    const [selectedCountryCode, setSelectedCountryCode] = useState("+973");

    useEffect(() => {
        const getCountryCode = (countryCodeList, userCountryCode) => {
            const country = countryCodeList.find((country) => country.code === userCountryCode);
            return country ? country.code : "+973";
        };

        // Determine the default country code
        const validCountryCode = getCountryCode(countryCode.countries, userEditableDetails.Country_Code);

        setSelectedCountryCode(validCountryCode);

        const sorted = [...countryCode.countries].sort((a, b) => {
            return a.code.localeCompare(b.code);
        });

        const selectedIndex = sorted.findIndex((country) => country.code === validCountryCode);
        if (selectedIndex > 0) {
            const [selectedCountry] = sorted.splice(selectedIndex, 1);
            sorted.unshift(selectedCountry);
        }

        setSortedCountries(sorted);
    }, [countryCode.countries, userEditableDetails.Country_Code]);

    const style = {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "95%", // Adjusted for responsiveness
        maxWidth: 500, // Maximum width for larger screens
        bgcolor: "var(--body)",
        border: "2px solid var(--bs-light)",
        boxShadow: 24,
        p: 2,
        borderRadius: 5,
    };


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleEditProfile = async () => {
        setLoading(true); // Start loading

        try {
            const formDataToSend = new FormData();
            formDataToSend.append("name", formData.name);
            formDataToSend.append("address", formData.address);
            formDataToSend.append("phoneno", formData.phoneno);
            formDataToSend.append("gender", formData.gender);
            formDataToSend.append("photo", userImage);
            formDataToSend.append("country_code", selectedCountryCode);

            const headers = {
                "Content-Type": "multipart/form-data",
                "x-access-token": token,
            };

            const response = await callApi(headers, formDataToSend, "post", "/user/profile_update");

            if (response.status === 200 || response.status === 201) {
                const data = response?.data?.result;
                toast.success(response?.data?.message);

                dispatch(
                    userDetailsEditable({
                        image: data?.selfie,
                        fullName: data?.name,
                        gender: data?.gender,
                        mobileNumber: data?.mobileno,
                        address: data?.address,
                        email: userEditableDetails?.email,
                        referralCode: userEditableDetails?.referralCode,
                        Country_Code: data?.country_code,
                    })
                );
            } else {
                throw new Error(response?.data?.message || "Failed to update profile.");
            }
        } catch (error) {
            const errorMessage =
                error?.response?.data?.error ||
                error?.response?.data?.message ||
                error?.message ||
                "An unknown error occurred.";

            toast.error(errorMessage);
            console.error("Error updating profile:", errorMessage);
        } finally {
            setLoading(false); // Stop loading
            setOpen(false); // Close the modal
        }
    };

    return (
        <div>
            <Container maxWidth="xl">
                <Box marginBottom={"2rem"}>
                    <Typography color={"var(--white)"} fontWeight={"500"} variant="h5">
                        My Profile
                    </Typography>
                </Box>
                <Grid container spacing={2}>
                    <Grid item lg="6" md="6" sm="12" xs="12">
                        <Box className="">
                            <Box className="mainContainerUser">
                                <Box>
                                    <Box display="flex" alignItems="center" justifyContent={"space-between"}>
                                        <Box className="UserContainer" marginBottom={"1rem"} position="relative">
                                            <img src={userEditableDetails?.image ? `${imagePath}${userEditableDetails?.image}` : userIMage} className="proUserImage" alt="user image" />

                                            <label htmlFor="upload-button"></label>
                                        </Box>
                                        <Box>
                                            <IconButton onClick={handleOpen} sx={{ background: "var(--primaryColor) !important" }}>
                                                <EditIcon sx={{ color: "white" }} />
                                            </IconButton>
                                        </Box>
                                    </Box>
                                    <Box overflow="auto">
                                        <Typography variant="h5" gutterBottom>
                                            Name: {userEditableDetails?.fullName || ""}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Address: {noneEditableDetails?.selectedAccount || ""}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Phone No : {`${userEditableDetails?.Country_Code || ""}-${userEditableDetails?.mobileNumber || ""}`}
                                        </Typography>
                                        <Typography variant="body1" gutterBottom>
                                            Referral Code: {String(noneEditableDetails?.refferalCode).toLocaleUpperCase() || ""}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box>
                <Modal open={open} onClose={handleClose} aria-labelledby="modal-modal-title" aria-describedby="modal-modal-description">
                    <Box sx={style}>
                        <Box sx={{ position: "relative" }}>
                            <Box>
                                <Typography variant="h5" color={"var(--primaryColor)"}>
                                    Edit Profile
                                </Typography>
                            </Box>
                            <Box sx={{ position: "absolute", right: "0px", top: "0px" }}>
                                <IconButton onClick={handleClose} sx={{ background: "var(--primaryColor) !important" }}>
                                    <CloseIcon sx={{ color: "white" }} />
                                </IconButton>
                            </Box>
                        </Box>
                        <Box width={"100%"} marginTop={"2rem"}>
                            <Box className="UserContainer" marginBottom={"1rem"} marginX="auto" position="relative">
                                <img src={userImage} className="proUserImage" alt="user image" />
                                <input accept="image/*" type="file" style={{ display: "none" }} id="upload-button" onChange={handleImageChange} />
                                <label htmlFor="upload-button">
                                    <IconButton sx={{ position: "absolute", right: "-10px", bottom: "0", background: "var(--bs-topnav-bg) !important" }} component="span">
                                        <CameraAltIcon sx={{ color: "var(--primaryColor)" }} />
                                    </IconButton>
                                </label>
                            </Box>
                            {/*<Box marginBottom="7px">
                            
                                <Typography variant="body1" color={"var(--white)"} gutterBottom>
                                    Image
                                </Typography>
                                <input accept="image/*" type="file" placeholder="upload Image" className="inputBox" onChange={handleImageChange} />
                            </Box>*/}
                            <Box marginBottom="7px">
                                <Typography variant="body1" color={"var(--white)"} gutterBottom>
                                    User Name
                                </Typography>
                                <input type="text" placeholder="Name" name="name" className="inputBox" value={formData.name} onChange={handleChange} />
                            </Box>
                            <Box marginBottom="7px">
                                <Typography variant="body1" color={"var(--white)"} gutterBottom>
                                    Address
                                </Typography>
                                <input type="text" placeholder="Address" name="address" className="inputBox" value={formData.address} onChange={handleChange} />
                            </Box>

                            <Box display="flex" alignItems="center" gap="10px">
                                <select value={selectedCountryCode} className="selectContRow" style={{ backgroundColor: "var(--bs-light)", border: 'none', color: 'var(--white)', }} aria-label="Select country code" onChange={(e) => setSelectedCountryCode(e.target.value)}>
                                    {sortedCountries.map((country) => (
                                        <option key={country.code} value={country.code}>
                                            {country.code} {country.name} {/* Ensure name exists */}
                                        </option>
                                    ))}
                                </select>
                                <input
                                    placeholder="Enter phone number"
                                    type="text"
                                    maxLength="13"
                                    style={{ borderRadius: '5px' }}
                                    className="input"
                                    name="phoneno"
                                    value={formData.phoneno}
                                    onChange={handleChange}
                                    required
                                />
                            </Box>

                            <Box>
                                <button className="buyButtons" style={{width:'100%'}} onClick={handleEditProfile}>
                                    {loading ? <CircularProgress /> : "Save"}
                                </button>
                            </Box>
                        </Box>
                    </Box>
                </Modal>
            </Box>
            <ToastContainer />
        </div>
    );
};

export default Profile;
