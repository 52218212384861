import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { imagePath } from "../../components/navbar/Navbar";
import moment from "moment";
import './Refferal.css'
import SocialShareModal from "./SocialShareModal";
import defaultImage from "../../components/assets/PalmLogo.png";


function signUpPageLink(substring) {
    const baseString = window.location.href;

    // Make sure the substring does not have extra spaces
    substring = substring.trim();

    const index = baseString.lastIndexOf(substring);

    if (index !== -1 && index + substring.length === baseString.length) {
        return baseString.substring(0, index);
    }

    return baseString;
}


const RefferalHistory = () => {

    const homeData = useSelector((state) => state.auth.importedAdress);
    const UserDetailsEditable = useSelector((state) => state.auth.UserDetailsEditable);
    const partners = homeData?.partners || [];
    const referralCode = UserDetailsEditable?.refferalCode

    const formattedDate = (timestamp) => moment(timestamp).format('YYYY-MM-DD HH:mm:ss');

    // const shareLink = signUpPageLink("refferal-history") + 'sign-up?referralCode=' + referralCode;

    // social share modal

    const [isShareModalOpen, setIsShareModalOpen] = useState(false);

    const openShareModal = () => {
        setIsShareModalOpen(true);
    };

    const closeShareModal = () => {
        setIsShareModalOpen(false);
    };

    const shareUrl = signUpPageLink("refferal-history") + 'sign-up?referralCode=' + referralCode;
    const shareTitle = "Palm Smart Chain";

    return (
        <div>
            <Container maxWidth="xl">
                <Box marginBottom={"2rem"}>
                    <Typography color={"var(--white)"} fontWeight={"500"} variant="h5">
                        Refferal History
                    </Typography>
                    <Box marginTop='3rem' className="SettingContainer">
                        <Box sx={{ display: { lg: 'flex', md: 'flex' } }} alignItems="center" gap="10px" justifyContent="space-between">
                            <Box>
                                <Typography color="var(--primaryColor)" variant="h5">Invite your friends</Typography>
                                <Typography color="var(--white)" variant="body1">Sharing is caring! invite your friend to Palm Wallet.</Typography>
                            </Box>
                            <Box display="flex" alignItems="center" gap="10px" justifyContent="space-between">
                                {/* <Box display="flex" alignItems="center" gap="10px">
                                    <Typography>{referralCode}</Typography>
                                    <button className="CopyBtn" onClick={handleCopy}>
                                        <ContentCopyIcon />
                                        <span>Copy</span>
                                    </button>
                                    {copySuccess && <Typography variant="caption" color="green">{copySuccess}</Typography>}
                                </Box> */}
                                <Box>
                                    <Typography variant="h5">{referralCode}</Typography>
                                </Box>
                                <Box sx={{}}>
                                    {/* <RWebShare className='RWEBSSHARE'
                                        data={{
                                            text: "Palm Smart Chain",
                                            url: shareLink,
                                            title: "Palm Smart Chain",
                                            textAlign:'center',
                                            
                                        }}
                                        onClick={() => console.log("shared successfully!")} 

                                    >
                                        <button class="buyButtons">Share 🔗</button>
                                        
                                    </RWebShare>  */}

                                    <button className="buyButtons" onClick={openShareModal}>
                                        Share 🔗
                                    </button>
                                    <SocialShareModal
                                        isOpen={isShareModalOpen}
                                        onClose={closeShareModal}
                                        url={shareUrl}
                                        title={shareTitle}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Grid container spacing={2}>
                    <Grid item lg="12" md="12" sm="12" xs="12">
                        <Box className="SettingContainer">
                            <Box className="overflow-x">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th style={{ minWidth: "100px" }}>Sr.No.</th>
                                            <th style={{ minWidth: "130px" }}>User Name</th>
                                            <th>Image</th>
                                            <th>Amount (PALM)</th>
                                            <th style={{ textAlign: 'end' }}>Date</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {partners.map((partner, index) => (
                                            <tr key={index}>
                                                <th scope="row">
                                                    <Typography>{index + 1}</Typography>
                                                </th>
                                                <th>
                                                    <Typography>{partner.name}</Typography>
                                                </th>
                                                <td>
                                                    <Box>
                                                        <img src={partner.selfie ? imagePath + partner.selfie : defaultImage} className="reffralImages" alt="" />
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Typography>{partner?.reward_amt ? partner?.reward_amt : 0} PALM</Typography>
                                                </td>
                                                <td style={{ textAlign: 'end' }}>
                                                    <Typography>{formattedDate(partner.time * 1000)}</Typography>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>

                                </table>
                            </Box>
                            <Box>
                                <ul className="pagination">
                                    <li className="page-item">
                                        <button className="page-link">Previous</button>
                                    </li>
                                    <li className="page-item">
                                        <button className="page-link">1</button>
                                    </li>
                                    <li className="page-item">
                                        <button className="page-link">Next</button>
                                    </li>
                                </ul>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </div>
    );
};

export default RefferalHistory;
