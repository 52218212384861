import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Box, IconButton, Typography } from "@mui/material";
import { callApi } from "../../api";
import jsPDF from "jspdf";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import { ContentCopy as CopyIcon } from "@mui/icons-material";
import moment from "moment";

const ViewDetailsDataTable = () => {
  const location = useLocation();
  const item = location.state;
  const token = useSelector((state) => state.auth.authToken);
  const [claimData, setClaimData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(12); // Default 12 rows per page
  const totalPages = Math.ceil(claimData.length / itemsPerPage);

  useEffect(() => {
    if (item !== undefined) {
      handleGetItem();
    }
  }, [item]);

  const handleGetItem = async () => {
    setLoading(true);
    try {
      const header = {
        "content-Type": "application/json",
        "x-access-token": token,
      };
      const method = "get";
      const subUri = `/staking/claimed_rewards_history/${item}`;
      const response = await callApi(header, {}, method, subUri);

      if (response.status === 200 || response.status === 201) {
        setClaimData(response.data?.result || []);
      }
    } catch (error) {
      const errorMessage =
        error?.response?.data?.error ||
        error?.response?.data?.message ||
        error?.message ||
        "Unknown error occurred";
      console.log(errorMessage);
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const convertTimeStampToDate = (timestamp) =>
    moment(timestamp * 1000).format("YYYY-MM-DD HH:mm:ss");

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = claimData.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getPaginationButtons = () => {
    const pageNumbers = [];
    if (totalPages <= 4) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
      }
    } else {
      if (currentPage <= 3) {
        pageNumbers.push(1, 2, 3, 4, "...", totalPages);
      } else if (currentPage > 3 && currentPage < totalPages - 2) {
        pageNumbers.push(1, "...", currentPage - 1, currentPage, currentPage + 1, "...", totalPages);
      } else {
        pageNumbers.push(1, "...", totalPages - 3, totalPages - 2, totalPages - 1, totalPages);
      }
    }
    return pageNumbers;
  };

  const copyToClipboard = async () => {
    try {
      const tableData = claimData.map((item) => {
        const Amount = parseFloat(item.reward_amount).toFixed(4);
        return `${item.transactionHash}\t${Amount}\t${convertTimeStampToDate(item.time)}`;
      }).join("\n");

      await navigator.clipboard.writeText(tableData);
      toast.success("Table data copied to clipboard!");
    } catch (error) {
      console.error("Failed to copy table data: ", error);
    }
  };

  const handleMakePdf = () => {
    const doc = new jsPDF();
    const headers = ["Transaction Hash", "Amount In PALM", "Date"];
    const tableData = claimData.map((item) => [
      item.transactionHash,
      parseFloat(item.reward_amount).toFixed(4),
      convertTimeStampToDate(item.time)
    ]);

    doc.autoTable({ head: [headers], body: tableData });
    doc.save("transaction_data.pdf");
  };

  const exportToExcel = () => {
    const excelData = claimData.map((item) => [
      item.transactionHash,
      parseFloat(item.reward_amount).toFixed(4),
      convertTimeStampToDate(item.time)
    ]);

    const worksheet = XLSX.utils.aoa_to_sheet([["Transaction Hash", "Amount In PALM", "Date"], ...excelData]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Transactions");

    const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });
    const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
    saveAs(blob, "transaction_data.xlsx");
  };

  const shortenHash = (hash) => (hash.length <= 10 ? hash : `${hash.slice(-10)}`);

  const copyHash = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        toast.success("Copied to clipboard!");
      })
      .catch((err) => {
        console.error("Failed to copy text: ", err);
        toast.error("Failed to copy text.");
      });
  };

  return (
    <div>
      <Box marginBottom={"2rem"}>
        <Typography variant="h5" color={"var(--white)"} gutterBottom fontWeight={"500"}>
          Stake Reward History
        </Typography>
      </Box>
      {loading ? (
        <Typography>Loading...</Typography>
      ) : claimData.length === 0 ? (
        <Typography>No claimed rewards history found.</Typography>
      ) : (
        <>
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", marginY: "1rem" }}>
              <Box display="flex" alignItems="center" gap="5px">
                <button className="dataBtn" onClick={copyToClipboard}>Copy</button>
                <button className="dataBtn" onClick={exportToExcel}>Excel</button>
                <button className="dataBtn" onClick={handleMakePdf}>PDF</button>
              </Box>
            </Box>
            <Box className="overflow-x">
              <table className="table">
                <thead>
                  <tr>
                    <th>ID No</th>
                    <th>Amount In PALM</th>
                    <th>Date</th>
                    <th>Transaction Hash</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((item, index) => (
                    <tr key={item.id}>
                      <td>{index + 1}</td>
                      <td>{parseFloat(item.reward_amount).toFixed(4)}</td>
                      <td>{convertTimeStampToDate(item.time)}</td>
                      <td>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <Typography sx={{ color: "var(--blue)" }}>{shortenHash(item.transactionHash)}</Typography>
                          <IconButton onClick={() => copyHash(item.transactionHash)}>
                            <CopyIcon sx={{ fontSize: "20px" }} />
                          </IconButton>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <ToastContainer />
              {claimData.length > 0 && (
                <Box>
                  <ul className="pagination">
                    {currentPage > 1 && (
                      <li className="page-item">
                        <button onClick={() => handlePageChange(currentPage - 1)} className="page-link">Previous</button>
                      </li>
                    )}
                    {getPaginationButtons().map((number, index) => (
                      <li key={index} className={`page-item ${number === currentPage ? "active" : ""}`}>
                        {number === "..." ? (
                          <span className="page-link">...</span>
                        ) : (
                          <button onClick={() => handlePageChange(number)} className="page-link">{number}</button>
                        )}
                      </li>
                    ))}
                    {currentPage < totalPages && (
                      <li className="page-item">
                        <button onClick={() => handlePageChange(currentPage + 1)} className="page-link">Next</button>
                      </li>
                    )}
                  </ul>
                </Box>
              )}
            </Box>
          </Box>
        </>
      )}
    </div>
  );
};

export default ViewDetailsDataTable;
