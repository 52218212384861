import React, { useEffect, useState } from "react";
import Switch from "@mui/material/Switch"; 
import { Box } from "@mui/material";
import LightModeIcon from "@mui/icons-material/LightMode";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import { useDispatch, useSelector } from "react-redux";
import { setIsDarkModeOn } from "../../redux/slices/authSlice";

const ThemeSwitchs = () => {
  const isDarkMode = useSelector(state => state.auth.isDarkMode)
  const dispatch = useDispatch()
 

  const handleSwitchChange = (e) => {
    // setSwitching(!switching); // Toggle between 0 and 1 
    dispatch(setIsDarkModeOn(!isDarkMode))
  };


  const setLightMode = () => {
    document.querySelector("body").setAttribute("data-theme", "light");
  }

  const setDarkMode = () => {
    document.querySelector("body").setAttribute("data-theme", "dark");
  }
  // const toggleTheme = (e) => {
  //   if (e.target.checked) setDarkMode();
  //   else setLightMode
  // }

  useEffect(() => {
  if(isDarkMode){
    setLightMode()
  }else{
    setDarkMode()
  }
  }, [isDarkMode])
  


  return (
    <Box display="flex" alignItems="center">
      <Switch
        checked={isDarkMode}
        // onChange={handleSwitchChange} 
        onChange={handleSwitchChange} 
        // color={switching === 0 ? "warning" : "primary"}
        sx={{
            '& .MuiSwitch-track': {
                backgroundColor: isDarkMode ? '#556EE6' : '#252b3b',
                border:'1px solid',
                borderColor:isDarkMode ? '#556EE6' : '#4e5c7c',
                borderRadius:'50px',  
                boxShadow:'none',
            },
            '& .MuiSwitch-thumb': {
                backgroundColor: isDarkMode ? '#556EE6' : '#5f6471', 
                boxShadow:'none',
            },
        }}
      />
      {isDarkMode ===false ? (
        <Box>
          <LightModeIcon
            className="bx-spin"
            sx={{ color: "var(--secondaryColor)" }}
          />
        </Box>
      ) : (
        <Box>
          <DarkModeIcon sx={{ color: "var(--white)" }} />
        </Box>
      )}
    </Box>
  );
};

export default ThemeSwitchs;
