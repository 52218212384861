import React, { useEffect, useState } from "react";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { exportToCSV, exportToExcel, exportToPDF, copyToClipboard } from "./exportUtils";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

// Utility function to convert timestamp to date
// const convertTimeStampToDate = (timeStamp) => {
//     const timeStampInMs = parseInt(timeStamp) * 1000;
//     return new Date(timeStampInMs).toLocaleDateString();
// };

const convertTimeStampToDate = (timestamp) => moment(timestamp * 1000).format('YYYY-MM-DD HH:mm:ss');

// Utility function to convert currency value
const convertCurrency = (value, tokenDecimal) => {
    const numericValue = Number(value);
    if (isNaN(numericValue) || isNaN(tokenDecimal) || tokenDecimal < 0) {
        throw new Error('Invalid value or tokenDecimal');
    }
    const formattedValue = (numericValue / Math.pow(10, tokenDecimal));
    return parseFloat(formattedValue.toFixed(4));
};

const TransactionsDashboard = (props) => {
    const tableData = props.trxTable;
    const user = useSelector((state) => state.auth.userDetails);
    const selectedAccount = user?.selectedAccount;
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(12);
    const [searchQuery, setSearchQuery] = useState("");

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Determine transaction type and address
    const amountTransactionType = (from, to) => {
        if (String(from).toLowerCase() === String(to).toLowerCase()) {
            return { type: "Self", address: selectedAccount };
        } else
            if (String(from).toLowerCase() === selectedAccount.toLowerCase()) {
                return { type: "Send", address: to };
            } else if (String(to).toLowerCase() === selectedAccount.toLowerCase()) {
                return { type: "Receive", address: from };
            }
        return { type: "Unknown", address: "" };
    };

    // Apply search query to table data
    const filteredData = tableData?.filter((item) => {
        return (
            String(item?.to).toLowerCase().includes(searchQuery.toLowerCase()) ||
            convertTimeStampToDate(item?.timeStamp).includes(searchQuery.toLowerCase()) ||
            convertCurrency(item?.value, item?.tokenDecimal).includes(searchQuery.toLowerCase())
        );
    });

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const pageNumbers = [];

    for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(i);
    }

    // Generate pagination buttons
    const getPaginationButtons = () => {
        const maxButtons = 4;
        let start = Math.max(currentPage - Math.floor(maxButtons / 2), 1);
        let end = Math.min(start + maxButtons - 1, totalPages);

        if (end - start + 1 < maxButtons) {
            start = Math.max(end - maxButtons + 1, 1);
        }

        const buttons = [];
        if (start > 1) {
            buttons.push(1);
            if (start > 2) buttons.push("...");
        }
        for (let i = start; i <= end; i++) {
            buttons.push(i);
        }
        if (end < totalPages) {
            if (end < totalPages - 1) buttons.push("...");
            buttons.push(totalPages);
        }
        return buttons;
    };

    // Handle export actions
    const handleExport = (format) => {
        switch (format) {
            case "copy":
                copyToClipboard(filteredData, selectedAccount);
                break;
            case "excel":
                exportToExcel(filteredData, selectedAccount);
                break;
            case "pdf":
                exportToPDF(filteredData, selectedAccount);
                break;
            default:
                break;
        }
    };

    return (
        <Box>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    my: 1,
                    gap: 2,
                }}
            >
                <Box>
                    {/* <input
                        type="search"
                        onChange={(e) => setSearchQuery(e.target.value)}
                        className="input"
                        placeholder="Search..."
                        style={{ borderRadius: "5px", background: "var(--body)" }}
                    /> */}
                </Box>

                <Box display="flex" alignItems="center" gap={1}>
                    <button className="dataBtn" onClick={() => handleExport("copy")}>
                        Copy
                    </button>
                    <button className="dataBtn" onClick={() => handleExport("excel")}>
                        Excel
                    </button>
                    <button className="dataBtn" onClick={() => handleExport("pdf")}>
                        PDF
                    </button>
                </Box>
            </Box>

            <Box className="overflow-x">
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col" style={{ minWidth: "70px" }}>ID No</th>
                            <th scope="col" style={{ minWidth: "160px" }}>Amount</th>
                            <th scope="col">Type</th>
                            <th scope="col">From/To Address</th>
                            <th scope="col" style={{ minWidth: "170px" }}>Date</th>
                            <th scope="col">Details</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => {
                            const { type, address } = amountTransactionType(item?.from, item?.to);
                            return (
                                <tr key={index}>
                                    <th scope="row"><Box fontWeight="400" fontSize="14px">{indexOfFirstItem + index + 1}</Box></th>
                                    <td><Box fontWeight="400" fontSize="14px">{convertCurrency(item?.value, item?.tokenDecimal)} {item?.tokenSymbol}</Box></td>
                                    <td style={{ color: type === "Send" ? "red" : type === "Receive" ? "#0adf0a" : "#ffc107" }}>
                                    <Box fontWeight="400" fontSize="14px"> {type}</Box>
                                    </td>
                                    <td><Box fontWeight="400" fontSize="14px">{address}</Box></td>
                                    <td><Box fontWeight="400" fontSize="14px">{convertTimeStampToDate(item?.timeStamp)}</Box></td>
                                    <td>
                                        <Link target="_blank" to={item?.hash} className="LinkStyle">
                                            <button className="Viewbtn">
                                                <RemoveRedEyeIcon sx={{ fontSize: 15 }} /> View
                                            </button>
                                        </Link>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </Box>

            {filteredData.length > 0 && (
                <Box>
                    <ul className="pagination">
                        {currentPage > 1 && (
                            <li className="page-item">
                                <button onClick={() => handlePageChange(currentPage - 1)} className="page-link">
                                    Previous
                                </button>
                            </li>
                        )}
                        {getPaginationButtons().map((number, index) => (
                            <li key={index} style={{padding:'0px'}} className={`page-item ${number === currentPage ? 'active' : ''}`}>
                                {number === "..." ? (
                                    <span className="page-link">...</span>
                                ) : (
                                    <button onClick={() => handlePageChange(number)} className="page-link">
                                        {number}
                                    </button>
                                )}
                            </li>
                        ))}
                        {currentPage < totalPages && (
                            <li className="page-item">
                                <button onClick={() => handlePageChange(currentPage + 1)} className="page-link">
                                    Next
                                </button>
                            </li>
                        )}
                    </ul>
                </Box>
            )}
        </Box>
    );
};

export default TransactionsDashboard;
