import axios from "axios";
import { setImportedWallet, setStakeData, setTransactionsReport } from "../redux/slices/authSlice";
const BASE_URI = process.env.REACT_APP_BASE_URI;

export const callApi = async (header, body, method, subUri, base = BASE_URI, redirect = null) => {
  if (method == "post") {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await axios.post(`${base}${subUri}`, body, {
          headers: header,
          redirect: redirect,
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  } else {
    return new Promise(async (resolve, reject) => {
      try {
        let response = await axios.get(`${base}${subUri}`, {
          headers: header,
        });
        resolve(response);
      } catch (err) {
        reject(err);
      }
    });
  }
};

// Fetch and dispatch home data
export const fetchAndDispatchHomeData = async (dispatch, token) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URI}/user/wallet_homesonu`, {
      headers: { "x-access-token": token },
    });

    if (response.status === 200 || response.status === 201) {
      const data = response.data;
      dispatch(setImportedWallet(data));
    } else {
      console.error('Unexpected response status:', response.status);
    }
  } catch (error) {
    console.error("Error fetching home data:", error);
  }
};

// Fetch and dispatch stake data
export const fetchStakeData = async (dispatch, token) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URI}/staking/stakes`, {
      headers: { "x-access-token": token },
    });

    if (response.status === 200 || response.status === 201) {
      const data = response.data.result;
      dispatch(setStakeData(data));
    } else {
      console.error('Unexpected response status:', response.status);
    }
  } catch (error) {
    console.error('Error fetching stake data:', error);
  }
};

// Fetch and dispatch transactions data
export const fetchTransactionsData = async (dispatch, token) => {
  try {
    const response = await axios.get(`${process.env.REACT_APP_BASE_URI}/transactions`, {
      headers: { "x-access-token": token },
    });

    if (response.status === 200 || response.status === 201) {
      const data = response.data;
      dispatch(setTransactionsReport(data));
    } else {
      console.error('Unexpected response status:', response.status);
    }
  } catch (error) {
    console.error('Error fetching transactions data:', error);
  }
};
