import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStakeData, fetchAndDispatchHomeData, fetchTransactionsData } from '../api/index';

const FetchOnPageSwitch = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const tokenFromRedux = useSelector(state => state.auth.authToken);
    const isAuthorized = useSelector(state => state.auth.isUserAuthenticated);
    const prevPathnameRef = useRef();

    useEffect(() => {
        if (!isAuthorized) return;

        const prevPathname = prevPathnameRef.current;

        if (location.pathname !== prevPathname) {
            prevPathnameRef.current = location.pathname;

            const fetchData = async () => {
                try {
                    switch (location.pathname) {
                        case '/stake':
                            await fetchStakeData(dispatch, tokenFromRedux);
                            break;
                        case '/':
                            await fetchAndDispatchHomeData(dispatch, tokenFromRedux);
                            await fetchTransactionsData(dispatch, tokenFromRedux);
                            break;
                        default:
                            break;
                    }
                } catch (error) {
                    // console.error('Error during API calls:', error);
                }
            };

            fetchData();
        }
    }, [location.pathname, tokenFromRedux, dispatch, isAuthorized]);

    return null;
};

export default FetchOnPageSwitch;
