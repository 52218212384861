import { createSlice } from "@reduxjs/toolkit";

let initialState = {
  toggle: false,
  userId: "",
  userDetails: null,
  UserDetailsEditable: null,
  authToken: null,
  userMessage: null,
  userName: null,
  userAddress: null,
  transaction: null,
  timestamp: null,
  transactionsData: [],
  transactionDetail: [],
  isAuthenticated: false,
  userWallets: [],
  importedAdress: [],
  importeddetails: [],
  isUserAuthenticated: false,
  stakeData: [],
  graphHistory: [],
  totalRewards: 0,
  isDarkMode: false,
  updateWeb: false,
  OneDocoinPrice: 0,
  notifiCount: 0,
  currentButTab: '',
  stackingPlan: [],
  transactionsReport: [],
  selectedSaleToken: null,
  selectedBuyWithToken: null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthToken: (state, action) => {
      state.authToken = action.payload;
    },
    userDetailsEditable: (state, action) => {
      state.UserDetailsEditable = action.payload;
    },
    loginSuccess: (state, action) => {
      state.isUserAuthenticated = true;
    },
    logOutSuccess: (state, action) => {
      return initialState;;
    },
    loginName: (state, action) => {
      state.userName = action.payload;
    },
    loginAddress: (state, action) => {
      state.userAddress = action.payload;
      state.userId = action.payload;
    },
    transactionsData: (state, action) => {
      state.transaction = action.payload;
      // state.userid = action.payload
    },
    transationtimestamp: (state, action) => {
      state.timestamp = action.payload;
    },
    setTransactionsData: (state, action) => {
      state.transactionsData = action.payload;
    },
    setTransactionDetails: (state, action) => {
      state.transactionDetail = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
    setIsAuthenticate: (state, action) => {
      state.isAuthenticated = action.payload;
    },
    setUserWallets: (state, action) => {
      state.userWallets = action.payload;
    },
    setImportedWallet: (state, action) => {
      state.importedAdress = action.payload;
    },
    setUserId: (state, action) => {
      state.userId = action.payload;
    },
    setStakeData: (state, action) => {
      state.stakeData = action.payload;
    },
    setGraphHistory: (state, action) => {
      state.graphHistory = action.payload;
    },
    setTotalrewards: (state, action) => {
      state.totalRewards = action.payload;
    },
    setIsDarkModeOn: (state, action) => {
      state.isDarkMode = action.payload;
    },
    setUpdateApp: (state, action) => {
      state.updateWeb = action.payload
    },
    setOneDocoinPrice: (state, action) => {
      state.OneDocoinPrice = action.payload
    },

    setNotifCount: (state, action) => {
      state.notifiCount = action.payload
    },
    setChangeBuyTab: (state, action) => {
      state.currentButTab = action.payload
    },
    setStackingPlan: (state, action) => {
      state.stackingPlan = action.payload
    },

    setTransactionsReport: (state, action) => {
      state.transactionsReport = action.payload
    },
    setSelectedSaleToken: (state, action) => {
      state.selectedSaleToken = action.payload
    },
    setSelectedBuyWithToken: (state, action) => {
      state.selectedBuyWithToken = action.payload
    },

  },
});

export const {
  setUpdateApp,
  setAuthToken,
  setUserDetails,
  loginSuccess,
  loginName,
  loginAddress,
  transactionsData,
  transationtimestamp,
  setTransactionsData,
  setTransactionDetails,
  setIsAuthenticate,
  setUserWallets,
  setImportedWallet,
  setUserId,
  logOutSuccess,
  setStakeData,
  setGraphHistory,
  setTotalrewards,
  setIsDarkModeOn,
  setOneDocoinPrice,
  setNotifCount,
  setChangeBuyTab,
  setStackingPlan,
  userDetailsEditable,
  setTransactionsReport,
  setSelectedSaleToken,
  setSelectedBuyWithToken
} = authSlice.actions;
export default authSlice.reducer;
