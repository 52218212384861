import React from 'react';
import DataTable from './DataTable';

// Example columns
const columns = [
    { Header: 'ID', accessor: 'id' },
    { Header: 'Name', accessor: 'name' },
    { Header: 'Age', accessor: 'age' },
    { Header: 'Country', accessor: 'country' }
];

// Example data
const data = [
    { id: 1, name: 'John Doe', age: 28, country: 'USA' },
    { id: 2, name: 'Jane Smith', age: 34, country: 'UK' },
    { id: 3, name: 'Alice Johnson', age: 23, country: 'Canada' },
    { id: 4, name: 'Bob Brown', age: 45, country: 'Australia' },
    { id: 1, name: 'John Doe', age: 28, country: 'USA' },
    { id: 2, name: 'Jane Smith', age: 34, country: 'UK' },
    { id: 3, name: 'Alice Johnson', age: 23, country: 'Canada' },
    { id: 4, name: 'Bob Brown', age: 45, country: 'Australia' },
    { id: 1, name: 'John Doe', age: 28, country: 'USA' },
    { id: 2, name: 'Jane Smith', age: 34, country: 'UK' },
    { id: 3, name: 'Alice Johnson', age: 23, country: 'Canada' },
    { id: 4, name: 'Bob Brown', age: 45, country: 'Australia' },
    { id: 1, name: 'John Doe', age: 28, country: 'USA' },
    { id: 2, name: 'Jane Smith', age: 34, country: 'UK' },
    { id: 3, name: 'Alice Johnson', age: 23, country: 'Canada' },
    { id: 4, name: 'Bob Brown', age: 45, country: 'Australia' },
    { id: 1, name: 'John Doe', age: 28, country: 'USA' },
    { id: 2, name: 'Jane Smith', age: 34, country: 'UK' },
    { id: 3, name: 'Alice Johnson', age: 23, country: 'Canada' },
    { id: 4, name: 'Bob Brown', age: 45, country: 'Australia' },
    // Add more data entries as needed
];

const MyTable = () => {
    return (
        <>
            <h1>My Data Table</h1>
            <DataTable columns={columns} data={data} />
        </>
    );
};

export default MyTable;
