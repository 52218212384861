import React, { useEffect, useState } from "react";
import "./App.css";
import { Outlet, Routes, Route, BrowserRouter, Navigate } from "react-router-dom";
import LogedIn from "./components/logedcomponents/LogedIn";
import SignUp from "./components/logedcomponents/SignUp";
import Verification from "./components/logedcomponents/Verification";
import EmailVerification from "./components/logedcomponents/EmailVerification";
import ForgetPassword from "./components/logedcomponents/ForgetPassword";
import Home from "./pages/home/Home";
// import Wallet from "./pages/wallet/Wallet";
import Navbar from "./components/navbar/Navbar";
import Footer from "./components/footer/Footer";
import Menu from "./components/menu/Menu";
import ConfirmPasswordVerification from "./components/logedcomponents/ConfirmPasswordVerification";
import Buy from "./pages/buys/Buy";
import Stake from "./pages/stakes/Stake";
import HelpSupport from "./pages/helpsupport/HelpSupport";
import Reports from "./pages/reports/Reports";
import KYCVerification from "./pages/kyc/KYCVerification";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, logOutSuccess, setImportedWallet, setIsAuthenticate, setNotifCount, setStakeData, setTransactionsData, setTransactionsReport, setUserDetails, setUserWallets, userDetailsEditable } from "./redux/slices/authSlice";
import ViewDetailsDataTable from "./pages/stakes/ViewDetailsDataTable";
import LoginHistory from "./pages/loginhistory/LoginHistory";
import Profile from "./pages/profile/Profile";
import Settings from "./pages/setting/Settings";
import RefferalHistory from "./pages/refferalhistory/RefferalHistory";
import MyTable from "./DataTable/MyTable";
import DeviceVerification from "./pages/deviceverification/DeviceVerification";
import ProtectedRoute from "./protectedRoute/ProtectedRoute";
import { jwtDecode } from "jwt-decode";
import NotFoundPage from "./components/notFoundPage/NotFoundPage";
import FetchOnPageSwitch from "./components/FetchOnPageSwitch";

const App = () => {
  const dispatch = useDispatch();
  const isAuthenticate = useSelector((state) => state.auth.isUserAuthenticated);
  const updateWeb = useSelector((state) => state.auth.updateWeb);
  // const notifiCountUpdate = useSelector((state) => state.auth.notifiCountUpdate);
  const tokenFromRedux = useSelector((state) => state.auth.authToken);


  const isLogin = async () => {
    try {
      const token = localStorage.getItem("token");

      if (!token) {
        dispatch(setIsAuthenticate(false));
        return;
      }

      const user = jwtDecode(token);
      const now = Math.floor(Date.now() / 1000);
      const expireTime = user.exp;

      if (expireTime > now) {
        dispatch(setIsAuthenticate(true));
        authentication(token);
      } else {
        dispatch(setIsAuthenticate(false));
        handleLogout();
      }
    } catch (error) {
      dispatch(setIsAuthenticate(false)); // Treat any error as not authenticated
    }
  };


  const handleLogout = async () => {
    try {
      await clearCache();

      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("token");
      localStorage.removeItem("userId");

      // Clear Cookies
      document.cookie.split(";").forEach((c) => {
        document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
      });

      dispatch(logOutSuccess());
    } catch (error) {
    }
  };

  async function clearCache() {
    if (window.caches) {
      const cacheNames = await caches.keys();
      await Promise.all(
        cacheNames.map(async (name) => {
          const cache = await caches.open(name);
          await cache.clear();
        })
      );
    }
  }

  async function authentication() {
    if (!tokenFromRedux) return null;
    try {
      const userData = await axios.post(
        `${process.env.REACT_APP_BASE_URI}/user/authanticate
          `,
        {},
        {
          headers: {
            "x-access-token": tokenFromRedux,
          },
        }
      );
      if (userData.status == 200 || userData.status == 201) {
        const userRes = await userData?.data?.data;
        dispatch(setUserDetails(userRes));
        dispatch(
          userDetailsEditable({
            image: userRes?.selfie,
            fullName: userRes?.name,
            gender: userRes?.gender,
            mobileNumber: userRes?.mobileno,
            address: userRes?.address,
            email: userRes?.email,
            Country_Code: userRes?.country_code,
            refferalCode: userRes?.refferalCode
          })
        );
        dispatch(loginSuccess(true));
      }
    } catch (error) {
      const errorMessage = error.response?.data.error || error.response?.data?.message || error?.message || "unknown error";
      if (errorMessage == "Invalid Token") {
        handleLogout();
      }
    }
    // -----------------------------

    // ----------------------------
    try {
      const transactions = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/transactions
          `,
        {
          headers: {
            "x-access-token": tokenFromRedux,
          },
        }
      );
      if (transactions.status == 200 || transactions.status == 201) {
        const response = await transactions?.data;
        dispatch(setTransactionsReport(response));
      }
    } catch (error) {
    }
  }

  const getNotificationCount = async () => {
    try {
      const transactions = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/user/countNotification
          `,
        {
          headers: {
            "x-access-token": tokenFromRedux,
          },
        }
      );
      if (transactions.status == 200 || transactions.status == 201) {
        const response = await transactions?.data?.data;
        dispatch(setNotifCount(response));
      }
    } catch (error) { }
  };

  const handleFetchData = async () => {
    try {
      const importedtoken = await axios.get(
        `${process.env.REACT_APP_BASE_URI}/user/wallet_homesonu
          `,
        {
          headers: {
            "x-access-token": tokenFromRedux,
          },
        }
      );
      if (importedtoken.status == 200 || importedtoken.status == 201) {
        const res = await importedtoken?.data;
        dispatch(setImportedWallet(res));
      }
    } catch (error) {
    }
  };

  const callapi = async () => {
    try {
      const res = await axios.get(`${process.env.REACT_APP_BASE_URI}/staking/stakes`, {
        headers: {
          "x-access-token": tokenFromRedux,
        },
      }
      );
      if (res.status === 200 || res.status === 201) {
        const response = res?.data?.result;
        dispatch(setStakeData(response));
      }
    } catch (error) {
    }
  };
  useEffect(() => {
    isLogin();
    authentication();
    if (tokenFromRedux && isAuthenticate) {
      handleFetchData();
      getNotificationCount();
      callapi();
    }
  }, [tokenFromRedux, isAuthenticate, updateWeb]);

  // useEffect(() => {
  //   getNotificationCount();
  // }, [notifiCountUpdate]);


  const Layout = () => {
    return (
      <div className="main">
        <FetchOnPageSwitch />
        <Navbar />
        <div className="conTainer">
          <div className="menuContainer">
            <Menu />
          </div>
          <div className="contentContainer">
            <Outlet />
          </div>
        </div>
        <Footer />
      </div>
    );
  };

  return (

    <BrowserRouter basename="/">
      <Routes>
        <Route path="/" element={<Layout />}>

          <Route path="/" element={<ProtectedRoute> <Home /> </ProtectedRoute>} />
          {/* {isAuthenticate && ( */}
          <>
            <Route path="/buy" element={<ProtectedRoute><Buy /></ProtectedRoute>} />
            <Route path="/stake" element={<ProtectedRoute><Stake /></ProtectedRoute>} />
            <Route path="/view-details" element={<ProtectedRoute><ViewDetailsDataTable /></ProtectedRoute>} />
            <Route path="/help-support" element={<ProtectedRoute><HelpSupport /></ProtectedRoute>} />
            <Route path="/reports" element={<ProtectedRoute><Reports /></ProtectedRoute>} />
            <Route path="/kyc" element={<ProtectedRoute><KYCVerification /></ProtectedRoute>} />
            <Route path="/login-history" element={<ProtectedRoute><LoginHistory /></ProtectedRoute>} />
            <Route path="/profile" element={<ProtectedRoute><Profile /></ProtectedRoute>} />
            <Route path="/settings" element={<ProtectedRoute><Settings /></ProtectedRoute>} />
            <Route path="/refferal-history" element={<ProtectedRoute><RefferalHistory /></ProtectedRoute>} />
            <Route path="/MyTable" element={<ProtectedRoute><MyTable /></ProtectedRoute>} />
          </>
          {/* )} */}
        </Route>

        <Route path="/login" element={isAuthenticate ? <Navigate to="/" /> : <LogedIn />} />
        <Route path="/sign-up" element={isAuthenticate ? <Navigate to="/" /> : <SignUp />} />
        <Route path="/verification" element={isAuthenticate ? <Navigate to="/" /> : <Verification />} />
        {/* <Route path="/email-verification" element={<EmailVerification />} />
         */}
        <Route path="/email-verification" element={isAuthenticate ? <Navigate to="/" /> : <EmailVerification />} />

        {/* <Route path="/forget-password" element={<ForgetPassword />} /> */}
        <Route path="/forget-password" element={isAuthenticate ? <Navigate to="/" /> : <ForgetPassword />} />

        {
          /* <Route
                path="/confirm-password"
                element={<ConfirmPasswordVerification />}
              /> */
        }
        <Route path="/confirm-password" element={isAuthenticate ? <Navigate to="/" /> : <ConfirmPasswordVerification />} />;

        <Route path="/device-verification/:token" element={<DeviceVerification />} />

        <Route path="*" element={<NotFoundPage />} />

      </Routes>
    </BrowserRouter>

  );
};
export default App;
