import { Box, Container, Grid, Typography } from "@mui/material";
import React from "react";
import signLogo from "../assets/logo/Palm-Logo.png";
import { Link } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import EmailIcon from "@mui/icons-material/Email";

const EmailVerification = () => {
    return (
        <Box className="SignUpContainer">
            <Container maxWidth="xl">
                <Grid container>
                    <Grid lg="4" md="4" sm="12" xs="12" margin={"auto"}>
                        <Box>
                            <Box textAlign={"center"} marginBottom="2rem">
                                <Box>
                                    <img src={signLogo} alt="" width={"120px"} />
                                </Box>
                                <Box>
                                    <Typography variant="h5" fontWeight={"500"} color={"var(--body)"}>
                                        Email Verification
                                    </Typography> 
                                </Box>
                            </Box>
                            <Box position="relative" bgcolor={"var(--white)"} zIndex="9" borderRadius={"1rem"} padding={"1.5rem"} boxShadow={"0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"}>
                                <Box display="flex" alignItems="center" gap="10px" marginBottom="1rem">
                                    <Box className="EmailIcons">
                                        <EmailIcon sx={{ color: "var(--primaryColor)", fontSize: 30 }} />
                                    </Box>
                                    <Box>
                                      <Typography variant="h5" color={"var(--body)"} fontWeight={"500"}>
                                          Verify your email
                                      </Typography>
                                      <Typography variant="body2" color={"var(--textmuted)"} textAlign={"center"} gutterBottom>
                                        Please enter the 6 digit code send to example@abc.com
                                    </Typography>
                                    </Box>

                                </Box> 
                                <Box
                                    sx={{
                                        display: { md: "flex", sm: "flex", xs: "flex" },
                                        alignItems: "center",
                                        gap: "10px",
                                        marginTop: "1rem",
                                    }}
                                >
                                    <input type="text" className="textInput inputOTP" />
                                    <input type="email" className="textInput inputOTP" />
                                    <input type="text" className="textInput inputOTP" />
                                    <input type="email" className="textInput inputOTP" />
                                    <input type="text" className="textInput inputOTP" />
                                    <input type="email" className="textInput inputOTP" />
                                </Box>
                                <Box sx={{ width: { md: "100%" }, margin: "auto" }}>
                                    <button className="Continue" style={{ background: "var(--primaryColor)" }}>
                                        Comfirm
                                    </button>
                                </Box>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default EmailVerification;
