import { Box, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import TransactionsDashboard from "../home/TransactionsDashboard";
import { useSelector } from "react-redux";

const Reports = () => {
    const transactionDataTable = useSelector((state) => state.auth.transactionsReport);
    const [transactionData, setTransactionData] = useState(transactionDataTable);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [selectedValue, setSelectedValue] = useState("");
    const user = useSelector((state) => state.auth.userDetails);
    const selectedAccount = user?.selectedAccount;
    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    // const actionType = transactionDataTable.map((ele) => {
    //     const actions = ele.action;
    //     return actions;
    // });
    // const filterAction = Array.from(new Set(actionType));

    function filterByDate() {
        try {
            // Start with the full dataset
            let filteredData = transactionDataTable;

            // Filter by date range
            if (fromDate !== "" && toDate !== "") {
                const from_date = new Date(fromDate);
                const to_date = new Date(toDate);
                const startTimestamp = Math.floor(from_date.setHours(0, 0, 0, 0) / 1000);
                const endTimestamp = Math.floor(to_date.setHours(23, 59, 59, 999) / 1000);
                filteredData = filteredData.filter((item) => {
                    return item.timeStamp >= startTimestamp && item.timeStamp <= endTimestamp;
                });
            } else {
                // Filter by fromDate only
                if (fromDate !== "") {
                    const from_date = new Date(fromDate);
                    const startTimestamp = Math.floor(from_date.setHours(0, 0, 0, 0) / 1000);
                    const endTimestamp = Math.floor(from_date.setHours(23, 59, 59, 999) / 1000);
                    filteredData = filteredData.filter((item) => {
                        return item.timeStamp >= startTimestamp && item.timeStamp <= endTimestamp;
                    });
                }

                // Filter by toDate only
                if (toDate !== "") {
                    const to_date = new Date(toDate);
                    const startTimestamp = Math.floor(to_date.setHours(0, 0, 0, 0) / 1000);
                    const endTimestamp = Math.floor(to_date.setHours(23, 59, 59, 999) / 1000);
                    filteredData = filteredData.filter((item) => {
                        return item.timeStamp >= startTimestamp && item.timeStamp <= endTimestamp;
                    });
                }
            }

            // Further filter by selected value if provided
            if (selectedValue !== "") {
                if (selectedValue === "1") {
                    filteredData = filteredData.filter(trx => trx.to.toLowerCase() === selectedAccount.toLowerCase());
                } else if (selectedValue === "0") {
                    filteredData = filteredData.filter(trx => trx.from.toLowerCase() === selectedAccount.toLowerCase());
                }
            }

            // Update the state with filtered data
            setTransactionData(filteredData);

        } catch (error) {
            console.log(error, "ver error");
        }
    }


    return (
        <Box className="buyMainContainer">
            <Box className="DASHBOARDheading">
                <Box className="titledashboard">
                    <h4>Reports</h4>
                </Box>
                <Box className="">
                    <ul className="dashboard_ulItem">
                        <li style={{ color: "var(--white)", fontSize: "12px" }}>Palm Smart Chain</li>
                        <li>/</li>
                        <li>Reports</li>
                    </ul>
                </Box>
            </Box>
            <Box bgcolor={"var(--darkSoft)"} sx={{ padding: "15px", borderRadius: "10px" }} marginBottom={"1.5rem"}>
                <Box
                    marginBottom={"1.5rem"}
                    sx={{
                        display: { md: "flex", sm: "flex", xs: "block" },
                        gap: "10px",
                    }}
                >
                    <Box sx={{ width: { md: "33%", sm: "50%" } }}>
                        <Typography marginY="10px" variant="body2" color={"var(--textmuted)"}>
                            From Date
                        </Typography>
                        <input className="input" type="date" value={fromDate} onChange={handleFromDateChange} />
                    </Box>
                    <Box sx={{ width: { md: "33%", sm: "50%" } }}>
                        <Typography marginY="10px" variant="body2" color={"var(--textmuted)"}>
                            To Date
                        </Typography>
                        <input className="input" type="date" value={toDate} onChange={handleToDateChange} />
                    </Box>
                    <Box sx={{ width: { md: "33%", sm: "50%" } }}>
                        <Typography marginY="10px" variant="body2" color={"var(--textmuted)"}>
                            Type
                        </Typography>
                        <select
                            className="form-select input"
                            value={selectedValue}
                            onChange={(e) => {
                                setSelectedValue(e.target.value);
                            }}
                        >
                            <option value="">Select</option>
                            <option value="0">Send</option>
                            <option value="1">Receive</option>
                        </select>
                    </Box>
                </Box>
                <Box>
                    <button className="buttonSendHelp" onClick={filterByDate}>
                        <SearchOutlinedIcon /> Search
                    </button>
                </Box>
            </Box>
            <Box bgcolor={"var(--darkSoft)"} sx={{ padding: "15px", borderRadius: "10px" }}>
                <Box>
                    <h3>All Reports</h3>
                    <TransactionsDashboard trxTable={[...transactionData]} />
                </Box>
            </Box>
        </Box>
    );
};

export default Reports;
