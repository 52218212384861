import { Box, Typography, IconButton, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const SwapConfirmModel = ({ isOpen, onClose, onConfirm, confirmData, isLoading }) => {
    if (!isOpen) return null;
    const from_symbol = confirmData.from_symbol;
    const to_symbol = confirmData.to_symbol;
    const amount1 = Number(confirmData?.from_amount);
    const amount2 = Number(confirmData?.to_amount);
    const formattedFromAmount = !isNaN(amount1) ? parseFloat(amount1.toFixed(6)) : "N/A";
    const formattedToAmount = !isNaN(amount2) ? parseFloat(amount2.toFixed(6)) : "N/A";

    return (
        <Box
            sx={{
                position: "fixed",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: "80%",
                maxWidth: "500px",
                bgcolor: "var(--bs-light)",
                borderRadius: "20px",
                boxShadow: 24,
                p: 4,
                zIndex: 1300,
            }}
        >
            <Box display="flex" flexDirection="column" alignItems="center">
                <IconButton onClick={onClose} sx={{ position: "absolute", top: 16, right: 16 }}>
                    <CloseIcon sx={{ color: "var(--primaryColor)", fontSize: 24 }} />
                </IconButton>
                <Box marginTop="3rem">
                    <Typography variant="h5" gutterBottom color="var(--white)">
                        Swap Transaction Confirmation
                    </Typography>
                </Box>
                <Box mb={2}>
                    <Typography variant="body1" color="var(--white)" gutterBottom>
                        <strong>From Currency:</strong> {formattedFromAmount} {from_symbol}
                    </Typography>
                    <Typography variant="body1" color="var(--white)">
                        <strong>To Currency:</strong> {formattedToAmount} {to_symbol}
                    </Typography>
                </Box>

                <Box display="flex" justifyContent="space-between" gap="10px" mt={2} width="100%">
                    <button className="buyButtons" onClick={onClose} style={{ width: '100%', background: 'var(--pink) !important' }} >
                        Cancel
                    </button>
                    <button className="buyButtons" onClick={onConfirm} disabled={isLoading} style={{ width: '100%' }} >
                        {isLoading ? "Loading..." : "Confirm"}
                    </button>
                </Box>
            </Box>
        </Box>
    );
};

export default SwapConfirmModel;
