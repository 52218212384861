import React from "react";
import "./footer.css";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="Footer">
      <Box
        sx={{
          display: { lg: "flex", md: "flex", sm: "block", xs: "block" },
          textAlign: "center",
        }}
        alignItems={"center"}
        justifyContent={"space-between"}
      >
        <p>2024 © Palm Smart Chain.</p>
        <p>
          Made With by
          <Link
            to="https://thinker-technology.com/"
            target="_blank"
            style={{
              textDecoration: "none",
              color: "var(--primaryColor)",
              marginLeft: "5px",
            }}
          >
            Thinker Technology
          </Link>
        </p>
      </Box>
    </div>
  );
};

export default Footer;
