import { Box, Container, Grid, IconButton, Modal, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import "./buy.css";
import SwapHorizOutlinedIcon from "@mui/icons-material/SwapHorizOutlined";
import logo from "../../components/assets/Palm-Smart-Chain-Icon.png";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import "reactjs-popup/dist/index.css";
import CircularProgress from "@mui/material/CircularProgress";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import QRCode from "react-qr-code";
import * as htmlToImage from "html-to-image";

import { setChangeBuyTab } from "../../redux/slices/authSlice";
import { fetchAndDispatchHomeData } from "../../api";
import { SuccessModal } from "../../CustomLoader/SuccessModal";
import ConfirmationModel from "../../CustomLoader/ConfirmationModel";
import CustomLoader from "../../CustomLoader/CustomLoader";
import SelectedOption from "../../CustomLoader/SelectedOption";
import SendIcon from '@mui/icons-material/Send';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import SwapCallsIcon from '@mui/icons-material/SwapCalls';
import ShareIcon from '@mui/icons-material/Share';
import SocialShareModal from "../refferalhistory/SocialShareModal";
import SwapConfirmModel from "../../CustomLoader/SwapConfirmModel";


const BASE_URI = process.env.REACT_APP_BASE_URI;



const Buy = () => {
    const PALM_CHAIN_ID = Number(process.env.REACT_APP_PALM_CHAIN_ID);
    const dispatch = useDispatch();
    const useraddress = useSelector((state) => state.auth.importedAdress);
    const allTokens = useraddress?.BuyWith || [];
    const tokenWith973 = allTokens.find((token) => token.chain_id === PALM_CHAIN_ID);
    const tokensWithout973 = allTokens.filter((token) => token.chain_id !== PALM_CHAIN_ID);
    // Combine the token with chain_id 973 at the beginning with the rest of the tokens
    const reorderedTokens = tokenWith973 ? [tokenWith973, ...tokensWithout973] : tokensWithout973;

    const token = useSelector((state) => state.auth.authToken);

    const [sendCoin, setsendCoin] = useState({ receiverAddress: "", amount: "" });
    // const [calculatedResult, setCalculatedResult] = useState("");
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({ title: "", hash: "" });
    const [isModalOpenConfirm, setIsModalOpenConfirm] = useState(false);
    const [confirmData, setConfirmData] = useState({ receiverAddress: " ", amount: "", chain_id: "", symbol: "" });
    const [swapType, setSwapType] = useState(true);

    const handleSwapType = () => setSwapType(!swapType);

    const handleCopyAddress = async () => {
        try {
            await navigator.clipboard.writeText(useraddress?.wallet_details?.address);
            toast.success('Copied to clipboard!');
        } catch (err) {
            toast.error('Failed to copy. Please try again.');
        }
    };

    const [swappingAmount, setSwappingAmount] = useState("");
    // const [selectSwapCoin, setSelectSwapCoin] = useState({ symbol: "ETH", chainId: 1 });
    const selectSwapCoin = useSelector((state) => state.auth.selectedBuyWithToken);
    const [swappingCalculatedResult, setswappingCalculatedResult] = useState("");
    const [isSwapModalOpenConfirm, setSwapModalOpenConfirm] = useState(false);
    const [swapConfirmData, setSwapConfirmData] = useState(null);


    const handleSwapSubmit = async () => {
        // e.preventDefault();
        if (!swappingAmount || parseFloat(swappingAmount) === 0) {
            return toast.error("Please enter a valid amount.");
        } else {
            const tokens = useraddress.BuyWith.find((token) => Number(token.chain_id) === Number(selectSwapCoin.chain_id) && token.symbol === selectSwapCoin.symbol);
            const balance = tokens.balance;

            if (Number(swappingAmount) > Number(balance)) return toast.error("Insufficient Balance.");
            setSwapModalOpenConfirm(false);
            setLoading(true);
            try {
                const buyCoin = {
                    amount: swappingAmount,
                    chain_id: selectSwapCoin.chain_id,
                    symbol: selectSwapCoin.symbol,
                };
                const headers = {
                    "x-access-token": token,
                };
                const res = await axios.post(`${BASE_URI}/buy`, buyCoin, { headers });

                if (res.status == 200 || res.status == 201) {
                    setswappingCalculatedResult("");
                    setSwappingAmount(0);
                    setModalData({
                        title: res?.data?.message,
                        hash: res?.data?.hash,
                    });
                    setIsModalOpen(true);

                    fetchAndDispatchHomeData(dispatch, token);
                }
            } catch (error) {
                const errorMessage = error.response.data.error ? error?.response?.data?.error : error?.response?.data?.message;
                toast.error(errorMessage);
            } finally {
                setLoading(false);
            }
        }
    };

    const calculateSwapamount = async () => {
        try {
            if (swappingAmount > 0) {
                const res = await axios.post(`${BASE_URI}/calculator`, { amount: swappingAmount, currency: selectSwapCoin.symbol, chain_id: selectSwapCoin.chain_id, CalculateType: "buy" });
                if (res.status === 200 || res.status === 201) {
                    const calculate = res.data.result;
                    setswappingCalculatedResult(calculate);
                }
            } else {
                setswappingCalculatedResult("");
            }
        } catch (error) {
            // const errorMessage = error.response.data.error ? error?.response?.data?.error : error?.response?.data?.message;
        }
    };

    useEffect(() => {
        if (swappingAmount && selectSwapCoin) {
            calculateSwapamount();
        }
    }, [swappingAmount, selectSwapCoin]);


    const currentTab = useSelector((state) => state.auth.currentButTab);

    const [activeBox, setActiveBox] = useState(0);

    //handle send token tab starts here
    const [selectedItemWhileSend, setSelectedItemWhileSend] = useState(reorderedTokens[0] || null);

    const handleSendToken = async () => {
        if (!sendCoin.receiverAddress.trim() || !sendCoin.amount.trim()) {
            return toast.error("Please enter both the address and the amount.");
        }

        if (!selectedItemWhileSend) {
            return toast.error("Select a token for sending!");
        }

        // Extract chain_id and symbol
        const chain_id = selectedItemWhileSend?.chain_id;
        const symbol = selectedItemWhileSend?.symbol;

        // Add chain_id and symbol to sendCoin object
        const requestPayload = {
            ...sendCoin,
            chain_id,
            symbol,
        };

        const balance = Number(selectedItemWhileSend.balance);
        if (Number(sendCoin.amount) > balance) {
            return toast.error("Insufficient Balance for transfer");
        }

        // Set data for confirmation modal
        setConfirmData({
            receiverAddress: requestPayload.receiverAddress,
            amount: requestPayload.amount,
            chain_id: requestPayload.chain_id,
            symbol: requestPayload.symbol,
        });
        setIsModalOpenConfirm(true);
    };

    // handleConfirm call API
    const handleConfirm = async (mobileOtp, emailOtp) => {
        try {
            setIsModalOpenConfirm(false);
            setLoading(true);

            const headers = {
                "x-access-token": token,
            };

            const bodyData = {
                ...confirmData,
                otp: mobileOtp,
                email_otp: emailOtp
            }


            // Make the API request with the updated payload
            const res = await axios.post(`${BASE_URI}/send`, bodyData, { headers });

            if (res.status === 200 || res.status === 201) {
                setModalData({
                    title: res?.data?.message,
                    hash: res?.data?.hash,
                });
                setIsModalOpen(true);
                setsendCoin({ receiverAddress: "", amount: "" }); // Reset the form
                fetchAndDispatchHomeData(dispatch, token);
            } else {
                // Handle unexpected status codes
                toast.error(`Unexpected response status: ${res.status}`);
            }
        } catch (error) {
            console.error("API call error:", error);

            // Improved error handling
            const errorMessage = error.response?.data?.error || error.response?.data?.message || "An error occurred";
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    const handleSelectToken = async (item, index) => {
        setActiveBox(index);
        setSelectedItemWhileSend(item);
    };
    //handle send token tab end here
    const addres = useraddress ? useraddress?.wallet_details?.address : "userdetail";
    const formattedAddress = `${String(addres).slice(0, 10)}...${String(addres).slice(-11)}`;

    const handleAmountChange = (e) => {
        const amountValue = e.target.value;
        setsendCoin({ ...sendCoin, amount: amountValue });
        errorHandler(amountValue);
    };

    const errorHandler = (amountValue) => {
        try {
            // Validate the entered amount
            const userBalance = Number(selectedItemWhileSend.balance);
            const enteredAmount = Number(amountValue);

            if (enteredAmount < 0) {
                setErrorMessage("The amount cannot be less than 0.");
                setIsButtonDisabled(true);
            } else if (enteredAmount > userBalance) {
                setErrorMessage("The amount is not sufficient.");
                setIsButtonDisabled(true);
            } else {
                setErrorMessage("");
                setIsButtonDisabled(false);
            }
        } catch (error) {
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
        setSwapModalOpenConfirm(false);
    };

    const handleCloseModalConform = () => setIsModalOpenConfirm(false);

    // sell token------------------------------------------------------------------
    const [saleTokens, setSaleTokens] = useState("");
    const [saleCalculatedTokens, setSaleCalculatedTokens] = useState("");
    const saleReceiveToken = useSelector((state) => state.auth.selectedSaleToken);

    const calculateSaleTokens = async () => {
        try {
            if (saleTokens > 0) {
                const res = await axios.post(`${BASE_URI}/calculator`, { amount: saleTokens, currency: saleReceiveToken.symbol, chain_id: saleReceiveToken.chain_id, CalculateType: "sell" });
                if (res.status === 200 || res.status === 201) {
                    const calculate = res.data.result;
                    setSaleCalculatedTokens(calculate);
                }
            } else {
                setSaleCalculatedTokens("");
            }
        } catch (error) {
            console.log(error, " errorerror");
        }
    };

    useEffect(() => {
        if (saleTokens) {
            calculateSaleTokens();
        }
    }, [saleTokens, saleReceiveToken]);

    const handleSaleToken = async () => {
        const amount = saleTokens.trim();
        if (!amount) {
            return toast.error("Enter the amount.");
        }

        if (!saleReceiveToken) {
            return toast.error("Select a token for sending!");
        }

        const chain_id = saleReceiveToken?.chain_id;
        const symbol = saleReceiveToken?.symbol;

        const requestPayload = {
            amount,
            chain_id,
            symbol,
        };

        const balance = Number(tokenWith973.balance);
        if (Number(amount) > balance) {
            return toast.error("Insufficient balance for the swap operation.");
        }

        setSwapModalOpenConfirm(false);
        setLoading(true);
        try {
            const headers = {
                "x-access-token": token,
            };
            const res = await axios.post(`${BASE_URI}/sell`, requestPayload, { headers });

            if (res.status == 200 || res.status == 201) {
                setSaleTokens("");
                setSaleCalculatedTokens("");
                setModalData({
                    title: res?.data?.message,
                    hash: res?.data?.hash,
                });
                setIsModalOpen(true);

                fetchAndDispatchHomeData(dispatch, token);
            }
        } catch (error) {
            const errorMessage = error.response.data.error ? error?.response?.data?.error : error?.response?.data?.message;
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    };

    // social share modal

    const homeData = useSelector((state) => state.auth.importedAdress);
    const UserDetailsEditable = useSelector((state) => state.auth.UserDetailsEditable);
    const partners = homeData?.partners || [];
    const referralCode = UserDetailsEditable?.refferalCode
    const [isShareModalOpen, setIsShareModalOpen] = useState(false);

    // const openShareModal = () => {
    //     setIsShareModalOpen(true);
    // };

    const closeShareModal = () => {
        setIsShareModalOpen(false);
    };


    // const shareUrl = signUpPageLink("refferal-history") + 'sign-up?referralCode=' + referralCode;
    // const shareTitle = "Palm Smart Chain";


    // GIFT API CALL______________startrt_________________________________________________
    const [giftAmount, setGiftAmount] = useState("");
    const [giftReceiverAddress, setGiftReceiverAddress] = useState("");
    const [giftAmountError, setGiftErrorMessage] = useState("");
    const [giftDisableButton, setgiftDisableButton] = useState(true);

    const giftAmountHandler = (e) => {
        const amount = e.target.value;
        setGiftAmount(amount);
        if (Number(amount) > Number(tokenWith973.balance)) {
            setGiftErrorMessage("The amount is not sufficient.");
            setgiftDisableButton(true);
        } else {
            setGiftErrorMessage("");
            setgiftDisableButton(false);
        }
    }

    const giftModelHandle = () => {
        if (giftReceiverAddress == "") return toast.error("Enter receiver address")

        if (giftAmount == "" || isNaN(giftAmount) || giftAmount <= 0) return toast.error("Amount should be greater then zero")
        try {
            const balance = Number(selectedItemWhileSend.balance);
            if (Number(giftAmount) > balance) {
                return toast.error("Insufficient Balance for transfer");
            }

            // Set data for confirmation modal
            setConfirmData({
                receiverAddress: giftReceiverAddress,
                amount: giftAmount,
                chain_id: tokenWith973.chain_id,
                symbol: tokenWith973.symbol,
            });
            setIsModalOpenConfirm(true);


        } catch (error) {
            console.log(error, " error")
        }
    }

    const giftPalmApiCall = async (mobileOtp, emailOtp) => {
        try {

            setIsModalOpenConfirm(false);
            setLoading(true);
            const headers = {
                "x-access-token": token,
            };

            const bodyData = {
                receiverAddress: giftReceiverAddress,
                giftAmount,
                otp: mobileOtp,
                email_otp: emailOtp
            }

            // Make the API request with the updated payload
            const res = await axios.post(`${BASE_URI}/gift`, bodyData, { headers });

            if (res.status === 200 || res.status === 201) {
                setModalData({
                    title: res?.data?.message,
                    hash: res?.data?.hash,
                });
                setIsModalOpen(true);
                fetchAndDispatchHomeData(dispatch, token);
            } else {
                // Handle unexpected status codes
                toast.error(`Unexpected response status: ${res.status}`);
            }
        } catch (error) {
            console.error("API call error:", error);
            // Improved error handling
            const errorMessage = error.response?.data?.error || error.response?.data?.message || "An error occurred";
            toast.error(errorMessage);
        } finally {
            setLoading(false);
        }
    }
    // GIFT API CALL___________________end____________________________________________


    // swap model-----------for buy/sell-----------------
    const publicToPrivateSwapHandler = () => {

        if (swapType) {
            if (!swappingAmount || parseFloat(swappingAmount) === 0) {
                return toast.error("Please enter a valid amount.");
            }
            const tokens = useraddress.BuyWith.find((token) => Number(token.chain_id) === Number(selectSwapCoin.chain_id) && token.symbol === selectSwapCoin.symbol);
            const balance = tokens.balance;

            if (Number(swappingAmount) > Number(balance)) return toast.error("Insufficient Balance.");
            setSwapConfirmData({
                from_symbol: selectSwapCoin.symbol,
                from_amount: swappingAmount,
                to_symbol: "PALM",
                to_amount: swappingCalculatedResult
            });
        } else {
            if (!saleTokens || parseFloat(saleTokens) === 0) {
                return toast.error("Please enter a valid amount.");
            }
            const balance = Number(tokenWith973.balance);
            if (Number(saleTokens) > balance) {
                return toast.error("Insufficient balance for the swap operation.");
            }

            setSwapConfirmData({
                from_symbol: "PALM",
                from_amount: saleTokens,
                to_symbol: saleReceiveToken?.symbol,
                to_amount: saleCalculatedTokens
            });
        }
        setSwapModalOpenConfirm(true);

    }

    return (
        <>
            <Box className="buyMainContainer">
                <Box className="DASHBOARDheading">
                    <Box className="titledashboard">  <h3>{currentTab === "Gift" ? "Gift PALM to a Wallet" : currentTab === "Receive" ? "Receiver Address" : currentTab === "Send" ? "Send Amount" : "BUY/SELL"}</h3> </Box>
                    <Box className="">
                        <ul className="dashboard_ulItem">
                            <li style={{ color: "var(--white)", fontSize: "12px" }}>Palm Smart Chain</li>
                            <li>/</li>
                            <li>{currentTab === "Gift" ? "Gift PALM" : currentTab === "Receive" ? "Receive" : currentTab === "Send" ? "Send" : "BUY/SELL"}</li>
                        </ul>
                    </Box>
                </Box>


                <Box>
                    <Container maxWidth="xxl" sx={{ paddingX: { sm: "5px", xs: "5px" } }}>
                        <Grid container spacing={2}>

                            <Grid item lg="12" md="12" sm="12" xs="12">
                                <Box className="tabsRowDetailss">

                                    {/* GIFT CODE HERE START------------------------ */}

                                    {currentTab === "Gift" && (
                                        <Box>
                                            <Typography marginBottom={"10px"} variant="body2" color={"var(--white)"}>
                                                Available Balance : {tokenWith973.balance} PALM
                                            </Typography>

                                            <Box
                                                sx={{
                                                    width: {
                                                        lg: "50%",
                                                        md: "50%",
                                                        sm: "100%",
                                                        xs: "100%",
                                                    },
                                                    border: ' 2px solid var(--bs-light)',
                                                    padding: '1rem',
                                                    borderRadius: '12px'
                                                }}
                                            >
                                                <Box marginBottom={"1rem"}>
                                                    <Typography marginBottom={"8px"} variant="body2" color={"var(--white)"}>
                                                        Gift Address :
                                                    </Typography>
                                                    <input autoComplete="off" className="input" placeholder="Gift Address" onChange={(e) => setGiftReceiverAddress(e.target.value)} />
                                                </Box>
                                                <Box>
                                                    <Typography marginBottom={"8px"} variant="body2" color={"var(--white)"}>
                                                        No of PALM :
                                                    </Typography>
                                                    <input
                                                        autoComplete="off"
                                                        className="input"
                                                        placeholder="No of Palm"
                                                        min={0}
                                                        onChange={giftAmountHandler}
                                                        name="amount"
                                                        type="number" // Use type="number" to ensure numeric input
                                                    />
                                                    {giftAmountError && <p style={{ color: "red" }}>{giftAmountError}</p>}
                                                </Box>
                                                <Box marginTop={"1.5rem"} textAlign={"center"}>
                                                    {loading ? (
                                                        <CircularProgress disableShrink />
                                                    ) : (
                                                        <button disabled={giftDisableButton} className="buyButtons" style={{ background: "var(--primaryColor)" }} onClick={() => giftModelHandle()}>
                                                            Gift Now
                                                        </button>
                                                    )}
                                                    <ConfirmationModel isOpen={isModalOpenConfirm} onClose={handleCloseModalConform} onConfirm={giftPalmApiCall} confirmData={confirmData} isLoading={loading} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}

                                    {/* GIFT CODE HERE END----------------------------- */}

                                    {currentTab === "Send" && (
                                        <Box>
                                            <Typography marginBottom={"10px"} variant="body2" color={"var(--white)"}>
                                                Token :
                                            </Typography>
                                            <Box
                                                sx={{
                                                    display: { md: "flex", sm: "flex", xs: "flex" },
                                                    alignItems: "center",
                                                    gap: "5px",
                                                    flexWrap: "wrap",
                                                    marginBottom: '1rem'
                                                }}
                                            >
                                                {reorderedTokens.map((item, index) => {
                                                    return (
                                                        <button key={index} className={activeBox === index ? "DoCoinbtnActive" : "DoCoinbtn"} onClick={() => handleSelectToken(item, index)}>
                                                            <img
                                                                src={item.image}
                                                                alt=""
                                                                style={{
                                                                    borderRadius: 50,
                                                                    width: "35px",
                                                                    height: "35px",
                                                                    objectFit: 'contain'
                                                                }}
                                                            />
                                                            {/* {item.name} */}
                                                            {item.balance} {item.symbol}
                                                        </button>
                                                    );
                                                })}
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: {
                                                        lg: "50%",
                                                        md: "50%",
                                                        sm: "100%",
                                                        xs: "100%",
                                                    },
                                                    border: ' 2px solid var(--bs-light)',
                                                    padding: '1rem',
                                                    borderRadius: '12px'
                                                }}
                                            >
                                                <Box marginBottom={"1rem"}>
                                                    <Typography marginBottom={"8px"} variant="body2" color={"var(--white)"}>
                                                        Receiver Address :
                                                    </Typography>
                                                    <input className="input" placeholder="Wallet Address" onChange={(e) => setsendCoin({ ...sendCoin, receiverAddress: e.target.value })} name="to" value={sendCoin.receiverAddress} />
                                                </Box>
                                                <Box>
                                                    <Typography marginBottom={"8px"} variant="body2" color={"var(--white)"}>
                                                        No of Token :
                                                    </Typography>
                                                    <input
                                                        className="input"
                                                        placeholder="No of Token"
                                                        min={0}
                                                        onChange={handleAmountChange}
                                                        value={sendCoin.amount}
                                                        name="amount"
                                                        type="number" // Use type="number" to ensure numeric input
                                                    />
                                                    {errorMessage && <p style={{ color: "red" }}>{errorMessage}</p>}
                                                </Box>
                                                <Box marginTop={"1.5rem"} textAlign={"center"}>
                                                    {loading ? (
                                                        <CircularProgress disableShrink />
                                                    ) : (
                                                        <button disabled={isButtonDisabled} className="buyButtons" style={{ background: "var(--primaryColor)" }} onClick={() => handleSendToken()}>
                                                            Send
                                                        </button>
                                                    )}
                                                    <ConfirmationModel isOpen={isModalOpenConfirm} onClose={handleCloseModalConform} onConfirm={handleConfirm} confirmData={confirmData} isLoading={loading} />
                                                </Box>
                                            </Box>
                                        </Box>
                                    )}
                                    {currentTab === "Receive" && (
                                        <Box textAlign="center">
                                            <Box className="" display="inline-block">
                                                <Box display="flex" alignItems="center" gap="10px" marginBottom={'10px'} border="2px solid var(--bs-light)" padding="5px 10px" borderRadius={"8px"}>
                                                    <Typography fontSize="16px" color={"var(--white)"}>
                                                        {/* Wallet Address: {useraddress.wallet_details.address} */}
                                                        <span> Wallet Address:</span> <span>{formattedAddress}</span>
                                                    </Typography>
                                                    <IconButton onClick={handleCopyAddress} sx={{ background: 'var(--primaryColor)' }}>
                                                        <ContentCopyIcon sx={{ color: "var(--white)", fontSize: 20 }} />
                                                    </IconButton>
                                                    {/* <IconButton onClick={openShareModal} sx={{ background: 'var(--green)' }}>
                                                        <ShareIcon sx={{ color: "var(--white)", fontSize: 20 }} />
                                                    </IconButton> */}
                                                </Box>
                                                {/* <SocialShareModal
                                                    isOpen={isShareModalOpen}
                                                    onClose={closeShareModal}
                                                    url={shareUrl}
                                                    title={shareTitle}
                                                /> */}
                                                <Box
                                                    border="2px solid var(--bs-light)"
                                                    padding="20px"     // Add extra padding around the QR code
                                                    borderRadius={"12px"}
                                                    sx={{ width: '100%', maxWidth: '350px' }}
                                                >
                                                    <QRCode

                                                        title="User Wallet Address"
                                                        value={String(useraddress?.wallet_details?.address)}
                                                        bgColor="#ffffff"
                                                        fgColor="#000000"
                                                        size={300}
                                                    />
                                                </Box>

                                            </Box>
                                        </Box>
                                    )}
                                    {currentTab === "Swap" && (
                                        <Box>
                                            {swapType ? (
                                                <Box>
                                                    <Box
                                                        sx={{
                                                            display: { md: "flex" },
                                                            alignItems: "center",
                                                            gap: "10px",
                                                        }}
                                                        marginBottom="1.2rem"
                                                    >

                                                    </Box>
                                                    <Typography marginBottom={"10px"} variant="body1" color={"var(--white)"}>
                                                        {/* Add value : */}
                                                    </Typography>
                                                    <Box bgcolor="var(--darkSoft)" border="2px solid var(--bs-light)"
                                                        sx={{
                                                            position: "relative",
                                                            width: { lg: "50%", md: "50%", sm: '100%', xs: "100%" },
                                                            padding: '1.5rem',
                                                            borderRadius: '12px',
                                                            margin: 'auto'
                                                        }}
                                                    >
                                                        <Box marginBottom="15px" display="flex" alignItems="center" justifyContent="space-between">
                                                            <Typography gutterBottom>Available Balance:</Typography>
                                                            <Typography gutterBottom> {selectSwapCoin?.balance} {selectSwapCoin?.symbol}</Typography>
                                                        </Box>
                                                        <Box display="flex" alignItems="center" bgcolor="var(--bs-light)">
                                                            <button className="inrbtn">From</button>
                                                            <input
                                                                min={0}
                                                                type="number"
                                                                className="input"
                                                                placeholder={`Enter ${selectSwapCoin?.symbol} Amount`}
                                                                value={swappingAmount}
                                                                onChange={(e) => setSwappingAmount(e.target.value)}
                                                                name="amount"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                            <Box>
                                                                <SelectedOption tokensWithout973={tokensWithout973} tokenType="buy" />
                                                            </Box>
                                                        </Box>
                                                        <Box className="swapExchange" sx={{ position: 'relative' }}>
                                                            <IconButton sx={{ bgcolor: "var(--primaryColor) !important" }}>
                                                                <SwapHorizOutlinedIcon onClick={handleSwapType} sx={{ color: "var(--white)" }} />
                                                            </IconButton>
                                                        </Box>
                                                        <Box bgcolor="var(--bs-light)" display="flex" alignItems="center" justifyContent="space-between">
                                                            <Box paddingX="10px">
                                                                <button className="inrbtn">To</button>
                                                            </Box>
                                                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                                                <Box>
                                                                    <span
                                                                        style={{
                                                                            textAlign: "start",
                                                                            fontSize: 13,
                                                                            alignSelf: "center",
                                                                        }}
                                                                    >
                                                                        {swappingAmount > 0 ? swappingCalculatedResult : ""}
                                                                    </span>
                                                                </Box>
                                                                <button className="inrbtn"><img src={logo} className="" alt="palm logo" style={{ width: '25px' }} /> PALM</button>
                                                            </Box>
                                                        </Box>
                                                        <Box marginTop={"1.5rem"} >
                                                            {loading ? (
                                                                <CircularProgress disableShrink />
                                                            ) : (
                                                                <button className="buyButtons" style={{ background: "var(--primaryColor)", width: '100%' }} onClick={publicToPrivateSwapHandler}>
                                                                    Swap
                                                                </button>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                    <SwapConfirmModel isOpen={isSwapModalOpenConfirm} onClose={handleCloseModal} onConfirm={handleSwapSubmit} confirmData={swapConfirmData} isLoading={loading} />
                                                </Box>
                                            ) : (
                                                <Box>
                                                    <Box bgcolor="var(--body)" border="2px solid var(--bs-light)"
                                                        sx={{
                                                            position: "relative",
                                                            width: { lg: "50%", md: "50%", sm: '100%', xs: "100%" },
                                                            padding: '1.5rem',
                                                            borderRadius: '12px',
                                                            margin: 'auto'
                                                        }}>
                                                        <Box marginBottom="15px" display="flex" alignItems="center" justifyContent="space-between">
                                                            <Typography gutterBottom>Available Balance:</Typography>
                                                            <Typography gutterBottom> {tokenWith973?.balance} PALM</Typography>
                                                        </Box>
                                                        <Box bgcolor="var(--bs-light)" display="flex" alignItems="center" width="100%" >
                                                            <Box>
                                                                <button className="inrbtn">From</button>
                                                            </Box>
                                                            <input min={0} type="number" autoComplete="off" className="input" placeholder={`Enter Amount`} value={saleTokens} onChange={(e) => setSaleTokens(e.target.value)} name="amount" required />
                                                            <button className="inrbtn" >
                                                                <img src={logo} className="" alt="palm logo" style={{ width: '25px' }} />
                                                                PALM</button>
                                                        </Box>

                                                        <Box className="swapExchange" sx={{ position: 'relative' }}>
                                                            <IconButton sx={{ bgcolor: "var(--primaryColor) !important" }} onClick={handleSwapType}>
                                                                <SwapHorizOutlinedIcon sx={{ color: "var(--white)" }} />
                                                            </IconButton>
                                                        </Box>
                                                        <Box bgcolor="var(--bs-light)" display="flex" justifyContent="space-between" alignItems="center" width="100%">
                                                            <Box>
                                                                <button className="inrbtn">To</button>
                                                            </Box>
                                                            <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                                                                <Box paddingLeft="10px">
                                                                    <span style={{ fontSize: 13, }}>
                                                                        {saleTokens > 0 ? saleCalculatedTokens : ""}
                                                                    </span>
                                                                </Box>
                                                                {/*<select value={`${saleReceiveToken.chain_id},${saleReceiveToken.symbol}`} onChange={handleSelectChange} className="coin-selector input">
                                                                        {tokensWithout973.length > 0 ? (
                                                                            tokensWithout973.map((token, index) => (
                                                                                <option key={index} value={`${token.chain_id},${token.symbol}`}>
                                                                                    {token.symbol}
                                                                                </option>
                                                                            ))
                                                                        ) : (
                                                                            <option value="" disabled>
                                                                                No tokens available
                                                                            </option>
                                                                        )}
                                                                    </select>*/}
                                                                <Box>
                                                                    <SelectedOption tokensWithout973={tokensWithout973} tokenType="sell" />
                                                                </Box>
                                                            </Box>
                                                        </Box>

                                                        <Box marginTop={"1.5rem"} >
                                                            {loading ? (
                                                                <CircularProgress disableShrink />
                                                            ) : (
                                                                <button className="buyButtons" style={{ background: "var(--primaryColor)", width: '100%' }} onClick={publicToPrivateSwapHandler}>
                                                                    Swap
                                                                </button>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                    <SwapConfirmModel isOpen={isSwapModalOpenConfirm} onClose={handleCloseModal} onConfirm={handleSaleToken} confirmData={swapConfirmData} isLoading={loading} />
                                                </Box>
                                            )}
                                            {/* <AllTranscationData filterType={3} /> */}
                                        </Box>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
                <SuccessModal isOpen={isModalOpen} onClose={handleCloseModal} modalType="success" responseMessage={modalData} titleText="Transaction Completed!" buttonText="View in Explorer" />
                <CustomLoader loading={loading} />
                <ToastContainer />
            </Box>
        </>
    );
};

export default Buy;
