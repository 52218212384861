import React, { memo, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { callApi, fetchStakeData } from "../../api";
import { useNavigate } from "react-router-dom";
import CustomLoader from "../../CustomLoader/CustomLoader";
import { SuccessModal } from "../../CustomLoader/SuccessModal";


const convertTimestampToYMDMSLEFT = (targetTime) => {
  let day, hour, minute, second;

  const now = Math.floor(Date.now() / 1000);
  let t = targetTime - now;

  if (t < 0) return "0 Second";

  second = t % 60;
  t = Math.floor(t / 60);
  minute = t % 60;
  t = Math.floor(t / 60);
  hour = t % 24;
  t = Math.floor(t / 24);
  day = t;

  // Format the output conditionally to hide days if 0
  let result = `${hour}H: ${minute}M: ${second}S`;
  if (day > 0) {
    result = `${day} days ${result}`;
  }

  return result;
};

const TransactionsData = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const token = useSelector((state) => state.auth.authToken);
  const DataTable1 = useSelector((state) => state.auth.stakeData);

  const [loading, setLoading] = useState(false);
  const [loadingStake, setLoadingStake] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({ title: "", hash: "" });
  const [itemsPerPage] = useState(10);

  const totalItems = DataTable1.length;
  const totalPages = Math.ceil(totalItems / itemsPerPage);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = DataTable1.slice(indexOfFirstItem, indexOfLastItem);

  const convertTimeStampToDate = (depositTime) => {
    const timeStampInMs = parseInt(depositTime) * 1000;
    const date = new Date(timeStampInMs);
    return date.toLocaleDateString();
  };

  const convertTimestampToYMDMS = (t) => {
    let year, month, day, hour, minute, second;

    second = t % 60;
    t = Math.floor(t / 60);
    minute = t % 60;
    t = Math.floor(t / 60);
    hour = t % 24;
    t = Math.floor(t / 24);
    day = t % 30;
    t = Math.floor(t / 30);
    month = t % 12;
    t = Math.floor(t / 12);
    year = t;

    if (year > 0) {
      return `${year} Year${year > 1 ? "s" : ""}`;
    }
    if (month > 0) {
      return `${month} Month${month > 1 ? "s" : ""}`;
    }
    if (day > 0) {
      return `${day} Day${day > 1 ? "s" : ""}`;
    }
    if (hour > 0) {
      return `${hour} Hour${hour > 1 ? "s" : ""}`;
    }
    if (minute > 0) {
      return `${minute} Minute${minute > 1 ? "s" : ""}`;
    }
    if (second > 0) {
      return `${second} Second${second > 1 ? "s" : ""}`;
    }
    return "0 Second"; // If the duration is 0
  };

  const handleClick = async (item, index) => {
    setLoadingStake(index);
    setLoading(true);
    try {
      const header = {
        "content-Type": "application/json",
        "x-access-token": token,
      };

      const body = {
        stakeId: item.id,
      };

      const method = "post";
      const subUri = "/staking/unstake";

      const response = await callApi(header, body, method, subUri);

      if (response.status === 200 || response.status === 201) {
        setModalData({
          title: response?.data?.message,
          hash: response?.data?.hash,
        });
        setIsModalOpen(true);
        await fetchStakeData(dispatch, token);
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message;
      toast.error(errorMessage);
    } finally {
      setLoading(false);
      setLoadingStake("");
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  //handle view details button navigate view details screen
  const handleDetails = async (item) => {
    if (item.depositTime !== item.lastWithdrawTime) {
      navigate("/view-details", { state: item.id });
    } else {
      toast.error("No claim history found");
    }
  };


  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Box>
      <Box
        sx={{
          display: { lg: "flex", md: "flex", sm: "flex" },
          alignItems: "center",
          justifyContent: "space-between",
          marginY: "1rem",
        }}
      >
        {/* Other content */}
      </Box>

      <Box className="overflow-x">
        <table className="table">
          <thead>
            <tr>
              <th style={{ minWidth: "70px" }}>ID No</th>
              <th style={{ minWidth: "150px" }}>Amount (PALM)</th>
              <th style={{ minWidth: "90px" }}>APR</th>
              <th style={{ minWidth: "150px" }}>Rewards</th>
              <th style={{ minWidth: "150px" }}>Deposit Time</th>
              <th style={{ minWidth: "150px" }}>Unstaking Time</th>
              <th style={{ minWidth: "140px" }}>Plan Duration</th>
              <th>UnStake</th>
              <th style={{ minWidth: "140px" }}>View Details</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => {
              const isStatus = item.status;
              const startingIndex = (currentPage - 1) * itemsPerPage;
              const depositTime = item.depositTime;
              const duration = item.duration;
              const durationInSeconds = parseInt(duration, 10);
              const unstakingTime = depositTime + durationInSeconds;
              const now = Math.floor(Date.now() / 1000);
              const isButtonDisabled = unstakingTime - now > 0 ? true : false;
              return (
                <tr key={index}>
                  <th scope="row">
                    <Box fontWeight="400" fontSize="14px">{startingIndex + index + 1}</Box>
                  </th>
                  <td><Box fontWeight="400" fontSize="14px">{parseFloat(item.amount)}</Box></td>
                  <td><Box fontWeight="400" fontSize="14px">{item.apy}%</Box></td>
                  <td><Box fontWeight="400" fontSize="14px">{item.rewards_amount}</Box></td>
                  <td><Box fontWeight="400" fontSize="14px">{convertTimeStampToDate(item.depositTime)}</Box></td>
                  <td><Box fontWeight="400" fontSize="14px">{convertTimestampToYMDMSLEFT(unstakingTime)}</Box></td>
                  <td><Box fontWeight="400" fontSize="14px">{convertTimestampToYMDMS(item.duration)}</Box></td>
                  <td>
                    {!isButtonDisabled && isStatus === 0 ? (
                      <button
                        className="Viewbtn"
                        disabled={isButtonDisabled}
                        onClick={() => handleClick(item, index)}
                        style={{
                          width: loadingStake === index ? 70 : undefined,
                          justifyContent: "center",
                        }}
                      >
                        {loadingStake === index ? (
                          <CircularProgress size={20} color="inherit" />
                        ) : (
                          "UnStake"
                        )}
                      </button>
                    ) : (
                      <Box fontWeight="400" fontSize="14px" className={(!isButtonDisabled && isStatus == 0) ? "" : isStatus === 0 ? "Runninggreen" : "Completedred"}>
                        {isStatus === 0 ? "Running" : "Completed"}
                      </Box>
                    )}
                  </td>


                  <td>
                    <button
                      className="Viewbtn"
                      onClick={() => handleDetails(item)}
                      style={{
                        width: loadingStake === index ? 100 : null,
                        justifyContent: "center",
                      }}
                    >
                      View details
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </Box>
      <SuccessModal isOpen={isModalOpen} onClose={handleCloseModal} modalType="success" responseMessage={modalData} titleText="Transaction Completed!" buttonText="View in Explorer" />
      <CustomLoader loading={loading} />

      <Box>
        <ul className="pagination">
          {totalItems <= 10 ? null : (
            <>
              {currentPage !== 1 && (
                <li className="page-item">
                  <button
                    onClick={() => handlePageChange(currentPage - 1)}
                    className="page-link"
                  >
                    Previous
                  </button>
                </li>
              )}
              {pageNumbers
                .slice(currentPage - 1, currentPage + 2)
                .map((number) => (
                  <li key={number} className="page-item">
                    <button
                      onClick={() => handlePageChange(number)}
                      className="page-link"
                    >
                      {number}
                    </button>
                  </li>
                ))}
              {currentPage !== totalPages && (
                <li className="page-item">
                  <button
                    onClick={() => handlePageChange(currentPage + 1)}
                    className="page-link"
                  >
                    Next
                  </button>
                </li>
              )}
            </>
          )}
        </ul>
      </Box>
    </Box>
  );
};

export default memo(TransactionsData);
