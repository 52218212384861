import { Box, Checkbox, Container, Grid, Select, Typography } from "@mui/material";
import React, { useMemo, useState } from "react";
import signLogo from "../assets/logo/Palm-Logo.png";
// import facebook from '../assets/facebook.png'
import google from "../assets/google-logo.png";
import { Link, useNavigate } from "react-router-dom";
import TelegramIcon from "@mui/icons-material/Telegram";
import FavoriteIcon from "@mui/icons-material/Favorite";
import { ToastContainer, toast } from "react-toastify";
import { callApi } from "../../api";
import { useDispatch } from "react-redux";
import { setUserId } from "../../redux/slices/authSlice";
import countryCode from "../../utils/Country.json";
import "react-toastify/dist/ReactToastify.css";

function getReferralCode() {
    const fullUrl = window.location.href;
    const url = new URL(fullUrl);
    const params = new URLSearchParams(url.search);
    const referralCode = params.get("referralCode");
    return referralCode;
}

const referralCode = getReferralCode();

const SignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [isValidPassword, setIsValidPassword] = useState(true);
    const handleChange = (event) => {
        const { value } = event.target;
        setEmail(value);
        setIsValid(validateEmail(value));
    };

    const handleChangePassword = (event) => {
        const { value } = event.target;
        setPassword(value);
        setIsValidPassword(validatePassword(value));
    };

    const validateEmail = (email) => {
        const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return regex.test(email);
    };

    const validatePassword = (password) => {
        const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
        return regex.test(password);
    };

    const validatePhone = (phone) => {
        const phoneRegex = /^[1-9]\d{7,}$/;
        return phoneRegex.test(phone);
    };

    const [loader, setLoader] = useState(false);

    const signupSubmit = async (e) => {
        e.preventDefault();
        const fullname = e.target.fullname.value;
        const email = e.target.email.value;
        let phoneno = e.target.phoneno.value;
        const password = e.target.password.value;
        const refferalTo = e.target.refferalTo.value;
        const confirmPassword = e.target.confirmPassword.value;
        if (!validateEmail(email)) return toast.error("Email is not valid");
        // if (!validateEmail(selectedCountry)) return toast.error("Select cuntry code first");
        if (!validatePhone(phoneno)) return toast.error("Phone number is not valid");
        if (!validatePassword(password)) return toast.error("Password is weak");
        if (password !== confirmPassword) return toast.error("Password is not matched");
        setLoader(true);
        try {
            const phoneNumber = `${selectedCountry}${phoneno}`;
            const header = {
                "content-Type": "application/json",
                // 'x-access-token': token
            };
            const body = {
                email,
                password,
                phoneno: phoneno,
                fullname,
                refferalTo,
                country_code: selectedCountry,
            };
            const method = "post";
            const subUri = "/user/register";

            const response = await callApi(header, body, method, subUri);
            if (response.status == 200 || response.status == 201) {
                const message = response?.data?.message;
                toast.success(message);
                const userId = response?.data?.data;
                dispatch(setUserId(userId));
                navigate("/verification");
            }
        } catch (error) {
            const errorMessage = error?.response?.data?.error ? error?.response?.data?.error : error?.response?.data?.message;
            toast.error(errorMessage);
        } finally {
            setLoader(false);
        }
    };

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const [selectedCountry, setSelectedCountry] = useState("+973");

    // Change handler to update selected value
    const changeHandler = (event) => {
        setSelectedCountry(event.target.value);
    };

    //   const count = countryCode.countries.map((ele)=>{
    //     return <select>{ele}</select>
    //     console.log(ele, "ddfdf")
    //   })

    return (
        <>
            <form onSubmit={signupSubmit}>
                <Box className="SignUpContainer" height={"100%"}>
                    <Container maxWidth="xl">
                        <Grid container>
                            <Grid lg="4" md="4" sm="12" xs="12" margin={"auto"}>
                                <Box position="relative" bgcolor={"var(--white)"} zIndex="9" borderRadius={"1rem"} padding={"2rem"} boxShadow={"0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"}>
                                    <Box textAlign={"center"} marginBottom={"1rem"}>
                                        <Box>
                                            <img src={signLogo} alt="" width={"120px"} />
                                        </Box>
                                        <Box>
                                            <Typography variant="h5" fontWeight={"600"} color={"var(--body)"}>
                                                Create Your Account
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box>
                                            <Box
                                                sx={{
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <Typography variant="body" gutterBottom color={"var(--borderright)"}>
                                                    Name
                                                </Typography>
                                                <input placeholder="Enter full name" type="text" className="textInput" required name="fullname" />
                                            </Box>
                                            <Box
                                                sx={{
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <Typography variant="body" gutterBottom color={"var(--borderright)"}>
                                                    Email
                                                </Typography>
                                                <input onChange={handleChange} placeholder="Enter email" type="email" className="textInput" name="email" required />
                                                {!isValid && email.length > 0 && <span style={{ color: "#e82149" }}>Invalid email format</span>}
                                            </Box>
                                        </Box>
                                        <Box>
                                            <Box
                                                sx={{
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <Typography variant="body" gutterBottom color={"var(--borderright)"}>
                                                    Phone Number
                                                </Typography>
                                                <Box display="flex" alignItems="center" gap="10px">
                                                    <select onChange={changeHandler} value={selectedCountry} className="selectContRow">
                                                        <option value="+973">+973 Bahrain</option>
                                                        {countryCode.countries.map((country, index) => (
                                                            <option key={index} value={country.code}>
                                                                {country.code} {country.name}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <input placeholder="Enter phone number" type="text" maxLength="13" className="textInput" name="phoneno" required />
                                                </Box>
                                            </Box>
                                            <Box
                                                sx={{
                                                    marginBottom: "10px",
                                                }}
                                            >
                                                <Typography variant="body" gutterBottom color={"var(--borderright)"}>
                                                    Password
                                                </Typography>
                                                <input onChange={handleChangePassword} placeholder="Enter password" type="password" className="textInput" name="password" required />
                                                {!isValidPassword && password.length > 0 && <span style={{ color: "#e82149" }}>Paasword is weak</span>}
                                            </Box>
                                        </Box>
                                        <Box
                                            sx={{
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <Typography variant="body" gutterBottom color={"var(--borderright)"}>
                                                Confirm Password
                                            </Typography>
                                            <input placeholder="Enter confirm password" type="password" className="textInput" name="confirmPassword" required />
                                        </Box>
                                        <Box
                                            sx={{
                                                marginBottom: "10px",
                                            }}
                                        >
                                            <Typography variant="body" gutterBottom color={"var(--borderright)"}>
                                                Referral Code (Optional)
                                            </Typography>
                                            <input placeholder="Enter Referral id" type="" className="textInput" name="refferalTo" value={referralCode} />
                                        </Box>
                                        <Box
                                            sx={{
                                                marginBottom: "10px",
                                            }}
                                            color={"var(--borderright)"}
                                        >
                                            <Checkbox color="success" sx={{ color: "var(--whiet)" }} onClick={handleCheckboxChange} checked={isChecked} required />
                                            <Typography variant="body" gutterBottom color={"var(--borderright)"}>
                                                By registering you agree to the Palm Smart Chain <span style={{ color: "var(--primaryColor)" }}>Terms of Use</span>
                                            </Typography>
                                        </Box>
                                    </Box>
                                    <Box>
                                        <Box sx={{ width: { md: "100%" }, margin: "auto" }}>
                                            <button className="Continue">
                                                <TelegramIcon /> Register Now
                                            </button>
                                        </Box>

                                        <Box textAlign={"center"}>
                                            <Typography fontWeight={"500"} color={"var(--borderright)"}>
                                                Already have an account?
                                                <Link
                                                    to="/login"
                                                    style={{
                                                        textDecoration: "none",
                                                        color: "var(--primaryColor)",
                                                        marginLeft: "5px",
                                                    }}
                                                >
                                                    Login
                                                </Link>
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </form>
            {/* <ToastContainer /> */}
        </>
    );
};

export default SignUp;
