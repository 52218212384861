import { Box, Checkbox, CircularProgress, Container, FormControlLabel, Grid, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import logo from "../assets/logo/Palm-Logo.png";
import { Link, useNavigate } from "react-router-dom";
// import facebook from '../assets/facebook.png'
// import google from '../assets/google-logo.png'
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import loginImg from "../assets/gfx-d-dark.png";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/css/pagination";
import { jwtDecode } from "jwt-decode";
import axios from "axios";
import { GoogleOAuthProvider, GoogleLogin } from "@react-oauth/google";
// import { loginSuccess } from '../../redux/slices/authSlice';
import { useDispatch } from "react-redux";
import { loginSuccess, setUserId } from "../../redux/slices/authSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callApi } from "../../api";


const LogedIn = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isValidPassword, setIsValidPassword] = useState(true);

  const validateEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    const phoneRegex = /^\+[1-9]\d{7,}$/;

    const isEmail = emailRegex.test(email);
    const isPhoneNumber = phoneRegex.test(email);
    return isEmail || isPhoneNumber;
  };
  const validatePassword = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setEmail(value);
    // setIsValid(validateEmail())
    setIsValid(validateEmail(value));
    if (validateEmail(value)) setIsButtonDisabled(false);
  };

  const handleChangePassword = (event) => {
    const { value } = event.target;
    setPassword(value);
    setIsValidPassword(validatePassword(value));
    if (validatePassword(value)) setIsButtonDisabled(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isValid && email.length > 0) return toast.error("Enter valid Email or Phone Number");
    // if (!isValidPassword && password.length > 0) return toast.error("Password is required");
    try {
      if (email && password) {
        const header = {
          "content-Type": "application/json",
          // 'x-access-token': token
        };
        const body = { email, password, loginType: "", fullname: "" };
        const method = "post";
        const subUri = "/user/login";

        const response = await callApi(header, body, method, subUri);
        if (response.status == 200 || response.status == 201) {
          if (response?.data?.verified === true) {
            const userId = response?.data?.userId;
            dispatch(setUserId(userId));
            // navigate(`/verification`);
            toast.success("login successfully");
            navigate("/verification", { state: email });
          } else {
            toast.warn("Check your mail first for device verification!");
          }
        }
      } else {
        toast.error("email and password is required");
      }
    } catch (error) {
      const errorMessage = error.response.data.error || error?.response?.data?.message || error.message || "unknown error";
      toast.error(errorMessage);
    }
  };

  const [showPassword, setShowPassword] = useState("password");

  const PasswordVisibility = () => {
    setShowPassword(showPassword === "text" ? "password" : "text");
  };
  const [loading, setLoading] = useState(false);
  const handleGoogleLogin = async (credentialResponse) => {
    setLoading(true);
    const decode = await jwtDecode(credentialResponse.credential);
    const email = decode.email;
    const googleName = decode.name;
    const loginType = "google";
    new Promise((resolve) => setTimeout(resolve, 2000));
    try {
      if (email && googleName && loginType === "google") {
        const header = {
          "content-Type": "application/json",
          // 'x-access-token': token
        };
        const body = {
          email: email,
          password: "",
          loginType: loginType,
          fullname: googleName,
        };
        const method = "post";
        const subUri = "/user/login";

        const response = await callApi(header, body, method, subUri);
        if (response.status == 200 || response.status == 201) {
          if (response?.data?.verified === true) {
            const userId = response.data.message;
            dispatch(setUserId(userId));
            // navigate(`/verification`);
            await toast.success("login successfully");
            // navigate("/verification", { state: email });
          } else {
            toast.warn("Check your mail first for device verification!");
          }
        }
      } else {
        toast.error("email and password is required");
      }
    } catch (error) {
      const errorMessage = error?.response?.data?.error;
      toast.error(errorMessage);
    } finally {
      setLoading(false);
    }
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    setIsButtonDisabled(false);
  };


  return (
    <Box className="LoginContainer" sx={{ paddingY: { md: "1rem" } }}>
      <Container maxWidth="xl">
        <Grid container alignItems={"center"}>
          <Grid item lg="4" md="4" sm="12" xs="12" margin={"auto"}>
            <Box position="relative" bgcolor={"var(--white)"} zIndex="9" borderRadius={'1rem'} padding={'2rem'} boxShadow={'0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)'}>
              <Box
                marginBottom={"1rem"}
                sx={{ textAlign: 'center' }}
              >
                <Link to="/">
                  <img src={logo} alt="" width="120" />
                </Link>
              </Box>
              <Box paddingBottom={"1rem"} textAlign={'center'}>
                <Typography
                  variant="h4"
                  fontWeight={"600"}
                  color={"var(--primaryColor)"}
                  fontFamily={"var(--Poppinsfont)"}
                >
                  Welcome Back!
                </Typography>
                <Typography variant="caption" fontWeight={"400"} color={"var(--borderright)"}>
                  Sign in to Continue to Palm Smart Chain.
                </Typography>
              </Box>
              <form onSubmit={handleSubmit}>
                <Box marginBottom={"1.5rem"}>
                  <Box marginBottom={"10px"}>
                    <Typography
                      variant="body"
                      gutterBottom
                      color={"var(--borderright)"}
                    >
                      Username
                    </Typography>
                    <input
                      placeholder="Enter username or phone number"
                      type="text"
                      className="textInput"
                      onChange={handleChange}
                      name="email"
                    />

                    {!isValid && email.length > 0 && (
                      <span style={{ color: "#e82149" }}>
                        Enter valid Email or Phone Number
                      </span>
                    )}
                  </Box>
                  <Box>
                    <Box
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Typography
                        variant="body"
                        gutterBottom
                        color={"var(--borderright)"}
                      >
                        Password
                      </Typography>
                      <Link
                        to="/forget-password"
                        style={{ textDecoration: "none" }}
                      >
                        <Typography variant="body" color={"var(--borderright)"}>
                          Forgot password?
                        </Typography>
                      </Link>
                    </Box>
                    <Box>
                      <Box position={"relative"}>
                        <input
                          placeholder="Enter password"
                          type={showPassword}
                          className="textInput"
                          onChange={handleChangePassword}
                          name="password"
                          required
                        />
                        <Box className="IconButtonEye">
                          <IconButton onClick={PasswordVisibility}>
                            {showPassword === "text" ? (
                              <VisibilityIcon
                                sx={{ color: "var(--primaryColor)" }}
                              />
                            ) : (
                              <VisibilityOffIcon
                                sx={{ color: "var(--primaryColor)" }}
                              />
                            )}
                          </IconButton>
                        </Box>
                      </Box>

                    </Box>
                  </Box>
                  <Box color={"var(--borderright)"}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isChecked}
                          onChange={handleCheckboxChange}
                          color="primary"
                          sx={{ color: "var(--borderright)" }}
                          required
                        />
                      }
                      label="Remember me"
                    />
                  </Box>
                </Box>
                <Box>
                  <button disabled={isButtonDisabled} className="Continue">Log In</button>
                </Box>
              </form>

              <Box
                display="none"
                alignItems={"center"}
                justifyContent={"center"}
                gap={"15px"}
                paddingBottom={"1rem"}
              >
                {/* <Box> 
                                <img src={facebook} alt='' width={35} />  
                            </Box> */}
                <Box>
                  {/* <img src={google} alt='' width={35} />   */}
                  {loading ? (
                    <>
                      <CircularProgress size={20} color="inherit" />
                    </>
                  ) : (
                    <GoogleOAuthProvider clientId="224904703954-1ij1s8oo39ktlb9sjoejluojcutpnm0l.apps.googleusercontent.com">
                      <GoogleLogin
                        onSuccess={handleGoogleLogin}
                        onError={() => console.log("Google login failed")}
                      />
                    </GoogleOAuthProvider>
                  )}
                </Box>
              </Box>
              <Box textAlign={"center"}>
                <Typography fontWeight={"500"} color={"var(--borderright)"}>
                  Don't have an account?&nbsp;
                  <Link
                    to="/sign-up"
                    style={{
                      textDecoration: "none",
                      color: "var(--primaryColor)",
                    }}
                  >
                    Sign Up
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
      {/* <ToastContainer /> */}
    </Box>
  );
};

export default LogedIn;
