import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import signLogo from "../assets/logo/Palm-Logo.png";
import { useNavigate } from "react-router-dom";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import { useDispatch, useSelector } from "react-redux";
import { loginSuccess, setAuthToken, setUserDetails } from "../../redux/slices/authSlice";
import { callApi } from "../../api";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Verification = () => {
    const navigate = useNavigate();
    const userId = useSelector((state) => state.auth.userId);
    const dispatch = useDispatch();

    // State for OTPs
    const [emailOtp, setEmailOtp] = useState(["", "", "", "", "", ""]);
    const [phoneOtp, setPhoneOtp] = useState(["", "", "", "", "", ""]);

    const handleemailChange = (e, i) => {
        let { value } = e.target;
        value = value.replace(/\D/g, "");
        const newOtp = [...emailOtp];
        newOtp[i] = value;
        setEmailOtp(newOtp);
    };
    const handlePhoneChange = (e, i) => {
        let { value } = e.target;
        value = value.replace(/\D/g, "");
        const newOtp = [...phoneOtp];
        newOtp[i] = value;
        setPhoneOtp(newOtp);
    };

    const handleemailKeyDown = (e, i) => {
        const prevInput = e.target.previousSibling;
        const nextInput = e.target.nextSibling;

        if (e.key === "Backspace" && i === 0) {
        } else if (e.key === "Backspace" && e.target.value === "") {
            e.preventDefault();
            if (prevInput) {
                prevInput.focus();
            }
        } else if (e.key === "Backspace" && i > 0 && e.target.value !== "") {
        } else if (i < emailOtp.length - 1 && e.target.value !== "") {
            if (nextInput) {
                nextInput.focus();
            }
        }
    };
    const handlephoneKeyDown = (e, i) => {
        const prevInput = e.target.previousSibling;
        const nextInput = e.target.nextSibling;

        if (e.key === "Backspace" && i === 0) {
        } else if (e.key === "Backspace" && e.target.value === "") {
            e.preventDefault();
            if (prevInput) {
                prevInput.focus();
            }
        } else if (e.key === "Backspace" && i > 0 && e.target.value !== "") {
        } else if (i < phoneOtp.length - 1 && e.target.value !== "") {
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    // const handleSubmit = async (e) => {
    //     e.preventDefault();
    //     const formattedEmailOtp = emailOtp.join("");
    //     const formattedPhoneOtp = phoneOtp.join("");
    //     if (!formattedEmailOtp || !formattedPhoneOtp) {
    //         toast.error("Both OTPs are required");
    //         return;
    //     }
    //     try {
    //         // if (emailOtp && userId) {
    //         const header = {
    //             "content-Type": "application/json",
    //             // 'x-access-token': token
    //         };
    //         const body = { otp: formattedPhoneOtp, userId, email_otp: formattedEmailOtp };
    //         const method = "post";
    //         const subUri = "/user/verify";

    //         const response = await callApi(header, body, method, subUri);
    //         if (response.status == 200 || response.status == 201) {
    //             const user = response.data;
    //             toast.success("User verified successfully");
    //             dispatch(setUserDetails(user.result.user));
    //             dispatch(loginSuccess());
    //             localStorage.setItem("isLoggedIn", true);
    //             localStorage.setItem("token", user.result.token);
    //             dispatch(setAuthToken(user.result.token));
    //             navigate("/");
    //         }

    //         // } else {
    //         //   toast.error("OTP is required");
    //         // }
    //     } catch (error) {
    //         const errorMessage = error?.response?.data?.error || error?.response?.data?.message || error?.message || "unknown error";
    //         toast.error(errorMessage);
    //     }
    // };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formattedEmailOtp = emailOtp.join("");
        const formattedPhoneOtp = phoneOtp.join("");
        if (!formattedEmailOtp || !formattedPhoneOtp) {
            toast.error("Both OTPs are required");
            return;
        }
        try {
            const header = {
                "content-Type": "application/json",
            };
            const body = { otp: formattedPhoneOtp, userId, email_otp: formattedEmailOtp };
            const method = "post";
            const subUri = "/user/verify";

            const response = await callApi(header, body, method, subUri);
            if (response.status === 200 || response.status === 201) {
                const user = response.data;
                toast.success("User verified successfully");
                dispatch(setUserDetails(user.result.user));
                dispatch(loginSuccess());
                localStorage.setItem("isLoggedIn", true);
                localStorage.setItem("token", user.result.token);
                dispatch(setAuthToken(user.result.token));
                navigate("/");
            } else {
                toast.error("Unexpected response status: " + response.status);
            }
        } catch (error) {
            console.error("Submit error:", error); // Log error for debugging
            const errorMessage = error?.response?.data?.error || error?.response?.data?.message || error?.message || "unknown error";
            toast.error(errorMessage);
        }
    };


    return (
        <>
            <Box className="SignUpContainer">
                <Container maxWidth="xl">
                    <Grid container>
                        <Grid item lg={4} md={4} sm={12} xs={12} margin="auto">
                            <form onSubmit={handleSubmit}>
                                <Box>
                                    <Box textAlign="center">
                                        <img src={signLogo} alt="Palm Logo" width="120px" />
                                        <Typography variant="h5" fontWeight="600" color="var(--bs-header-bg)" textTransform="capitalize">
                                            Verification for Palm Smart Chain.
                                        </Typography>
                                    </Box>

                                    {/* Email OTP Verification */}
                                    <Box position="relative" bgcolor="var(--white)" zIndex="9" borderRadius="1rem" padding="1.5rem" boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)" marginY="1rem">
                                        <Box display="flex" alignItems="center" gap="10px" marginBottom="1rem">
                                            <Box className="EmailIcons" textAlign="center">
                                                <EmailIcon sx={{ color: "var(--primaryColor)", fontSize: 30 }} />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" color="var(--bs-header-bg)" fontWeight="500">
                                                    Verify your email
                                                </Typography>
                                                <Typography variant="body2" color="var(--textmuted)" fontWeight="400">
                                                    Please Enter the One-Time Password to verify your account?
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                                            {emailOtp.map((value, index) => (
                                                <input
                                                    key={`email-otp-${index}`}
                                                    type="text"
                                                    className="textInput inputOTP"
                                                    maxLength="1"
                                                    value={value}
                                                    onChange={(e) => handleemailChange(e, index, "email")}
                                                    onKeyDown={(e) => handleemailKeyDown(e, index, "email")}
                                                    required
                                                />
                                            ))}
                                        </Box>
                                    </Box>

                                    {/* Phone OTP Verification */}
                                    <Box position="relative" bgcolor="var(--white)" zIndex="9" borderRadius="1rem" padding="1.5rem" boxShadow="0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)">
                                        <Box display="flex" alignItems="center" gap="10px" marginBottom="1rem">
                                            <Box className="EmailIcons" textAlign="center">
                                                <PhoneIcon sx={{ color: "var(--primaryColor)", fontSize: 30 }} />
                                            </Box>
                                            <Box>
                                                <Typography variant="h6" color="var(--bs-header-bg)" fontWeight="500">
                                                    Verify your Number
                                                </Typography>
                                                <Typography variant="body2" color="var(--textmuted)" fontWeight="400">
                                                    Please Enter the One-Time Password to verify your account?
                                                </Typography>
                                            </Box>
                                        </Box>
                                        <Box sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                                            {phoneOtp.map((value, index) => (
                                                <input
                                                    key={`phone-otp-${index}`}
                                                    type="text"
                                                    className="textInput inputOTP"
                                                    maxLength="1"
                                                    value={value}
                                                    onChange={(e) => handlePhoneChange(e, index, "phone")}
                                                    onKeyDown={(e) => handlephoneKeyDown(e, index, "phone")}
                                                    required
                                                />
                                            ))}
                                        </Box>
                                    </Box>

                                    {/* Confirm Button */}
                                    <Box sx={{ width: "100%", marginTop: "1rem", textAlign: "center" }}>
                                        <button className="Continue" style={{ background: "var(--primaryColor)" }}>
                                            Confirm
                                        </button>
                                    </Box>
                                </Box>
                            </form>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
        </>
    );
};

export default Verification;
