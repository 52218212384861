import { Box, Container, Grid, Typography } from '@mui/material'
import React, { useState } from 'react'
import DevicesOtherIcon from '@mui/icons-material/DevicesOther';
import { useNavigate, useParams } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";
import { toast } from 'react-toastify';
import { callApi } from '../../api';


const DeviceVerification = () => {
    const navigate = useNavigate();
    const { token } = useParams();
    const [phoneOtp, setPhoneOtp] = useState(["", "", "", "", "", ""]);

    try {
        const user = jwtDecode(token);
        const { id, ipAddress } = user;

    } catch (error) {
    }

    async function deviceVerification(e) {
        e.preventDefault();
        const formattedPhoneOtp = phoneOtp.join("");
        if (!formattedPhoneOtp) {
            toast.error("OTP is required");
            return;
        }
        try {
            // if (emailOtp && userId) {
            const header = {
                "content-Type": "application/json",
                'x-access-token': token
            };
            const body = { otp: formattedPhoneOtp };
            const method = "post";
            const subUri = "/user/verify-device";

            const response = await callApi(header, body, method, subUri);
            if (response.status == 200 || response.status == 201) {
                const user = response.data;
                toast.success(user.message);
                // Redirect to login page after 1 second
                setTimeout(() => {
                    navigate("/login");
                }, 1000);
            }
        } catch (error) {
            const errorMessage = error.response.data.error || error.response.data.message || error.message || "unknown error";
            toast.error(errorMessage);
        }

    }

    const handlePhoneChange = (e, i) => {
        let { value } = e.target;
        value = value.replace(/\D/g, "");
        const newOtp = [...phoneOtp];
        newOtp[i] = value;
        setPhoneOtp(newOtp);
    };

    const handlephoneKeyDown = (e, i) => {
        const prevInput = e.target.previousSibling;
        const nextInput = e.target.nextSibling;

        if (e.key === "Backspace" && i === 0) {
        } else if (e.key === "Backspace" && e.target.value === "") {
            e.preventDefault();
            if (prevInput) {
                prevInput.focus();
            }
        } else if (e.key === "Backspace" && i > 0 && e.target.value !== "") {
        } else if (i < phoneOtp.length - 1 && e.target.value !== "") {
            if (nextInput) {
                nextInput.focus();
            }
        }
    };

    return (
        <Box className="SignUpContainer">
            <Container maxWidth="xl">
                <Grid container>
                    <Grid lg="5" md="5" sm="12" xs="12" margin={"auto"}>
                        <Box marginTop="3rem">
                            <form method='post' onSubmit={deviceVerification}>
                                <Box position="relative" bgcolor={"var(--white)"} zIndex="9" borderRadius={"1rem"} padding={"1.5rem"} boxShadow={"0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"}>
                                    <Box marginBottom="1rem" display="flex" alignItems="center" gap="10px" margin Bottom="1rem">
                                        <Box className="EmailIcons">
                                            <DevicesOtherIcon sx={{ color: "var(--primaryColor)", fontSize: 30 }} />
                                        </Box>
                                        <Box>
                                            <Typography variant="h5" color={"var(--body)"} fontWeight={"500"}>
                                                Device Verification
                                            </Typography>
                                            <Typography variant="body2" color={"var(--textmuted)"} textAlign={"center"} gutterBottom>
                                                Please enter the 6 digit code send to example@abc.com
                                            </Typography>
                                        </Box>

                                    </Box>
                                    <Box sx={{ display: "flex", justifyContent: "center", gap: "10px" }}>
                                        {phoneOtp.map((value, index) => (
                                            <input
                                                key={`phone-otp-${index}`}
                                                type="text"
                                                className="textInput inputOTP"
                                                maxLength="1"
                                                value={value}
                                                onChange={(e) => handlePhoneChange(e, index, "phone")}
                                                onKeyDown={(e) => handlephoneKeyDown(e, index, "phone")}
                                                required
                                            />
                                        ))}
                                    </Box>
                                    <Box sx={{ width: { md: "100%" }, margin: "auto" }}>
                                        <button className="Continue" style={{ background: "var(--primaryColor)" }}>
                                            Comfirm
                                        </button>
                                    </Box>
                                </Box>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}

export default DeviceVerification