import React, { useMemo, useState } from 'react';
import { useTable, usePagination, useFilters } from 'react-table';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as XLSX from 'xlsx';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const DataTable = ({ columns, data }) => {
    const [searchInput, setSearchInput] = useState('');

    const filteredData = useMemo(() => {
        if (!searchInput) return data;
        return data.filter(row =>
            Object.values(row).some(value =>
                value.toString().toLowerCase().includes(searchInput.toLowerCase())
            )
        );
    }, [searchInput, data]);

    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize }
    } = useTable(
        {
            columns,
            data: filteredData,
            initialState: { pageIndex: 0, pageSize: 5 }
        },
        useFilters, // Use filters to allow search functionality
        usePagination
    );

    const exportToExcel = () => {
        try {
            const ws = XLSX.utils.json_to_sheet(filteredData);
            const wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
            XLSX.writeFile(wb, 'data.xlsx');
        } catch (error) {
            console.error('Error exporting to Excel:', error);
        }
    };

    const exportToPDF = () => {
        try {
            const doc = new jsPDF();
            doc.autoTable({ html: '#data-table' });
            doc.save('data.pdf');
        } catch (error) {
            console.error('Error exporting to PDF:', error);
        }
    };

    return (
        <>
            <div style={{ marginBottom: '10px' }}>
                <button onClick={exportToExcel}>Export to Excel</button>
                <button onClick={exportToPDF}>Export to PDF</button>
                <CopyToClipboard text={JSON.stringify(filteredData)}>
                    <button>Copy to Clipboard</button>
                </CopyToClipboard>
            </div>
            <div style={{ marginBottom: '10px' }}>
                <input
                    type="text"
                    value={searchInput}
                    onChange={e => setSearchInput(e.target.value)}
                    placeholder="Search..."
                    style={{ padding: '8px', width: '100%' }}
                />
            </div>
            <table {...getTableProps()} id="data-table" style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()} style={{ border: '1px solid black', padding: '8px' }}>
                                    {column.render('Header')}
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map(row => {
                        prepareRow(row);
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map(cell => (
                                    <td {...cell.getCellProps()} style={{ border: '1px solid black', padding: '8px' }}>
                                        {cell.render('Cell')}
                                    </td>
                                ))}
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div style={{ marginTop: '10px' }}>
                <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                    {'<<'}
                </button>{' '}
                <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                    {'<'}
                </button>{' '}
                <button onClick={() => nextPage()} disabled={!canNextPage}>
                    {'>'}
                </button>{' '}
                <button onClick={() => gotoPage(pageOptions.length - 1)} disabled={!canNextPage}>
                    {'>>'}
                </button>{' '}
                <span>
                    Page{' '}
                    <strong>
                        {pageIndex + 1} of {pageOptions.length}
                    </strong>{' '}
                </span>
                <select
                    value={pageSize}
                    onChange={e => {
                        setPageSize(Number(e.target.value));
                    }}
                >
                    {[10, 20, 30, 40, 50].map(pageSize => (
                        <option key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </option>
                    ))}
                </select>
            </div>
        </>
    );
};

export default DataTable;
