import React, { useEffect, useRef, useState } from 'react';
import './StockTicker.css'; // Import your CSS file for styling

const StockTicker = () => {
  const [stocks, setStocks] = useState([]);
  const tickerRef = useRef(null);
  // Simulate fetching stock data
  const fetchStockData = () => {
    // Replace this with your API call to fetch stock data
    const stockData = [
        { symbol: 'AAPL', price: 145.09, change: +1.23, type: 'stock' },
        { symbol: 'GOOGL', price: 2731.23, change: -15.67, type: 'stock' },
        { symbol: 'AMZN', price: 3345.00, change: +25.12, type: 'stock' },
        { symbol: 'BTC', price: 40000.00, change: +1500.50, type: 'crypto' },
        { symbol: 'ETH', price: 2800.00, change: -20.00, type: 'crypto' },
        { symbol: 'BNB', price: 300.00, change: +10.00, type: 'crypto' },
        { symbol: 'USDT', price: 1.00, change: 0.00, type: 'crypto' },
        { symbol: 'XRP', price: 0.80, change: +0.05, type: 'crypto' },
        { symbol: 'ADA', price: 1.20, change: -0.02, type: 'crypto' },
        { symbol: 'SOL', price: 30.50, change: +1.00, type: 'crypto' },
        { symbol: 'DOT', price: 6.80, change: +0.10, type: 'crypto' },
        { symbol: 'LTC', price: 150.00, change: -5.00, type: 'crypto' },
        { symbol: 'LINK', price: 25.00, change: +0.50, type: 'crypto' },
        { symbol: 'MATIC', price: 1.50, change: +0.03, type: 'crypto' },
      // Add more stocks as needed
    ];
    setStocks(stockData);
  };

  useEffect(() => {
    fetchStockData();
    const interval = setInterval(fetchStockData, 10000); // Fetch new data every 10 seconds
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="stock-ticker">
    <div className="ticker-wrapper">
      {/* Duplicate stocks to create an infinite scroll effect */}
      {stocks.concat(stocks).map((stock,index) => (
        <div key={index} id={`stock-item-${stock.symbol}-${index}`} className="stock-item">
          <span className="stock-symbol">{stock.symbol}</span>
          <span className="stock-price">${stock.price.toFixed(2)}</span>
          <span className={`stock-change ${stock.change >= 0 ? 'up' : 'down'}`}>
            {stock.change >= 0 ? '+' : ''}
            {stock.change.toFixed(2)}%
          </span>
        </div>
      ))}
    </div>
  </div>
  );
};

export default StockTicker;
