import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import signLogo from "../assets/logo/Palm-Logo.png";
import { Link, useNavigate } from "react-router-dom";
import FavoriteIcon from "@mui/icons-material/Favorite";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { callApi } from "../../api";

const ForgetPassword = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [isValid, setIsValid] = useState(true);

    const validateEmail = (email) => {
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
        // const allowedProviders = ['gmail.com', 'yahoo.com', 'outlook.com'];
        // const emailProvider = email.toLowerCase().split('@')[1];
        const phoneRegex = /^\+[1-9]\d{7,}$/;

        // const isEmail = emailRegex.test(email) && allowedProviders.includes(emailProvider);
        const isEmail = emailRegex.test(email);
        const isPhoneNumber = phoneRegex.test(email);
        return isEmail || isPhoneNumber;
    };

    const handleChange = (event) => {
        const { value } = event.target;
        setEmail(value);
        setIsValid(validateEmail(value));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const header = {
                "content-Type": "application/json",
                // 'x-access-token': token
            };
            const body = { email };
            const method = "post";
            const subUri = "/user/forgetAppPassword";
            const response = await callApi(header, body, method, subUri);
            if (response.status == 200 || response.status == 201) {
                const message = await response?.data?.message;
                toast.success(message);
                navigate("/confirm-password", { state: { email } });
            }
        } catch (error) {
            const errorMessage = error.response.data.error || error.response.data.message || error.message || "unknown error";
            toast.error(errorMessage);
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <Box className="SignUpContainer">
                <Container maxWidth="xl">
                    <Grid container>
                        <Grid lg="4" md="4" sm="12" xs="12" margin={"auto"}>
                            <Box>
                                <Box sx={{ padding: { md: "2rem", sm: "1rem", xs: "1rem" } }}>
                                    <Box textAlign="center">
                                        <Box>
                                            <img src={signLogo} alt="" width={"120px"} />
                                        </Box>
                                        <Box>
                                            <Typography variant="h5" fontWeight={"600"} color={"var(--body)"}>
                                                Reset Password
                                            </Typography>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box position="relative" bgcolor={"var(--white)"} zIndex="9" borderRadius={"1rem"} padding={"1.5rem"} boxShadow={"0rem 0.3125rem 0.3125rem 0rem rgba(82, 63, 105, 0.05)"}>
                                    <Box>
                                        <Typography variant="body" gutterBottom color={"var(--borderright)"}>
                                            Email or Number
                                        </Typography>
                                        <input placeholder="Enter email or phone number" type="text" className="textInput" onChange={handleChange} name="email" required />
                                        {!isValid && email.length > 0 && <span style={{ color: "#e82149" }}>Invalid email format</span>}
                                    </Box>
                                    <Box sx={{ textAlign: "-webkit-right" }}>
                                        <button className="Continue" style={{ width: "100%", padding: "0 30px" }}>
                                            Reset
                                        </button>
                                    </Box>
                                </Box>
                                <Box marginTop={"10px"}>
                                    <Box textAlign={"center"}>
                                        <Typography fontWeight={"500"} color={"var(--borderright)"}>
                                            Remember it?&nbsp;
                                            <Link
                                                to="/sign-up"
                                                style={{
                                                    textDecoration: "none",
                                                    color: "var(--primaryColor)",
                                                }}
                                            >
                                                Sign in here
                                            </Link>
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </Box>
            <ToastContainer />
        </form>
    );
};

export default ForgetPassword;
