import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { callApi } from "../../api";

const ChartWidget = () => {
  const token = useSelector((token) => token.auth.authToken);
  const updateWeb = useSelector((state) => state.auth.updateWeb);
  const [active, setActiveBox] = useState("6M");
  const [data, setdata] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const header = {
          "content-Type": "application/json",
          "x-access-token": token,
        };

        const body = {
          data: active,
        };

        const method = "post";
        const subUri = "/chartPrice";

        const response = await callApi(header, body, method, subUri);

        if (response.status === 200 || response.status === 201) {
          const message = response?.data?.message;
          let result = message?.map((item) => {
            const date = new Date(item.date).getTime();

            return [date, Number(item.value).toFixed(2)];
          });
          setdata(result);
        }
      } catch (error) {
        console.log("error", error);
      }
    };
    fetchData();
  }, [active, token, updateWeb]);

  const chartData = {
    series: [
      {
        name: "USD",
        data: data || [],
        backgroundColor: "transparent",
        borderColor: "transparent",
        pointBorderColor: "transparent",
        pointBorderWidth: 4,
        tension: 0.5,
      },
    ],
    options: {
      chart: {
        id: "area-datetime",
        type: "area",
        height: 265,
        toolbar: "false",
        foreColor: "var(--primaryColor)",
        background: "transparent",
        sparkline: {
          enabled: false,
        },
        zoom: {
          autoScaleYaxis: true,
        },
      },
      annotations: {
        yaxis: [
          {
            y: 30,
            borderColor: "transparent",
          },
        ],
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0,
        style: "hollow",
        colors: "var(--primaryColor)",
        strokeColors: "transparent",
        strokeWidth: 0,
        strokeOpacity: 0.9,
        strokeDashArray: 0,
        fillOpacity: 1,
        discrete: [],
        shape: "circle",
        radius: 2,
        offsetX: 0,
        offsetY: 0,
        onClick: undefined,
        onDblClick: undefined,
        showNullDataPoints: true,
        hover: {
          size: undefined,
          sizeOffset: 5,
        },
      },
      xaxis: {
        type: "datetime",
        tickAmount: 12,
      },
      yaxis: {
        labels: {
          show: false, // Hide y-axis labels
        },
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy",
        },
        enabled: true,
        enabledOnSeries: undefined,
        shared: true,
        followCursor: false,
        intersect: false,
        inverseOrder: false,
        custom: undefined,
        hideEmptySeries: true,
        fillSeriesColor: false,
        theme: "dark",
      },
      grid: {
        xaxis: { lines: { show: false } },
        yaxis: {
          lines: {
            show: false,
          },
        },
      },
      stroke: {
        curve: "smooth",
        width: 2,
        colors: ["var(--primaryColor)"],
        opacity: 0.3,
      },
      fill: {
        type: "gradient",
        colors: "var(--primaryColor)",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.6,
          opacityTo: 0.05,
          stops: [42, 100, 100, 100],
        },
      },
    },
  };

  return (
    <div>
      <div className="toolbar_month">
        <button
          style={{
            backgroundColor: active === "1D" ? "var(--primaryColor)" : "var(--body)",
            color: active === "1D" ? "var(--body)" : "var(--white)",
          }}
          className="one_month"
          name="data"
          onClick={() => setActiveBox("1D")}
        >
          1D
        </button>
        <button
          style={{
            backgroundColor: active === "1W" ? "var(--primaryColor)" : "var(--body)",
            color: active === "1W" ? "var(--body)" : "var(--white)",
          }}
          className="one_month"
          name="data"
          onClick={() => setActiveBox("1W")}
        >
          1W
        </button>
        <button
          style={{
            backgroundColor: active === "1M" ? "var(--primaryColor)" : "var(--body)",
            color: active === "1M" ? "var(--body)" : "var(--white)",
          }}
          className="one_month"
          name="data"
          onClick={() => setActiveBox("1M")}
        >
          1M
        </button>
        <button
          style={{
            backgroundColor: active === "6M" ? "var(--primaryColor)" : "var(--body)",
            color: active === "6M" ? "var(--body)" : "var(--white)",
          }}
          className="one_month"
          name="data"
          onClick={() => setActiveBox("6M")}
        >
          6M
        </button>
      </div>
      {data && <ReactApexChart options={chartData.options} series={chartData.series} type="area" height={265} />}
    </div>
  );
};

export default ChartWidget;
